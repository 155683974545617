import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonBase } from '@material-ui/core'

import { RedemptionCenterCondition } from '../../../redemptionCenterCondition'
import BaseDrawer from '../../../../ui/BaseDrawer/BaseDrawer'
import { IdList } from '../../../../core/utils/id-list'
import ExclamationMarkIco from '../../../../ui/customIcons/ExclamationMarkIco'
import LoadingDialog from '../../../../ui/LoadingDialog/LoadingDialog'

import './PhoneVerificationAlreadyUsed.scss'

interface PhoneVerificationAlreadyUsedProps {
  isSpinner: boolean
  isPhoneVerificationAlreadyUsed: boolean
  handleClosePhoneVerificationAlreadyUsed: () => void
  handleClosePhoneVerificationUseSameNumber: () => void
  validatePhoneNumber: () => void | Promise<void>
}

const PhoneVerificationAlreadyUsed: FC<PhoneVerificationAlreadyUsedProps> = ({
  isSpinner,
  isPhoneVerificationAlreadyUsed,
  handleClosePhoneVerificationAlreadyUsed,
  handleClosePhoneVerificationUseSameNumber,
  validatePhoneNumber
}) => {
  const { t } = useTranslation()

  const handlePhoneVerification = async (): Promise<void> => {
    handleClosePhoneVerificationUseSameNumber()
    return await validatePhoneNumber()
  }

  return (
    <BaseDrawer
      isOpen={isPhoneVerificationAlreadyUsed}
      onClose={handleClosePhoneVerificationAlreadyUsed}
      isCentered
      centerCondition={RedemptionCenterCondition.both}
    >
      <LoadingDialog isSpinner={isSpinner}>
        <div className='phone-verification-already-used'>
          <div className='phone-verification-already-used__img'>
            <ExclamationMarkIco />
          </div>
          <div className='phone-verification-already-used__title'>{t`PhoneAlreadyInUse`}</div>
          <div className='phone-verification-already-used__message'>{t`PhoneAlreadyInUseMessage`}</div>
          <Button
            id={IdList.phoneVerificationAlreadyUsed}
            onClick={handlePhoneVerification}
            className='phone-verification-already-used__btn'
          >
            {t`ContinueWithThisNumber`}
          </Button>
          <ButtonBase
            onClick={handleClosePhoneVerificationAlreadyUsed}
            className='terms-link'
          >
            {t`UseADifferentNumber`}
          </ButtonBase>
        </div>
      </LoadingDialog>
    </BaseDrawer>
  )
}

export default PhoneVerificationAlreadyUsed
