import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../user/selectors'
import './DevLabel.scss'

const PROD_VALUE = 'prod'

const DevLabel: FC = () => {
  const env = process.env.REACT_APP_ENVIRONMENT
  const isDev = env != null ? !env.includes(PROD_VALUE) : true
  const user: UserInfo = useSelector(selectUser)
  const UserID = (user.UserID ?? 'ANON').slice(0, 4)
  const version = process.env.REACT_APP_VERSION

  return (
    <>
      {isDev &&
        <div className='dev-label'>
          <span>{version} - {env} - {UserID} </span>
        </div>}
    </>
  )
}

export default DevLabel
