import axios from 'axios'
import * as Sentry from '@sentry/react'

let healthURL: string | undefined =
  process.env.REACT_APP_BACKEND_HEALTH_JSON_URL
if (healthURL === undefined) {
  healthURL = ''
}
const DOWN = 'DOWN'

export const isServerDown = async (): Promise<boolean> => {
  return await axios({
    method: 'get',
    url: healthURL,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  })
    .then((response) => {
      if (response.data.Backend === DOWN) {
        return true
      }

      return false
    })
    .catch(async (error) => {
      Sentry.captureException(error, {
        tags: {
          method: 'isServerDown'
        }
      })
      return false
    })
}
