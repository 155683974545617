import { axiosInstance } from './init'
import { signOut } from '../../auth/actions'
import { RoutePath } from '../routes/route-path'
import { setAppError } from '../../config/actions'
import { AppErrorType } from '../../config/appErrorType'
import * as Sentry from '@sentry/react'
import {
  FORGOT_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  VALIDATE_INVITE_CODE_PATH
} from './constants'

const interceptor = (store: any, history: any): any => {
  const checkIfRewardsInfoAvailable = (): boolean => {
    const state = store.getState()
    return state.campaign !== null && state.campaign.campaignList.length > 0
  }

  const routeAndReportError = (error: any): void => {
    if (
      error.config.url !== FORGOT_PASSWORD_PATH &&
      error.config.url !== SIGN_UP_PATH &&
      error.config.url !== SIGN_IN_PATH &&
      error.config.url !== VALIDATE_INVITE_CODE_PATH
    ) {
      history.push(RoutePath.ErrorPage)
    }
    Sentry.captureException(error, {
      tags: {
        method: 'routeAndReportError'
      }
    })
  }

  const proceedGeneralErrorIfNoReward = (error: Error): void => {
    if (!checkIfRewardsInfoAvailable()) {
      store.dispatch(setAppError(AppErrorType.generalError))
      routeAndReportError(error)
    }
  }

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.response !== undefined && error.response !== null) {
        if (error.response.status === 403) {
          store.dispatch(setAppError(AppErrorType.loginError))
          store.dispatch(signOut())
          routeAndReportError(error)
        } else {
          proceedGeneralErrorIfNoReward(error)
        }
      } else {
        if (error.message === 'Network Error') {
          store.dispatch(setAppError(AppErrorType.networkError))
          routeAndReportError(error)
        } else {
          proceedGeneralErrorIfNoReward(error)
        }
      }

      return await Promise.reject(error)
    }
  )
}
export default interceptor
