import React, { FC } from 'react'

import BaseDrawer from '../BaseDrawer/BaseDrawer'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsSignupWelcomeEnabled } from '../../config/selectors'
import { RedemptionCenterCondition } from '../../offer/redemptionCenterCondition'
import { toggleSignupWelcome } from '../../config/actions'
import ReactMarkdown from 'react-markdown'

import './SignupWelcomeMessage.scss'
import _ from 'lodash'

interface SignupWelcomMessageProps {
  children?: React.ReactNode
  image: string
  label: string
  description: string
  title: string
}

const SignupWelcomeMessage: FC<SignupWelcomMessageProps> = ({
  image,
  label,
  description,
  title
}) => {
  const dispatch = useDispatch()
  const signupWelcomeEnabled = useSelector(selectIsSignupWelcomeEnabled)
  const onCloseMsg = (): void => {
    dispatch(toggleSignupWelcome(false))
  }

  return (
    <BaseDrawer
      isOpen={signupWelcomeEnabled}
      isCentered
      onClose={onCloseMsg}
      centerCondition={RedemptionCenterCondition.both}
    >
      <div className='welcome-msg'>
        {!_.isEmpty(image) && (
          <img src={`${image}`} className='welcome-msg__icon' alt='Welcome Message Icon' />
        )}

        <div className='welcome-msg__title'>{title}</div>
        <div className='welcome-msg__description'>
          <ReactMarkdown linkTarget='_blank'>{description}</ReactMarkdown>
        </div>
        <div className='welcome-msg__btn btn' onClick={onCloseMsg}>
          {label}
        </div>
      </div>
    </BaseDrawer>
  )
}

export default SignupWelcomeMessage
