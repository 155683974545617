import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { RedemptionCenterCondition } from '../../offer/redemptionCenterCondition'
import { diffFromNow } from '../../core/utils/time'
import { IdList } from '../../core/utils/id-list'
import BaseDrawer from '../BaseDrawer/BaseDrawer'

import './AvailableMessage.scss'

interface AvailableMessageProps {
  isAvailableMessageOpen: boolean
  handleCloseAvailableMessage: () => void
  nextResetAtUTC: string
}

const AvailableMessage: FC<AvailableMessageProps> = ({ isAvailableMessageOpen, handleCloseAvailableMessage, nextResetAtUTC }) => {
  const { t } = useTranslation()
  return (
    <>
      <BaseDrawer
        isOpen={isAvailableMessageOpen}
        onClose={handleCloseAvailableMessage}
        isCentered
        centerCondition={RedemptionCenterCondition.both}
      >
        <div className='available-message'>
          <div className='available-message__title'>
            {t`AvailableIn`}...
          </div>
          <div className='available-message__time'>
            {diffFromNow(nextResetAtUTC)}
          </div>
          <Button
            id={IdList.availableMessage}
            className='available-message__btn'
            onClick={handleCloseAvailableMessage}
          >
            {t`Ok`}
          </Button>
        </div>
      </BaseDrawer>
    </>
  )
}

export default AvailableMessage
