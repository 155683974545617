export enum IdList {
  signIn = 'sign-in',
  signInEmailPhoneNumber = 'sign-in-email-phone-number',
  signInPassword = 'sign-in-password',
  signOut = 'sign-out',
  burgerMenu = 'burger-menu',
  tabEarn = 'tab-earn',
  navHome = 'nav-home',
  navLogo = 'nav-login',
  navConnect = 'nav-connect',
  navInviteCode = 'nav-invite-code',
  navReferFriend = 'nav-refer-friend',
  navModalConnect = 'nav-modal-connect',
  navModalClose = 'nav-modal-close',
  navModalHelp = 'nav-modal-help',
  navModalInviteCode = 'nav-modal-invite-code',
  navModalReferFriend = 'nav-modal-refer-friend',
  navModalSignOut = 'nav-modal-sign-out',
  balanceHistory = 'balance-history',
  earningClaim = 'earning-claim',
  seeAllEarnings = 'see-all-earnings',
  tabRedeem = 'tab-redeem',
  navModalHistory = 'nav-modal-history',
  tabMore = 'tab-more',
  transactionHistoryList = 'transaction-history-list',
  offerListPrompt = 'offer-list-prompt',
  redeemFor = 'redeem-for',
  unclaimedEarnings = 'unclaimed-earnings',
  badgeIcon = 'badge-icon',
  reportIssue = 'report-issue',
  viewTicket = 'view-ticket',
  ticketClose = 'ticket-close',
  redemptionCode = 'redemption-code',
  redemptionCopyCode = 'redemption-copy-code',
  redemptionApplyCode = 'redemption-apply-code',
  redemptionClose = 'redemption-close',
  availableMessage = 'available-message',
  phoneVerificationSuccess = 'phone-verification-success',
  phoneVerificationAlreadyUsed = 'phone-verification-already-used',
  submitPhone = 'submit-phone',
  InviteCodeLink = 'invite-code-link',
  inviteCode = 'invite-code',
  waitingDialogBtn = 'waiting-dialog-btn',
  providerSuccessDialog = 'provider-success-dialog',
  optInBtn = 'opt-in-btn',
  addNewCode = 'add-new-code',
  addNewCodeSubmit = 'add-new-code-submit',
  referCode = 'refer-code',
  referCopyCode = 'refer-copy-code',
  referLink = 'refer-link',
  referCopyLink = 'refer-copy-link',
  multiLanguageSelection = 'multi-language-selection',
  manageAccount = 'manage-account',
  navEarn = 'nav-earn',
  navRedeem = 'nav=redeem',
  rewardsProgram = 'rewards-program'
}
