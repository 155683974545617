import { useEffect } from 'react'

export const parseCssTheme = (
  cssTheme: Nullable<string>
): { [key: string]: string } => {
  let customTheme: { [key: string]: string }
  try {
    const tenantCssTheme = cssTheme != null ? JSON.parse(cssTheme) : {}
    customTheme =
      typeof tenantCssTheme === 'string'
        ? JSON.parse(tenantCssTheme)
        : tenantCssTheme
  } catch {
    customTheme = {}
  }
  return customTheme
}

const useTheme = (cssTheme: Nullable<string>): void => {
  useEffect(() => {
    const root = document.documentElement
    const defaultTheme = {
      button: '#006BA6',
      buttonText: 'white',
      btnHover: '#1A7AAF',
      pointsBadge: '#0081AD',
      pointsBadgeText: '#191919',
      header: '#E1E7EC',
      headerText: '#191919',
      disable: '#BFC7CD',
      disableText: '#3E5656',
      error: '#E45858',
      linkColor: '#0081AD',
      stateBadge: '#191919'
    }

    const customTheme = parseCssTheme(cssTheme)

    const {
      button,
      buttonText,
      btnHover,
      pointsBadge,
      pointsBadgeText,
      header,
      headerText,
      disable,
      disableText,
      error,
      linkColor,
      stateBadge
    } = { ...defaultTheme, ...(customTheme as { [key: string]: string }) }

    root.style.setProperty('--btn-text', buttonText)
    root.style.setProperty('--btn-hover', btnHover)
    root.style.setProperty('--btn-primary', button)
    root.style.setProperty('--header', header)
    root.style.setProperty('--header-text', headerText)
    root.style.setProperty('--disable', disable)
    root.style.setProperty('--disable-text', disableText)
    root.style.setProperty('--error', error)
    root.style.setProperty('--link-color', linkColor)
    root.style.setProperty('--icons', stateBadge)
    root.style.setProperty('--points-badge', pointsBadge)
    root.style.setProperty('--points-badge-text', pointsBadgeText)
  }, [cssTheme])
}

export default useTheme
