import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

export const selectOfferList = (state: ApplicationState): Offer[] =>
  sortBy(state.offer.offerList, 'WebUiPosition')
export const selectOfferHiddenList = (state: ApplicationState): Offer[] =>
  state.offer.offerHiddenList
export const selectOfferCategories = (
  state: ApplicationState
): OfferCategory[] => sortBy(state.offer.offerCategories, 'WebUiPosition')

export const selectOfferListByCategory = createSelector(
  [selectOfferList, selectOfferCategories],
  (
    OfferList: Offer[],
    OfferCategories: OfferCategory[]
  ): OfferListByCategory[] => {
    return OfferCategories.map(category => ({
      ...category,
      offerList: OfferList.filter(
        Offer => Offer.OfferCategoryID === category.OfferCategoryID
      )
    }))
  }
)
