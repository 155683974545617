import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import { useSelector } from 'react-redux'

import { RoutePath } from '../../../core/routes/route-path'
import { IdList } from '../../../core/utils/id-list'

import './Navigation.scss'
import { selectUser } from '../../../user/selectors'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

export const NavigationUpdated: FC = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const { featureFlags }: UserState = useSelector(selectUser)

  const handleEarnLink = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Earn'
    })
  }
  const handleRedeemLink = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Redeem'
    })
  }
  return (
    <div className='navigation'>
      {featureFlags.UX_UPDATES && (
        <NavLink
          id={IdList.navEarn}
          to={RoutePath.EarnUpdated}
          className='navigation-link navigation-link__desktop'
          onClick={handleEarnLink}
        >
          {t`Earn`}
        </NavLink>
      )}
      <NavLink
        id={IdList.navRedeem}
        to={RoutePath.RedeemUpdated}
        className='navigation-link navigation-link__desktop'
        onClick={handleRedeemLink}
      >
        {t`Redeem`}
      </NavLink>
    </div>
  )
}
