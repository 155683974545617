import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './StreakProgressUpdated.scss'

interface StreakProgressUpdatedProps {
  trips: number
  completion: number
  currentLevel: number
  totalLevel: number
  daysLeft: number
  completed: boolean
  streakLevelTarget?: number
  isFromDetailView?: boolean
}

const StreakProgressUpdated: FC<StreakProgressUpdatedProps> = ({
  trips,
  completion,
  totalLevel,
  currentLevel,
  daysLeft,
  completed,
  streakLevelTarget = 0,
  isFromDetailView = false
}) => {
  const { t } = useTranslation()
  return (
    <div className='progress-wrapper'>
      <div className='progress-upper'>
        <div className='progress-left-info'>
          <p className='progress-left-info-level'>
            {`${trips}/${streakLevelTarget} ${t`Trips`}`}
          </p>
        </div>
        <div className='progress-right-info'>
          <p className='progress-right-info-day-left'>
            {completed
              ? t`StreakFinished`
              : daysLeft > 0
                ? t('StreakDaysLeft', {
                  daysLeft
                })
                : t`LastDay`}
          </p>
        </div>
      </div>
      <div className='progress-outer'>
        <div
          className='progress-inner'
          style={{
            width: `${completion}%`,
            backgroundColor: `${
              currentLevel < totalLevel ? '#F4AD07' : '#09ba70'
            }`
          }}
        />
      </div>
      {streakLevelTarget > 0 && isFromDetailView && (
        <p className='target__extra_css'>
          {t`Level`} {currentLevel}: {streakLevelTarget} {t`Trips`}
        </p>
      )}
    </div>
  )
}

export default StreakProgressUpdated
