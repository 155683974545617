import axios from 'axios'
import { configService } from '../../config/service'

let baseURL: string | undefined = process.env.REACT_APP_API_URL

if (baseURL === undefined) {
  baseURL = ''
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300
  }
})

export async function getInitialTenantInfo (TenantID?: string): Promise<any> {
  return TenantID != null
    ? await configService.getConfigInfoByTenant(TenantID)
    : await configService.getConfigInfo()
}

export { axiosInstance }
