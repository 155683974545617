import { axiosInstance } from '../core/api/init'
import { languageService } from '../language/service'

export const campaignService = {
  participatePromotion: async (
    promotion: ParticipatePromotion
  ): Promise<CampaignPromotional> => {
    return await axiosInstance
      .post(
        `/rest/campaigns/promotional/${
          promotion.inviteCode
        }/participation/${languageService.getLanguage()}`
      )
      .then((response) => response.data)
      .catch(async (error) => await Promise.reject(error.response))
  },
  getReferralInfo: async (TenantID: string): Promise<CampaignReferral> => {
    return await axiosInstance
      .post(`/rest/campaigns/referral/${languageService.getLanguage()}`, {
        TenantID
      })
      .then((response) => response.data)
      .catch(async (error) => await Promise.reject(error.response))
  },
  getCampaign: async (id: string): Promise<Campaign> => {
    return await axiosInstance
      .get(`/rest/campaigns/${languageService.getLanguage()}/${id}`)
      .then((response) => response.data)
  },
  getCampaignList: async (): Promise<Campaign[]> => {
    return await axiosInstance
      .get(`/rest/campaigns/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },
  getCampaignFeaturedList: async (): Promise<CampaignFeatured[]> => {
    return await axiosInstance
      .get('/rest/featuredCampaigns')
      .then((response) => response.data)
  },
  getCampaignCategories: async (): Promise<CampaignCategory[]> => {
    return await axiosInstance
      .get(`/rest/campaignsCategories/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },
  getCampaignStatuses: async (): Promise<CampaignStatus[]> => {
    return await axiosInstance
      .get('/rest/users/me/campaignsStatus')
      .then((response) => response.data)
  }
}
