export const initialState: UpdateCardState = {
  updateCardList: []
}

export const defaultAction: UpdateCardListAction = {
  type: '',
  payload: []
}

export const defaultUpdateCard: UpdateCard = {
  UpdateCardID: 0,
  SubHeader1: '',
  SubHeader2: '',
  ImageUrl: '',
  Title: '',
  Description: '',
  Icon: '',
  CtaLabel: '',
  CtaLink: ''
}
