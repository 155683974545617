import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ProviderItem from './ProviderItem/ProviderItem'
import { selectProviderList } from '../selectors'
import { Skeleton } from '@material-ui/lab'

import './ProviderList.scss'
import { getProviderStatusList } from '../actions'

interface ProviderListProps {
  isProviderLoaded: boolean
}

const ProviderList: FC<ProviderListProps> = ({ isProviderLoaded }) => {
  const dispatch = useDispatch()
  const providerList: ProviderAccount[] = useSelector(selectProviderList)

  useEffect((): void => {
    dispatch(getProviderStatusList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='provider-list'>
      {isProviderLoaded
        ? (providerList.map((providerItem: ProviderAccount, index) => (
          <ProviderItem
            key={index}
            provider={providerItem}
          />
        )))
        : (new Array(3).fill(0).map((_, i) => (
          <Skeleton variant='rect' classes={{ root: 'provider-list--loading__item' }} key={i} />)))}
    </div>
  )
}

export default ProviderList
