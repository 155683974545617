import React, { FC } from 'react'
import { useMediaQuery } from '@react-hook/media-query'

import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import RedemptionWithCode from './RedemptionWithCode/RedemptionWithCode'
import RedemptionWithTicket from './RedemptionWithTicket/RedemptionWithTicket'
import { RedemptionType } from '../../redemptionType'
import Spinner from '../../../ui/animation/Spinner/Spinner'
import { breakpoints } from '../../../core/utils/css-selectors'

import './RedemptionResultDrawer.scss'
import { RedemptionCenterCondition } from '../../redemptionCenterCondition'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../user/selectors'

interface RedemptionResultDrawerProps {
  isSpinner: boolean
  isRedemptionResultOpen: boolean
  handleCloseRedemption: () => void
  offerItem: Offer | undefined
  redemption: Redemption | undefined
  handleOpenHistory?: () => void
  handleOpenTerms: () => void
  isFromUpdatedDesktopUI?: boolean
}

const RedemptionResultDrawer: FC<RedemptionResultDrawerProps> = ({
  isSpinner,
  isRedemptionResultOpen,
  handleCloseRedemption,
  offerItem,
  redemption,
  handleOpenHistory,
  handleOpenTerms,
  isFromUpdatedDesktopUI = false
}) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { featureFlags }: UserState = useSelector(selectUser)
  const onOpenHistory = (): void => {
    if (handleOpenHistory !== undefined) {
      handleOpenHistory()
    }
  }

  const handleClose = (): void => {
    if (
      offerItem === undefined ||
      offerItem.CouponType !== RedemptionType.directExpire
    ) {
      handleCloseRedemption()
    }
  }

  return (
    <BaseDrawer
      isOpen={isRedemptionResultOpen}
      onClose={handleClose}
      isCentered={featureFlags.UX_UPDATES ? isSpinner : true}
      centerCondition={RedemptionCenterCondition.both}
      noPadding
      isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd && !isSpinner}
      isFromUpdatedDesktopUI={isFromUpdatedDesktopUI && matchesMd && !isSpinner}
      dialogClassName={
        (featureFlags.UX_UPDATES) && !isSpinner
          ? 'redemption-drawer-new'
          : matchesMd
            ? 'custom-padding'
            : ''
      }
    >
      <div className='redemption-result'>
        {isSpinner && (
          <div
            className={`${
              matchesMd ? 'redemption-result__spinner--active' : ''
            }`}
          >
            <Spinner height={70} width={70} />
          </div>
        )}

        <div
          className={`${
            !(featureFlags.UX_UPDATES) ? 'redemption-result__content' : ''
          } ${isSpinner ? 'redemption-result__content--hidden' : ''}`}
        >
          {redemption !== undefined && (
            <div
              className={
                matchesMd && !featureFlags.UX_UPDATES ? 'custom-padding' : ''
              }
            >
              {redemption.CouponType === RedemptionType.uniqueCode && (
                <RedemptionWithCode
                  handleCloseRedemption={handleCloseRedemption}
                  offerItem={offerItem}
                  redemption={redemption}
                  handleOpenHistory={onOpenHistory}
                  handleOpenTerms={handleOpenTerms}
                  isCustom={matchesMd}
                  isFullWidth
                  isFromUpdatedUI={featureFlags.UX_UPDATES}
                  isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                />
              )}
              {redemption.CouponType === RedemptionType.direct && (
                <RedemptionWithTicket
                  handleCloseRedemption={handleCloseRedemption}
                  offerItem={offerItem}
                  redemption={redemption}
                  isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
                  isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                />
              )}
              {(redemption.CouponType === RedemptionType.directExpire ||
                redemption.CouponType === RedemptionType.creditPass) &&
                (
                  <RedemptionWithTicket // TODO: replace with RedemptionWithExpiry in the future
                    handleCloseRedemption={handleCloseRedemption}
                    offerItem={offerItem}
                    redemption={redemption}
                    isFromUpdatedUI={featureFlags.UX_UPDATES && !matchesMd}
                    isFromUpdatedDesktopUI={featureFlags.UX_UPDATES && matchesMd}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    </BaseDrawer>
  )
}

export default RedemptionResultDrawer
