import React, { FC } from 'react'
import ReactMarkdown from 'react-markdown'

import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'

import './OfferTermsDrawer.scss'

interface OfferTermsDrawerProps {
  isOpen: boolean
  setIsOpen: (flag: boolean) => void
  title: Nullable<string>
  description: Nullable<string>
  isFromUpdatedUI?: boolean
  isFromUpdatedDesktopUI?: boolean
}

const OfferTermsDrawer: FC<OfferTermsDrawerProps> = ({
  isOpen = false,
  setIsOpen,
  title,
  description,
  isFromUpdatedUI = false,
  isFromUpdatedDesktopUI = false
}) => {
  return (
    <BaseDrawer
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      isFromUpdatedUI={isFromUpdatedUI}
      customClassName={`${
        isFromUpdatedUI || isFromUpdatedDesktopUI
          ? `terms-and-condition-updated ${isFromUpdatedDesktopUI ? 'maxh-70' : ''}`
          : ''
      }`}
      isFromUpdatedDesktopUI={isFromUpdatedDesktopUI}
      customDialogClass={isFromUpdatedDesktopUI ? 'p-1' : ''}
    >
      <div className='offer-terms'>
        <div
          className={`offer-terms__title ${
            isFromUpdatedDesktopUI ? 'mt-2' : ''
          }`}
        >
          {title}
        </div>
        <ReactMarkdown
          linkTarget='_blank'
          className={`offer-terms__description ${
            isFromUpdatedDesktopUI ? 'mb-3' : ''
          }`}
        >
          {description}
        </ReactMarkdown>
      </div>
    </BaseDrawer>
  )
}

export default OfferTermsDrawer
