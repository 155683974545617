import { createSelector } from 'reselect'

export const selectConfig = (state: ApplicationState): ConfigState => state.config
export const selectStartAppUTC = (state: ApplicationState): string => state.config.startAppUTC
export const selectActiveTab = (state: ApplicationState): string => state.config.ui.activeTab
export const selectLoadedList = (state: ApplicationState): string[] => state.config.ui.loadedList
export const selectIsWelcomeMessage = (state: ApplicationState): boolean => state.config.ui.isWelcome
export const selectIsOffline = (state: ApplicationState): boolean => state.config.ui.isOffline
export const selectIsShake = (state: ApplicationState): boolean => state.config.ui.isShake
export const selectIsSignupWelcomeEnabled = (state: ApplicationState): boolean => state.config.ui.isSignupWelcome
export const selectWelcomeScreenConfig = (state: ApplicationState): WelcomeScreenConfig => state.config.WelcomeScreenConfig
export const selectWelcomeStepsConfigs = (state: ApplicationState): WelcomeStepsConfig[] => state.config.WelcomeStepsConfig
export const selectNewFeatureConfig = (state: ApplicationState): NewFeatureConfig[] => state.user.NewFeatureConfig
export const selectLanguageList = (state: ApplicationState): LanguageList[] => state.config.LanguageList
export const selectIsActionLoaded: any = (actionType: string | string[]) => {
  return createSelector(
    [selectLoadedList],
    (loadedList: string[]): boolean => {
      if (typeof actionType === 'string') {
        return loadedList.includes(actionType)
      } else {
        const matchLoadedList = actionType.filter((action) => {
          return loadedList.includes(action)
        })
        return matchLoadedList !== undefined ? matchLoadedList.length === actionType.length : false
      }
    }
  )
}
