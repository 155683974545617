import * as actionTypes from './actionTypes'
import { initialState, defaultAction } from './initialState'

const reducer = (
  state: AuthState = initialState,
  action: AuthAction = defaultAction
): AuthState => {
  switch (action.type) {
    case actionTypes.SIGN_IN:
      return {
        ...state,
        isAuth: action.payload.isAuth
      }
    case actionTypes.SET_HOME_PATH:
      return {
        ...state,
        isHomePath: action.payload.isHomePath
      }
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        isAuth: action.payload.isAuth
      }
    case actionTypes.SIGN_IN_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case actionTypes.RESET_SIGN_IN_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case actionTypes.PASSWORD_RESET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error as string
      }
    case actionTypes.RESET_PASSWORD_RESET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.error as string
      }
    case actionTypes.EDIT_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        editAccountInfoErr: action.payload.error as string
      }
    case actionTypes.RESET_EDIT_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        editAccountInfoErr: action.payload.error as string
      }
    case actionTypes.EDIT_ACCOUNT_PASSWORD_ERROR:
      return {
        ...state,
        editAccountPasswordErr: action.payload.error as string
      }
    case actionTypes.RESET_EDIT_ACCOUNT_PASSWORD_ERROR:
      return {
        ...state,
        editAccountPasswordErr: action.payload.error as string
      }
  }
  return state
}

export default reducer
