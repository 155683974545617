import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './StreakProgressUpdated.scss'
import { MAX_STREAK_PRORESS_STEPS } from './StreakConstants'

interface StreakProgressWithTimeDistinctProps {
  currentLevel: number
  totalLevel: number
  daysLeft: number
  completed: boolean
  streakTarget?: number
  behavioursCount: number
  timeDistinctCount: number
  timeDistinctUnit: string
  fromCard?: boolean
  extraCssForList?: boolean
}

const StreakProgressWithTimeDistinct: FC<
StreakProgressWithTimeDistinctProps
> = ({
  totalLevel,
  currentLevel,
  daysLeft,
  completed,
  streakTarget = 0,
  behavioursCount,
  timeDistinctCount,
  fromCard = false,
  extraCssForList = false
}) => {
  const { t } = useTranslation()
  const getProgress = (): JSX.Element[] => {
    const steps = []
    for (let i = 0; i < MAX_STREAK_PRORESS_STEPS; i++) {
      steps.push(
        <div
          className={`circle ${fromCard ? 'circle__card' : ''} ${
            behavioursCount > i ? 'fulfilled' : 'not-fulfilled'
          }  fill${i > timeDistinctCount - 1 ? '__unavailable' : '__available'}`}
          key={i}
        >
          {behavioursCount > i ? (
            <div className={`checkmark ${fromCard ? 'checkmark__card' : ''}`} />
          ) : (
            <p
              className={`circle-number ${
                fromCard ? 'circle-number__card' : ''
              } ${extraCssForList ? 'circle-number__extra_css' : ''}`}
            >
              {i + 1}
            </p>
          )}
        </div>
      )
      if (i < MAX_STREAK_PRORESS_STEPS - 1) {
        steps.push(
          <div
            className={`progress-line ${
              fromCard ? 'progress-line__card' : ''
            } ${extraCssForList ? 'progress-line__extra_css' : ''}`}
          />
        )
      }
    }
    return steps
  }
  return (
    <div className='progress-wrapper'>
      {!fromCard && (
        <div className='progress-upper'>
          <div className='progress-left-info'>
            <p className='progress-left-info-level'>
              {`${currentLevel}/${totalLevel}`}
            </p>
          </div>
          <div className='progress-right-info'>
            <p className='progress-right-info-day-left'>
              {completed
                ? t`StreakFinished`
                : daysLeft > 0
                  ? t('StreakDaysLeft', {
                    daysLeft
                  })
                  : t`LastDay`}
            </p>
          </div>
        </div>
      )}
      <div className='progress-bar'>{getProgress()}</div>
      {streakTarget > 0 && (
        <p
          className={`${fromCard ? 'target__card' : ''} ${
            extraCssForList ? 'target__extra_css' : ''
          }`}
        >
          {t`StreakTarget`}: {streakTarget} {t`Trips`}
        </p>
      )}
    </div>
  )
}

export default StreakProgressWithTimeDistinct
