import React, { FC } from 'react'

const BackIco: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <path d='M24 0L24 24 0 24 0 0z' opacity='.87' transform='translate(-73 -101) translate(73 101)' />
            <path fill='#191919' fillRule='nonzero' d='M17.7 15.89L13.82 12l3.89-3.89c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0l-4.59 4.59c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .38-.38.38-1.02-.01-1.4zM7 6c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1s-1-.45-1-1V7c0-.55.45-1 1-1z' transform='translate(-73 -101) translate(73 101)' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BackIco
