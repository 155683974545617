import { axiosInstance } from '../core/api/init'
import { languageService } from '../language/service'

export const providerService = {
  getProviderList: async (): Promise<ProviderAccount[]> => {
    return await axiosInstance.get(`/rest/providers/${languageService.getLanguage()}`)
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  getProviderStatusList: async (): Promise<ProviderStatus[]> => {
    return await axiosInstance.get('/rest/users/me/providersStatus')
      .then((response) => response.data)
  },
  setProviderStatus: async (ProviderID: string, identifier: string): Promise<ProviderStatus> => {
    return await axiosInstance.post('/rest/users/me/providersStatus', { ProviderID, identifier })
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  disconnectProviderStatus: async (ProviderID: string): Promise<void> => {
    return await axiosInstance.delete(`/rest/users/me/providersStatus/${ProviderID}`)
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  getRedirectURLForOAUTH: async (ProviderID: string): Promise<ProviderRedirectURL> => {
    return await axiosInstance.get(`/rest/users/me/providerRedirectURL/${ProviderID}`)
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  }
}
