import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import track, { useTracking } from 'react-tracking'
import { Dispatch } from 'redux'

import { useMediaQuery } from '@react-hook/media-query'

import OfferList from '../offer/OfferList/OfferList'
import TabPanel from '../ui/TabPanel/TabPanel'
import { TabsName } from '../core/tabsName'
import { selectActiveTab, selectConfig } from '../config/selectors'
import { breakpoints } from '../core/utils/css-selectors'
import { AnalyticsCategory } from '../core/analytics/analyticsCategory'
import WelcomeMessage from '../ui/WelcomeMessage/WelcomeMessage'
import SignupWelcomeMessage from '../ui/SignupWelcomeMessage/SignupWelcomeMessage'
import TutorialToolTip from '../tutorial/TutorialToolTip'
import NewFeatureMessage from '../ui/NewFeatureMessage/NewFeatureMessage'
import { changeTab } from '../config/actions'
import { useHistory, useLocation } from 'react-router-dom'
import { RoutePath } from '../core/routes/route-path'
import { setTutorialOn } from '../tutorial/actions'
import { setHomePath } from '../auth/actions'
import { selectUser } from '../user/selectors'
import AddToHomescreenPrompt from '../ui/AddToHomescreen/AddToHomescreenPrompt'
import {
  getUserReactivationStatus,
  setHasJustLoggedIn,
  updateShowReactivationBonusDialog
} from '../user/actions'
import { selectCurrentTutorialState } from '../tutorial/selectors'
import TutorialToolTipUpdated from '../tutorial/TutorialToolTipUpdated'
import UpdatedCampaigns from '../campaign/UpdatedCampaigns/UpdatedCampaigns'
import UpdateCards from '../updateCards/UpdateCardList/UpdateCardList'
import Campaigns from '../campaign/Campaigns/Campaigns'
import ReactivationBonusDialog from '../ui/ReactivationBonusDialog/ReactivationBonusDialog'
import * as Sentry from '@sentry/react'

import './Home.scss'
import { Box } from '@material-ui/core'
import Spinner from '../ui/animation/Spinner/Spinner'
import { AUTH_INDICATOR_SIZE } from '../auth/constants'

const INITIAL_POLLING_INTERVAL = 1000
const MAX_POLLING_INTERVAL = 60000
const BACKOFF_MULTIPLIER = 2
const MAX_ATTEMPTS = 5

const Home: FC = () => {
  const isDesktop = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const activeTab = useSelector(selectActiveTab)
  const { trackEvent } = useTracking()
  const config: ConfigState = useSelector(selectConfig)
  const dispatch: Dispatch<UserAction> = useDispatch()
  const location = useLocation()
  const { tutorialOn } = useSelector(selectCurrentTutorialState)
  const {
    featureFlags,
    hasJustLoggedIn,
    HaveSeenTutorial,
    showReactivationBonusDialog,
    isDataLoaded,
    isUserInactive
  }: UserState = useSelector(selectUser)
  const history = useHistory()

  useEffect(() => {
    trackEvent({
      action: 'Rewards Opened',
      page: 'Home'
    })
    if (location.pathname === RoutePath.Redemption) {
      dispatch(changeTab(TabsName.Redeem))
      window.scrollTo(0, 0)
    }
    if (location.pathname === RoutePath.Tutorial) {
      dispatch(changeTab(TabsName.Earn))
      dispatch(setTutorialOn(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    let pollingInterval = INITIAL_POLLING_INTERVAL
    let timeoutId: NodeJS.Timeout | null = null
    let attempts = 0
    const pollData = (): void => {
      if (isDataLoaded && isUserInactive) {
        if (attempts >= MAX_ATTEMPTS || !isUserInactive) {
          return
        }
        try {
          dispatch(getUserReactivationStatus())
        } catch (error) {
          Sentry.captureException(error, {
            tags: {
              method: 'pollData'
            }
          })
        } finally {
          pollingInterval = Math.min(
            pollingInterval * BACKOFF_MULTIPLIER,
            MAX_POLLING_INTERVAL
          )
          attempts++
          timeoutId = setTimeout(pollData, pollingInterval)
        }
      }
    }
    if (isDataLoaded && isUserInactive) {
      pollData()
    }
    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId)
      }
    }
  }, [dispatch, isDataLoaded, isUserInactive])

  useEffect(() => {
    dispatch(setHomePath(true))
  }, [dispatch])

  const handlePromptClose = (): SetHasJustLoggedInAction =>
    dispatch(setHasJustLoggedIn(false))
  const handlePromptAddBtnClick = (): void => {
    dispatch(setHasJustLoggedIn(false))
    history.push(RoutePath.AddToHomescreenInstructions)
  }

  const isTutorialSeenAndClosed = (): boolean => {
    if (!config.DisableTutorial) {
      return HaveSeenTutorial === true && !tutorialOn
    }
    return true
  }

  const handleReactivationDialogClose = (): void => {
    dispatch(updateShowReactivationBonusDialog())
  }
  return !isDataLoaded && isDesktop ? (
    <Box className='home-spinner-container'>
      <Box className='spinner-holder'>
        <Spinner height={AUTH_INDICATOR_SIZE} width={AUTH_INDICATOR_SIZE} />
      </Box>
    </Box>
  ) : (
    <>
      {isDesktop ? (
        <>
          {featureFlags.UX_UPDATES ? (
            <>
              <UpdateCards />
              <UpdatedCampaigns />
            </>
          ) : (
            <>
              <div className='m-top-48'>
                <Campaigns />
              </div>
              <div className='m-top-48'>
                <OfferList />
              </div>
            </>
          )}
          <NewFeatureMessage />
        </>
      ) : (
        <>
          <TabPanel active={activeTab} value={TabsName.Earn}>
            <UpdateCards />
            {featureFlags.UX_UPDATES ? <UpdatedCampaigns /> : <Campaigns />}
            <NewFeatureMessage />
          </TabPanel>
          <TabPanel active={activeTab} value={TabsName.Redeem}>
            <OfferList />
          </TabPanel>
        </>
      )}

      <WelcomeMessage />
      <SignupWelcomeMessage
        image={config.SignUpWelcomeImage ?? ''}
        label={config.SignUpWelcomeButtonLabel ?? ''}
        description={config.SignUpWelcomeDescription ?? ''}
        title={config.SignUpWelcomeTitle ?? ''}
      />
      {!config.DisableTutorial && !isDesktop && !featureFlags.UX_UPDATES ? (
        <TutorialToolTip />
      ) : null}

      {!config.DisableTutorial && !isDesktop && featureFlags.UX_UPDATES ? (
        <TutorialToolTipUpdated />
      ) : null}

      {showReactivationBonusDialog && (
        <ReactivationBonusDialog
          open={showReactivationBonusDialog}
          handleClose={handleReactivationDialogClose}
        />
      )}

      <AddToHomescreenPrompt
        open={
          !isDesktop &&
          config.IsPwaFlowVisible &&
          hasJustLoggedIn &&
          isTutorialSeenAndClosed()
        }
        handleClose={handlePromptClose}
        handleAddBtnClick={handlePromptAddBtnClick}
      />
    </>
  )
}

export default track({
  page: AnalyticsCategory.Home
})(Home)
