import React, { FC } from 'react'

const SuccessIco: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <path d='M0 0L40 0 40 40 0 40z' transform='translate(-140 -265) translate(140 265)' />
            <path fill='var(--secondary)' fillRule='nonzero' d='M19.98 0C8.94 0 0 8.96 0 20s8.94 20 19.98 20C31.04 40 40 31.04 40 20S31.04 0 19.98 0zM20 36c-8.84 0-16-7.16-16-16S11.16 4 20 4s16 7.16 16 16-7.16 16-16 16zm-4.517-8.85L9.5 21.167c-.65-.65-.65-1.7 0-2.35.65-.65 1.7-.65 2.35 0l4.817 4.8L28.133 12.15c.65-.65 1.7-.65 2.35 0 .65.65.65 1.7 0 2.35l-12.65 12.65c-.633.65-1.7.65-2.35 0z' transform='translate(-140 -265) translate(140 265)' />
          </g>
        </g>
      </g>
    </svg>

  )
}

export default SuccessIco
