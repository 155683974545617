import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, MenuItem, TextField } from '@material-ui/core'
import { languageService } from '../service'
import i18n from 'i18next'
import {
  getCampaignCategories,
  getCampaignList,
  getReferralInfo
} from '../../campaign/actions'
import { getOfferCategories, getOfferList } from '../../offer/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getEarningList, updatePreferredLanguage } from '../../user/actions'
import { getConfigInfoByTenant } from '../../config/actions'
import { getProviderList } from '../../provider/actions'
import { selectLanguageList } from '../../config/selectors'
import { useTracking } from 'react-tracking'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import _ from 'lodash'
import CheckIcon from '@material-ui/icons/Check'
import { getUpdateCardList } from '../../updateCards/actions'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
const { REACT_APP_TENANT_ID_FOR_CLIENT } = process.env

const MultiLanguageDropDown: FC = () => {
  const { trackEvent } = useTracking()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const initialLanguage: string = languageService.getLanguage()
  const initialLanguageTitle: string = languageService.getLanguageTitle()
  const selectedLanguage: string = initialLanguage ?? ''
  const [selectedTitle, setSelectedTitle] = useState(initialLanguageTitle ?? '')
  const [isChangeLang, setIsChangeLang] = useState<boolean>(false)
  const languageList: LanguageList[] = useSelector(selectLanguageList)
  const [isOpenLangMenu, setIsOpenLangMenu] = useState<boolean>(false)
  useEffect(() => {
    if (initialLanguage !== selectedLanguage) {
      setIsChangeLang(true)
    }
  }, [initialLanguage, selectedLanguage])
  const handleSave = async (event: any): Promise<void> => {
    const targetLanguage = _.find(languageList, {
      LanguageCode: event.target.value
    })
    if (targetLanguage != null) {
      languageService.setLanguage(
        event.target.value,
        targetLanguage.LanguageName
      )
      setSelectedTitle(targetLanguage.LanguageName)
      await i18n.changeLanguage(event.target.value)
      await Promise.all([
        dispatch(updatePreferredLanguage(event.target.value)),
        dispatch(getConfigInfoByTenant(REACT_APP_TENANT_ID_FOR_CLIENT)),
        dispatch(getCampaignList()),
        dispatch(getCampaignCategories()),
        dispatch(getReferralInfo(REACT_APP_TENANT_ID_FOR_CLIENT)),
        dispatch(getEarningList()),
        dispatch(getOfferList()),
        dispatch(getOfferCategories()),
        dispatch(getProviderList()),
        dispatch(getUpdateCardList())
      ])
      trackEvent({
        action: 'Selected Language',
        category: 'Language',
        payload: {
          title: 'language',
          description: selectedLanguage,
          value: isChangeLang ? selectedLanguage : 'Default'
        }
      })
    }
  }
  const getSelectedLang = (): string => {
    return selectedTitle === 'English'
      ? selectedLanguage.toUpperCase()
      : t([selectedTitle])
  }
  useEffect(() => {
    if (isOpenLangMenu) {
      setIsChangeLang(false)
      trackEvent({
        action: 'Language',
        category: AnalyticsCategory.Menu
      })
    }
  }, [isOpenLangMenu, trackEvent])
  return (
    <Box className='multi-lang-action'>
      <Box className='multi-lang-name'>
        <Box className='multi-lang-name__selected'>
          <p>{getSelectedLang()}</p>
        </Box>
      </Box>
      <TextField
        className='multi-lang-select'
        select
        id='multi-lang-action'
        value=''
        onClick={() => setIsOpenLangMenu(!isOpenLangMenu)}
        onChange={handleSave}
        SelectProps={{
          IconComponent: () => (
            <KeyboardArrowDownIcon style={{ color: 'white' }} />
          ),
          MenuProps: {
            className: 'lang-drop',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null,
            PaperProps: {
              className: 'dropdown'
            }
          }
        }}
      >
        {languageList != null &&
          Object.values(languageList).map((lang, index) => (
            <MenuItem
              key={lang.LanguageName}
              value={lang.LanguageCode}
              title={lang.LanguageName}
              className={`lang-menu-item ${
                selectedLanguage === lang.LanguageCode ? 'selected-lang' : ''
              }`}
            >
              {t([lang.LanguageName])}
              <span>
                <CheckIcon
                  className={`check-icon ${
                    selectedLanguage === lang.LanguageCode
                      ? 'show-icon'
                      : 'hide-icon'
                  }`}
                />
              </span>
            </MenuItem>
          ))}
      </TextField>
    </Box>
  )
}

export default MultiLanguageDropDown
