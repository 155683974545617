import { defaultAction, initialState } from './initialState'
import * as actionTypes from './actionTypes'

const reducer = (state: ProviderAccountState = initialState, action: ConnectionAction = defaultAction): ProviderAccountState => {
  switch (action.type) {
    case actionTypes.GET_PROVIDER_LIST:
      return {
        ...state,
        providerList: [...action.payload]
      }
    case actionTypes.CLEAR_PROVIDER_LIST:
      return {
        ...state,
        providerList: [...action.payload]
      }
    case actionTypes.GET_PROVIDER_STATUS_LIST:
      return {
        ...state,
        providerStatusList: [...action.payload]
      }
    case actionTypes.CLEAR_PROVIDER_STATUS_LIST:
      return {
        ...state,
        providerStatusList: [...action.payload]
      }
    case actionTypes.SET_PROVIDER_STATUS: {
      const providerStatusResult = [...state.providerStatusList].map((provider) => provider.ProviderID === action.payload.ProviderID ? { ...action.payload } : provider)

      return {
        ...state,
        providerStatusList: providerStatusResult
      }
    }
    case actionTypes.DISCONNECT_PROVIDER_STATUS: {
      const providerStatusResult = [...state.providerStatusList]
      const providerStatusIndex = providerStatusResult.findIndex((providerStatus) => providerStatus.ProviderID === action.payload.ProviderID)

      if (providerStatusIndex !== -1) {
        providerStatusResult[providerStatusIndex] = {
          ...providerStatusResult[providerStatusIndex],
          ...action.payload,
          identifier: null
        }
      }

      return {
        ...state,
        providerStatusList: [...providerStatusResult]
      }
    }
    case actionTypes.SET_REDIRECTION_URL:
      return {
        ...state,
        providerRedirectionURL: action.payload
      }
  }
  return state
}

export default reducer
