export const NO_ICON = 'NoIcon'
export const UPDATE_ICON_NAMES = {
  REPORT: 'Report',
  REPORT_PROBLEM: 'ReportProblem',
  INFO: 'Info'
}
export const UPDATE_ICON_CLASSNAMES = {
  REPORT: 'report',
  NEW_RELEASES: 'new-releases',
  INFO: 'info'
}
export const ICON_CLASSNAME_MAP = {
  [UPDATE_ICON_NAMES.REPORT]: UPDATE_ICON_CLASSNAMES.REPORT,
  [UPDATE_ICON_NAMES.REPORT_PROBLEM]: UPDATE_ICON_CLASSNAMES.REPORT,
  [UPDATE_ICON_NAMES.INFO]: UPDATE_ICON_CLASSNAMES.INFO
}
