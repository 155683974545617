import React, { FC } from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  active: string
}

const TabPanel: FC<TabPanelProps> = ({ children, active, value, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={active !== value}
      id={`tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      {active === value && (
        <div>{children}</div>
      )}
    </div>
  )
}

export default TabPanel
