import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import './OfferListCategoryUpdated.scss'
import noResult from '../../../../assets/images/offer/noResult.png'
import { useTranslation } from 'react-i18next'
import OfferListItem from '../OfferListItem/OfferListItem'
import OfferListCategory from './OfferListCategory'
import _ from 'lodash'
interface OfferListCategoryUpdatedProps {
  offerCategoryList: OfferListByCategory[]
  submitHandler: (e: React.FormEvent<HTMLFormElement>) => void
  isFavoriteOffer: boolean
  isRedeemTab: boolean
  term: string
  setIsPhoneVerificationDrawerOpen: (flag: boolean) => void
  setTerm: (searchTerms: string) => void
  isDesktopView?: boolean
}

const OfferListCategoryUpdated: FC<OfferListCategoryUpdatedProps> = ({
  offerCategoryList,
  submitHandler,
  isFavoriteOffer,
  isRedeemTab,
  term,
  setTerm,
  setIsPhoneVerificationDrawerOpen,
  isDesktopView = false
}) => {
  const { t } = useTranslation()
  const [hasResult, setHasResult] = useState<boolean>(false)
  const offerCategoryCount: number = offerCategoryList.filter(
    (cat: OfferListByCategory) => cat.offerList.length > 0
  ).length
  const getFilteredListCount = useCallback(
    (offerCategory: OfferListByCategory): number => {
      let filteredList = 0
      for (let j = 0; j < offerCategory.offerList.length; j++) {
        if (
          !_.isEmpty(offerCategory.offerList[j].Title) &&
          offerCategory.offerList[j].Title.toUpperCase().includes(
            term.toUpperCase()
          )
        ) {
          filteredList = filteredList + 1
        }
      }
      return filteredList
    },
    [term]
  )
  useEffect(() => {
    let resultItemsCount = 0
    offerCategoryList.forEach((offerCategory: OfferListByCategory, i) => {
      const filteredList = getFilteredListCount(offerCategory)
      if (filteredList > 0) {
        resultItemsCount = resultItemsCount + 1
      }

      return resultItemsCount
    })

    if (resultItemsCount > 0) {
      setHasResult(true)
    } else {
      setHasResult(false)
    }
  }, [term, offerCategoryList, getFilteredListCount])
  const getOfferItem = useCallback(
    (index: number, offerItem: Offer): ReactElement => {
      return (
        <div className='offer-wrapper' key={`wrapper_${index}`}>
          <OfferListItem
            key={index}
            offerItem={offerItem}
            handleClickLockedOffer={() =>
              setIsPhoneVerificationDrawerOpen(true)}
            showUpdatedUI={isRedeemTab}
            isFavoriteOfferAvailable={isFavoriteOffer}
          />
        </div>
      )
    },
    [setIsPhoneVerificationDrawerOpen, isFavoriteOffer, isRedeemTab]
  )
  const showOffers = useCallback(
    (offerCategory: OfferListByCategory): ReactElement[] => {
      return offerCategory.offerList.map((offerItem, index) => {
        if (term != null && term.length > 0) {
          if (offerItem.Title.toUpperCase().includes(term.toUpperCase())) {
            return getOfferItem(index, offerItem)
          }
        } else {
          return getOfferItem(index, offerItem)
        }
        return <div key={index} />
      })
    },
    [term, getOfferItem]
  )
  const getResultOffers = useCallback(
    (offerCategory: OfferListByCategory, i: number): ReactElement => {
      return isDesktopView ? (
        <div key={`${offerCategory.OfferCategoryID}-${i}`}>
          <div className='offer-list-category__title'>
            {!_.isEmpty(offerCategory.iconImageUrl) && (
              <img
                src={offerCategory.iconImageUrl}
                alt='Offer List Category'
                className='m-right-8 updated-icon'
              />
            )}
            <p>{offerCategory.CategoryName}</p>
          </div>
          <div className='offer-category-cover'>
            {showOffers(offerCategory)}
          </div>
        </div>
      ) : (
        <div
          className='offer-category-cover'
          key={`${offerCategory.OfferCategoryID}-${i}`}
        >
          <OfferListCategory
            offerCategory={offerCategory}
            handleClickLockedOffer={() =>
              setIsPhoneVerificationDrawerOpen(true)}
            searchFilter={term}
            allowFilter={term != null || term !== ''}
            offerCategoryCount={offerCategoryCount}
          />
        </div>
      )
    },
    [
      showOffers,
      setIsPhoneVerificationDrawerOpen,
      isDesktopView,
      term,
      offerCategoryCount
    ]
  )
  return useMemo(() => {
    const hasSearchTerm = term != null && term !== ''
    if (hasSearchTerm && hasResult) {
      return (
        <div className='offer-category-wrapper'>
          {offerCategoryList.map((offerCategory: OfferListByCategory, i) => {
            const filteredList = getFilteredListCount(offerCategory)
            if (filteredList > 0) {
              return getResultOffers(offerCategory, i)
            } else {
              return null
            }
          })}
        </div>
      )
    } else if (hasSearchTerm && !hasResult) {
      return (
        <div className='offer-category-cover'>
          <div
            className={`noResult-wrapper ${
              isDesktopView ? 'noResult_desktop' : ''
            }`}
          >
            <div className='noResult-img'>
              <img src={noResult} alt='OfferList No Result' />
            </div>
            <div className='noResult-text'>
              <p className='top'>{t`NoResults`}</p>
              <p className='middle'>{term}</p>
              <p className='bottom'>{t`CheckSpelling`}</p>
            </div>
            <div className='noResult-btn'>
              <form onSubmit={submitHandler}>
                <button
                  onClick={() => {
                    setTerm('')
                  }}
                >
                  {t`ResetSearchFilter`}
                </button>
              </form>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className={`${
            isDesktopView ? 'offer-category-wrapper' : 'offer-category-cover'
          }`}
        >
          {offerCategoryList.map(
            (offerCategory: OfferListByCategory, i) =>
              offerCategory.offerList.length > 0 &&
              getResultOffers(offerCategory, i)
          )}
        </div>
      )
    }
  }, [
    term,
    offerCategoryList,
    submitHandler,
    hasResult,
    t,
    getResultOffers,
    isDesktopView,
    setTerm,
    getFilteredListCount
  ])
}

export default OfferListCategoryUpdated
