import React, { FC, ReactElement } from 'react'
import './OfferListItemUpdated.scss'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { IconButton, useMediaQuery } from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { getOfferList, setFavoriteOffer } from '../../actions'
import { useDispatch } from 'react-redux'
import { useTracking } from 'react-tracking'
import { getFavoriteOfferCount } from '../../../user/actions'
import { RedeemCalc } from './OfferListItem'
import { breakpoints } from '../../../core/utils/css-selectors'

interface OfferListItemUpdatedProps {
  handleOfferItemClick: () => void
  handleUnavailableOfferItemClick: () => void
  points: ReactElement
  subTitle: string
  title: string
  isOfferStatusSoldOut: boolean
  isOfferStatusUnavailable: boolean
  isBlocked: boolean
  isFavoriteOfferAvailable?: boolean
  OfferID: string
  isFavorite: boolean
  disableFavorite: boolean
  redeemCalc: RedeemCalc
}

const OfferListItemUpdated: FC<OfferListItemUpdatedProps> = ({
  handleOfferItemClick,
  points,
  subTitle,
  title,
  isOfferStatusSoldOut,
  isOfferStatusUnavailable,
  handleUnavailableOfferItemClick,
  isBlocked,
  isFavoriteOfferAvailable = false,
  OfferID,
  isFavorite,
  redeemCalc,
  disableFavorite
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { trackEvent } = useTracking()
  const handleClick = (): void => {
    isOfferStatusUnavailable
      ? handleUnavailableOfferItemClick()
      : handleOfferItemClick()
  }
  const handleFavoriteOffer = async (
    OfferID: string,
    isFavorite: boolean
  ): Promise<void> => {
    await Promise.all([
      await dispatch(setFavoriteOffer(OfferID, !isFavorite)),
      dispatch(getOfferList()),
      dispatch(getFavoriteOfferCount())
    ])
    trackEvent({
      category: 'Redemption',
      action: isFavorite ? 'Unmark Favorite' : 'Mark Favorite',
      payload: {
        title: isFavorite ? 'Unmark Favorite' : 'Mark Favorite',
        description: 'ID of the Offer',
        value: OfferID
      }
    })
  }
  return (
    <div className='offer-list-item-update-wrapper'>
      <div
        className={`offer-list-item-updated ${
          isBlocked || isOfferStatusSoldOut
            ? 'blocked-cursor'
            : 'available-cursor'
        }`}
        onClick={handleClick}
      >
        <div className='main'>
          <div
            className={`offer-points ${
              isOfferStatusSoldOut ||
              isOfferStatusUnavailable ||
              redeemCalc.difference < 0
                ? 'unavailable-background'
                : 'available-background'
            }
            ${matchesMd ? 'offer-points__desktop' : ''}`}
          >
            {points}
          </div>
          <div className='offer-info'>
            <p className={`subheader ${matchesMd ? 'subheader__desktop' : ''}`}>
              {subTitle}
            </p>
            <p className={`title ${_.isEmpty(subTitle) ? 'mt-0' : ''} ${
                matchesMd ? 'title__desktop' : ''
              }`}
            >
              {title}
            </p>
          </div>
        </div>
        <div className={`status ${isFavoriteOfferAvailable ? 'pr-4' : ''}`}>
          {isOfferStatusSoldOut && <p className='sold-out'>{t`SoldOut`}</p>}
          {isOfferStatusUnavailable && (
            <p className='unavailable'>{t`Unavailable`}</p>
          )}
        </div>
      </div>
      {isFavoriteOfferAvailable && (
        <IconButton
          onClick={async () => await handleFavoriteOffer(OfferID, isFavorite)}
          className={`favorite-icon ${
            !isFavorite && disableFavorite ? 'favorite-disabled' : ''
          }`}
        >
          {isFavorite ? (
            <FavoriteIcon className='selected-favorite-icon' />
          ) : (
            <FavoriteBorderIcon className='unselected-favorite-icon' />
          )}
        </IconButton>
      )}
    </div>
  )
}

export default OfferListItemUpdated
