import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { useMediaQuery } from '@react-hook/media-query'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Close'
import OfferListPrompt from './OfferListPrompt/OfferListPrompt'
import { selectOfferList, selectOfferListByCategory } from '../selectors'
import { selectUser } from '../../user/selectors'
import PhoneVerificationDrawer from './PhoneVerificationDrawer/PhoneVerificationDrawer'
import { selectIsActionLoaded } from '../../config/selectors'
import { GET_OFFER_LIST, GET_OFFER_CATEGORIES } from '../actionTypes'
import { breakpoints } from '../../core/utils/css-selectors'
import FavoriteOfferList from './FavoriteOfferList/FavoriteOfferList'
import { useTracking } from 'react-tracking'
import { useTranslation } from 'react-i18next'
import OfferListItemSkeletonUpdated from './OfferListItem/OfferListItemSkeletonUpdated'
import './OfferList.scss'
import OfferListCategoryUpdated from './OfferListCategory/OfferListCategoryUpdated'

const FIRST_INDEX = 0
const LIST_ITEM_SKELETON_NUM = 3
const VERIFIED = 'VERIFIED'
let firstInit: boolean = true
const OfferList: FC = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const offerList: Offer[] = useSelector(selectOfferList)
  const offerCategoryList: OfferListByCategory[] = useSelector(
    selectOfferListByCategory
  )
  const [isPhoneVerificationDrawerOpen, setIsPhoneVerificationDrawerOpen] =
    useState(false)

  const isOfferCategoryLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_OFFER_CATEGORIES)
  )
  const isOfferListLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_OFFER_LIST)
  )
  const [isSearchActive, setSearchActive] = useState<boolean>(false)

  const [term, setTerm] = useState<string>('')
  const [filter, setFilter] = useState('')
  const [usefilter, setUseFilter] = useState(false)
  const [cleanTerm, setCleanTerm] = useState(true)
  const maxRowOffersMd = 1
  const maxRowOffersXs = 3
  const maxColumnOffersMd = 3
  const maxColumnOffersXs = 2
  const SEARCH_ALL = 'ALL'
  const TERM_LIMIT = 20
  const [amountMax, setAmountMax] = useState<number>(maxRowOffersXs)
  let categoryUIIndex = 0
  const { featureFlags }: UserState = useSelector(selectUser)

  useEffect(() => {
    matchesMd ? setAmountMax(maxRowOffersMd) : setAmountMax(maxRowOffersXs)
  }, [matchesMd])

  const handleClosePhoneVerification = (): void => {
    setIsPhoneVerificationDrawerOpen(false)
  }

  const user: UserState = useSelector(selectUser)
  useEffect(() => {
    if (firstInit && offerList.length > 0) {
      firstInit = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerList])

  useEffect(() => {
    if (term === null || term === '') {
      setFilter(SEARCH_ALL)
      setUseFilter(false)
      setSearchActive(false)
      setCleanTerm(true)
    } else {
      setFilter(term)
      setUseFilter(true)
      setSearchActive(true)
      if (term.length > 1) {
        setCleanTerm(false)
      }
      if (term.length === 1 && cleanTerm) {
        trackEvent({
          action: 'Search Offer',
          category: 'Redemption'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term])

  const submitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setTerm(term)
  }

  const handleTermChange = (event: ChangeEvent<HTMLInputElement>): any => {
    setTerm(event.target.value.slice(0, TERM_LIMIT))
  }

  return (
    <div className='offer-list-cover'>
      {isOfferListLoaded && isOfferCategoryLoaded ? (
        offerList.length > 0 && (
          <>
            <OfferListPrompt
              setIsPhoneVerificationDrawerOpen={
                setIsPhoneVerificationDrawerOpen
              }
            />
            {user.userStatus === VERIFIED && (
              <div className={`offer-search ${matchesMd ? 'mt-1' : ''}`}>
                <form onSubmit={submitHandler}>
                  <input
                    type='text'
                    className={`searchBar ${
                      featureFlags.UX_UPDATES ? 'custom-search' : ''
                    }`}
                    value={term}
                    placeholder={
                      featureFlags.UX_UPDATES
                        ? t`SearchForOfferUpdated`
                        : t`SearchForOffer`
                    }
                    onChange={handleTermChange}
                  />
                  <button
                    disabled
                    className={`searchBtn ${
                      featureFlags.UX_UPDATES ? 'custom-icon' : ''
                    }`}
                  >
                    <SearchIcon />
                  </button>
                  <button
                    className={`searchClearBtn ${
                      isSearchActive ? 'searchClearBtn--active' : ''
                    }`}
                    onClick={() => {
                      setTerm('')
                    }}
                  >
                    <ClearIcon />
                  </button>
                </form>
              </div>
            )}
            {featureFlags.UX_UPDATES && featureFlags.FAVORITE_OFFER && (
              <div className='offer-favorite-cover'>
                <FavoriteOfferList
                  offerCategoryList={offerCategoryList}
                  categoryIndex={categoryUIIndex++}
                  handleClickLockedOffer={() =>
                    setIsPhoneVerificationDrawerOpen(true)}
                  searchFilter={filter}
                  allowFilter={usefilter}
                />
              </div>
            )}
            <OfferListCategoryUpdated
              offerCategoryList={offerCategoryList}
              isFavoriteOffer={featureFlags.FAVORITE_OFFER}
              isRedeemTab={featureFlags.UX_UPDATES}
              term={term}
              setTerm={setTerm}
              submitHandler={submitHandler}
              setIsPhoneVerificationDrawerOpen={setIsPhoneVerificationDrawerOpen}
            />
            <PhoneVerificationDrawer
              isPhoneVerificationDrawerOpen={isPhoneVerificationDrawerOpen}
              handleClosePhoneVerificationDrawer={handleClosePhoneVerification}
              handleOpenPhoneVerificationDrawer={
                setIsPhoneVerificationDrawerOpen
              }
            />
          </>
        )
      ) : matchesMd || (!matchesMd && !featureFlags.UX_UPDATES) ? (
        <div className='offer-list--loading'>
          {Array.from({ length: amountMax }).map((_, i) => (
            <div
              className='offer-list--loading__wrapper'
              key={`skeletonCategory-${i}`}
            >
              <Skeleton
                variant='rect'
                classes={{
                  root: 'offer-list--loading__category-title skeleton-txt'
                }}
              />
              <div className='offer-list--loading__description'>
                {Array.from({ length: matchesMd ? maxColumnOffersMd : maxColumnOffersXs })
                  .map((_, i) => (
                    <div
                      className='offer-list--loading__description__item'
                      key={`skeletonItem-${i}`}
                    >
                      <Skeleton
                        variant='rect'
                        classes={{
                          root: 'offer-list--loading__description__item__img'
                        }}
                      />
                      <Skeleton
                        variant='rect'
                        classes={{
                          root: 'offer-list--loading__description__item__title skeleton-txt--default'
                        }}
                      />
                      <Skeleton
                        variant='rect'
                        classes={{
                          root: 'offer-list--loading__description__item__subtitle skeleton-txt--default'
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <OfferListItemSkeletonUpdated
          maxColumn={maxColumnOffersXs}
          firstIndex={FIRST_INDEX}
          skeletonNum={LIST_ITEM_SKELETON_NUM}
        />
      )}
    </div>
  )
}

export default OfferList
