import { FC, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Button, InputBase } from '@material-ui/core'
import { IdList } from '../../../core/utils/id-list'
import { copyToDashboard } from '../../../core/utils/copy'
import LinkIcon from '@material-ui/icons/Link'
import * as RF_CONSTANTS from './ReferFormConstant'
import { useTracking } from 'react-tracking'
import './ReferForm.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

interface ReferFormProps {
  btnTxt: string
  referralConfiguration: any
  referrerRecord: any
}

const ReferForm: FC<ReferFormProps> = ({
  btnTxt,
  referralConfiguration = null,
  referrerRecord = null
}) => {
  const { t } = useTranslation()
  const textCopyRef = useRef<HTMLInputElement>()
  const copyLinkRef = useRef<HTMLInputElement>()
  const [copyLinkText, setcopyLinkText] = useState<string>(t`ReferCopyLink`)
  const [copyLinkUrl, setCopyLinkUrl] = useState<string>('')
  const [copyCodeMsg, setcopyCodeMsg] = useState<boolean>(false)
  const [copyLinkMsg, setcopyLinkMsg] = useState<boolean>(false)
  const [couponCode, setCouponCode] = useState<string>('')
  const { trackEvent } = useTracking()
  const actionForCTA = 'Copy Referral Code'
  const actionForLink = 'Copy Referral Link'
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const copyButtonPressEvent = (action: string, origin: string): void => {
    trackEvent({
      action,
      page: AnalyticsCategory.Referral,
      payload: {
        campaignID: referralConfiguration.CampaignID,
        origin
      }
    })
  }
  const copyToClipboardFromCTA = (): void => {
    copyButtonPressEvent(actionForCTA, 'CTA')
    textCopyRef.current?.select()
    document.execCommand('copy')
    setcopyCodeMsg(true)
    setTimeout(() => {
      setcopyCodeMsg(false)
    }, RF_CONSTANTS.delayAfterCopyClipBoard)
  }
  const copyToClipboardFromLink = (): void => {
    copyButtonPressEvent(actionForLink, 'Link')
    const copyText = `${copyLinkUrl}\n${couponCode}`
    copyToDashboard(copyText)
    setcopyLinkMsg(true)
    setTimeout(() => {
      setcopyLinkMsg(false)
    }, RF_CONSTANTS.delayAfterCopyClipBoard)
  }
  useEffect(() => {
    if (referralConfiguration != null) {
      if (worthToChange(referralConfiguration.CopyLinkText)) {
        setcopyLinkText(referralConfiguration.CopyLinkText)
      }
      if (worthToChange(referralConfiguration.CopyLinkUrl)) {
        setCopyLinkUrl(referralConfiguration.CopyLinkUrl)
      }
    }
  }, [referralConfiguration])
  useEffect(() => {
    if (referrerRecord != null) {
      if (worthToChange(referralConfiguration.ReferralCouponCode)) {
        setCouponCode(referralConfiguration.CopyLinkText)
      }
    }
  }, [referralConfiguration, referrerRecord])
  const worthToChange = (obj: any): boolean => {
    if (obj != null && obj !== '') {
      return obj
    }
    return false
  }
  function routeComponent (): any {
    if (copyCodeMsg) {
      return (
        <div
          className='refer-drawer-code__copy'
          style={{ backgroundColor: '#B0E8C0' }}
        >
          <p className='refer-copy-msg'>{t`CodeCopiedInClipboard`}</p>
        </div>
      )
    } else if (copyLinkMsg) {
      return (
        <div
          className='refer-drawer-code__copy'
          style={{ backgroundColor: '#B0E8C0' }}
        >
          <p className='refer-copy-msg'>{t`InviteLinkCopiedInClipboard`}</p>
        </div>
      )
    } else if (!copyCodeMsg && !copyLinkMsg) {
      return (
        <div className='refer-drawer-code__copy'>
          <InputBase
            value={
              referrerRecord != null ? referrerRecord.ReferralCouponCode : ''
            }
            inputProps={{ ref: textCopyRef }}
            id={IdList.referCode}
            className='refer-drawer-code__copy__input'
            autoFocus={false}
            readOnly
          />
          <Button
            id={IdList.referCopyCode}
            onClick={copyToClipboardFromCTA}
            className='refer-drawer-code__copy__btn'
          >
            {btnTxt}
          </Button>
        </div>
      )
    }
  }
  return (
    <div className='refer-drawer-code'>
      {routeComponent()}
      {!matchesMd && (
        <div className='refer-drawer-code__or'>
          <p>{t`or`}</p>
        </div>
      )}
      <div className='refer-drawer-code__action'>
        <InputBase
          value={copyLinkUrl}
          inputProps={{ ref: copyLinkRef }}
          className='refer-drawer-code__action__input'
          autoFocus={false}
          id={IdList.referLink}
          readOnly
        />
        <Button
          onClick={copyToClipboardFromLink}
          className='refer-drawer-code__action__link'
          id={IdList.referCopyLink}
        >
          <LinkIcon />
          {copyLinkText}
        </Button>
      </div>
    </div>
  )
}
export default ReferForm
