import transitImg from '../../../assets/images/highlight/transit.png'
import claimImg from '../../../assets/images/highlight/claim.png'
import redeemImg from '../../../assets/images/highlight/redeem.png'

export const baseWelcomeScreen = {
  PrivacyPolicyURL: 'https://velocia.io/',
  TermsAndConditionsURL: 'https://velocia.io/',
  MoreInformationLinkURL: 'https://velocia.io/'
}

export const baseWelcomeSteps = [
  {
    StepTitle: '',
    StepDescription: '',
    StepImage: transitImg
  },
  {
    StepTitle: '',
    StepDescription: '',
    StepImage: claimImg
  },
  {
    StepTitle: '',
    StepDescription: '',
    StepImage: redeemImg
  }
]
