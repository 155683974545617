import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppBar, Toolbar } from '@material-ui/core'

import { IdList } from '../../core/utils/id-list'
import { RoutePath } from '../../core/routes/route-path'
import { selectConfig } from '../../config/selectors'
import Error from './Error'

const ErrorPage: FC = () => {
  const config: ConfigState = useSelector(selectConfig)

  return (
    <>
      <AppBar position='static' className='header'>
        <Toolbar className='header-toolbar'>
          <div className='flex height-full'>
            <a id={IdList.navLogo} href={RoutePath.Home} className='flex align-center'>
              <div style={{ backgroundImage: `url(${config.TenantLogoURL})` }} className='header-toolbar__logo' />
            </a>
          </div>
        </Toolbar>
      </AppBar>

      <Error />
    </>
  )
}

export default ErrorPage
