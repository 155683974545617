import { FC, useState } from 'react'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'

import PhoneVerificationMobile from './PhoneVerificationMobile/PhoneVerificationMobile'
import PhoneVerificationWithCode from './PhoneVerificationWithCode/PhoneVerificationWithCode'
import PhoneVerificationSuccess from './PhoneVerificationSuccess/PhoneVerificationSuccess'
import PhoneVerificationAlreadyUsed from './PhoneVerificationAlreadyUsed/PhoneVerificationAlreadyUsed'
import { userService } from '../../../user/service'

import phoneVerify from '../../../../assets/images/offer/phoneVerify.png'
import { CUSTOME_ERROR_MESSAGE } from '../../../constants'

interface PhoneVerificationDrawerProps {
  isPhoneVerificationDrawerOpen: boolean
  handleClosePhoneVerificationDrawer: () => void
  handleOpenPhoneVerificationDrawer: (flag: boolean) => void
}

const PhoneVerificationDrawer: FC<PhoneVerificationDrawerProps> = ({
  isPhoneVerificationDrawerOpen,
  handleClosePhoneVerificationDrawer,
  handleOpenPhoneVerificationDrawer
}) => {
  const [isPhoneVerificationWithCodeOpen, setIsPhoneVerificationWithCodeOpen] =
    useState(false)
  const [isPhoneVerificationSuccessOpen, setIsPhoneVerificationSuccessOpen] =
    useState(false)
  const [
    isPhoneVerificationAlreadyUsedOpen,
    setIsPhoneVerificationAlreadyUsedOpen
  ] = useState(false)
  const [verificationCode, setVerificationCode] = useState<VerificationCode>()
  const [mobilePhone, setMobilePhone] = useState<string>('')
  const [isSpinner, setIsSpinner] = useState(false)
  const [isMigrationRequest, setIsMigrationRequest] = useState(false)

  const showMessage = (error?: string): void => {
    if (error !== undefined) {
      setIsSpinner(true)
      setIsPhoneVerificationSuccessOpen(false)
      setIsPhoneVerificationAlreadyUsedOpen(false)
      setIsPhoneVerificationWithCodeOpen(true)
    } else {
      setIsSpinner(false)
    }
  }

  const showSpinner = (): void => {
    setIsPhoneVerificationWithCodeOpen(false)
    setIsPhoneVerificationSuccessOpen(true)
    setIsSpinner(true)
  }

  const handleClosePhoneVerificationSuccess = (): void => {
    handleClosePhoneVerificationDrawer()
    setIsPhoneVerificationSuccessOpen(false)
    setIsPhoneVerificationAlreadyUsedOpen(false)
    setIsPhoneVerificationWithCodeOpen(false)
  }

  const handleOpenPhoneVerificationWithCode = (
    code: VerificationCode,
    mobile: string
  ): void => {
    setVerificationCode(code)
    setMobilePhone(mobile)
    setIsPhoneVerificationWithCodeOpen(true)
  }

  const handleOpenPhoneVerificationAlreadyUsed = (mobile: string): void => {
    setIsMigrationRequest(true)
    setMobilePhone(mobile)
    setIsSpinner(false)
    setIsPhoneVerificationAlreadyUsedOpen(true)
  }

  const handleClosePhoneVerificationAlreadyUsed = (): void => {
    setIsPhoneVerificationAlreadyUsedOpen(false)
    setIsPhoneVerificationWithCodeOpen(false)
    handleOpenPhoneVerificationDrawer(true)
  }

  const handleClosePhoneVerificationUseSameNumber = (): void => {
    handleClosePhoneVerificationDrawer()
    setIsPhoneVerificationAlreadyUsedOpen(false)
  }

  const handleClosePhoneVerificationWithCode = (): void => {
    setIsPhoneVerificationWithCodeOpen(false)
    setIsPhoneVerificationAlreadyUsedOpen(false)
    handleClosePhoneVerificationDrawer()
  }

  const handleBack = (): void => {
    handleOpenPhoneVerificationDrawer(true)
    setIsPhoneVerificationWithCodeOpen(false)
  }

  const validatePhoneNumber = async (): Promise<void> => {
    return await userService
      .validatePhoneNumber(mobilePhone, isMigrationRequest)
      .then((code) => {
        setVerificationCode(code)
        setIsPhoneVerificationWithCodeOpen(true)
      })
      .catch((error) => {
        toast.error(
          error?.message != null && error?.message.length > 0
            ? error?.message
            : `${CUSTOME_ERROR_MESSAGE} validate phone number`
        )
        Sentry.captureException(error, {
          tags: {
            method: 'validatePhoneNumber'
          }
        })
      })
  }

  return (
    <div className='phone-verification'>
      <PhoneVerificationMobile
        handleOpenPhoneVerificationWithCode={handleOpenPhoneVerificationWithCode}
        handleOpenPhoneVerificationAlreadyUsed={handleOpenPhoneVerificationAlreadyUsed}
        isPhoneVerificationMobileOpen={isPhoneVerificationDrawerOpen}
        handleClosePhoneVerificationMobile={handleClosePhoneVerificationDrawer}
        phoneVerifyImg={phoneVerify}
        isMigrationRequest={isMigrationRequest}
      />

      <PhoneVerificationWithCode
        verificationCode={verificationCode}
        mobilePhone={mobilePhone}
        isPhoneVerificationWithCodeOpen={isPhoneVerificationWithCodeOpen}
        handleClosePhoneVerificationWithCode={
          handleClosePhoneVerificationWithCode
        }
        handleBack={handleBack}
        resendCode={validatePhoneNumber}
        showMessage={showMessage}
        showSpinner={showSpinner}
        isMigrationRequest={isMigrationRequest}
      />

      <PhoneVerificationSuccess
        isSpinner={isSpinner}
        isPhoneVerificationSuccessOpen={isPhoneVerificationSuccessOpen}
        handleClosePhoneVerificationSuccess={
          handleClosePhoneVerificationSuccess
        }
      />

      <PhoneVerificationAlreadyUsed
        isSpinner={isSpinner}
        validatePhoneNumber={validatePhoneNumber}
        isPhoneVerificationAlreadyUsed={isPhoneVerificationAlreadyUsedOpen}
        handleClosePhoneVerificationAlreadyUsed={handleClosePhoneVerificationAlreadyUsed}
        handleClosePhoneVerificationUseSameNumber={
          handleClosePhoneVerificationUseSameNumber
        }
      />
    </div>
  )
}

export default PhoneVerificationDrawer
