import React, { ReactElement, useEffect, useState } from 'react'
import './ManageAccount.scss'
import AccountDetails from './AccountDetails'
import AccountDetailsEdit from './AccountDetailsEdit'
import AccountPasswordEdit from './AccountPasswordEdit'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../user/selectors'
import { Dispatch } from 'redux'
import {
  editAccountInfo,
  editAccountPassword,
  resetEditAccountInfoError,
  resetEditAccountPasswordError
} from '../actions'
import { selectAuth } from '../selectors'
import { useMediaQuery } from '@react-hook/media-query'
import { useTranslation } from 'react-i18next'
import { RES_TYPE_GET_USER_INFO, STATUS_OK } from '../constants'
import track, { useTracking } from 'react-tracking'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import _ from 'lodash'
import { breakpoints } from '../../core/utils/css-selectors'

interface ManageAccountProps {
  isDrawerView?: boolean
  changeDrawerTitle: (value: boolean) => void
}

const ManageAccount = ({
  isDrawerView = false,
  changeDrawerTitle
}: ManageAccountProps): ReactElement | null => {
  const { name, email, UserID }: UserState = useSelector(selectUser)
  const [isEditInfo, setIsEditInfo] = useState<boolean>(false)
  const [isEditPassword, setIsEditPassword] = useState<boolean>(false)
  const dispatch: Dispatch<UserAction> = useDispatch()
  const authState = useSelector(selectAuth)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const i18t = useTranslation()
  const { trackEvent } = useTracking()

  const handleEditInfoClick = (): void => {
    setIsEditInfo(true)
    trackEvent({
      page: 'User Account',
      action: 'Edit Information'
    })
  }

  const handleUpdateInfoSubmission = (data: EditAccountInfoParams): void => {
    dispatch(resetEditAccountInfoError())
    const newData = {
      ...data,
      UserID
    }
    dispatch(editAccountInfo(newData, i18t)).then((res?: UserInfoAction) => {
      if (
        res != null &&
        !_.isEmpty(res.type) &&
        res.type === RES_TYPE_GET_USER_INFO
      ) {
        setIsEditInfo(false)
        trackEvent({
          page: 'User Account',
          action: 'Update Information'
        })
      }
    })
  }
  const handleUpdatePasswordSubmission = (data: EditPasswordParams): void => {
    const newData = {
      ...data,
      UserID,
      email
    }
    dispatch(editAccountPassword(newData, i18t)).then((res: Response) => {
      setIsEditPassword(false)
      if (res?.status === STATUS_OK) {
        dispatch(resetEditAccountPasswordError())
        trackEvent({
          page: 'User Account',
          action: 'Update Password'
        })
      }
      if (isDrawerView && res?.status === STATUS_OK) {
        changeDrawerTitle(false)
      }
    })
  }
  const handleEditPasswordClick = (): void => {
    setIsEditPassword(true)
    isDrawerView && changeDrawerTitle(true)
    trackEvent({
      page: 'User Account',
      action: 'Change Password'
    })
  }
  const handleCancelClick = (): void => {
    setIsEditInfo(false)
    setIsEditPassword(false)
    dispatch(resetEditAccountInfoError())
    dispatch(resetEditAccountPasswordError())
    isDrawerView && changeDrawerTitle(false)
  }

  useEffect(() => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'My Account'
    })
  }, [trackEvent])

  useEffect(() => {
    handleCancelClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return matchesMd || isDrawerView ? (
    isEditInfo || authState.editAccountInfoErr != null ? (
      <>
        <AccountDetailsEdit
          isDrawerView={isDrawerView}
          name={name}
          email={email}
          handleCancelClick={handleCancelClick}
          handleUpdateInfoSubmission={(data) =>
            handleUpdateInfoSubmission(data)}
        />
      </>
    ) : isEditPassword || authState.editAccountPasswordErr != null ? (
      <AccountPasswordEdit
        isDrawerView={isDrawerView}
        handleCancelClick={handleCancelClick}
        handleUpdatePasswordSubmission={(data) =>
          handleUpdatePasswordSubmission(data)}
      />
    ) : (
      <AccountDetails
        name={name}
        email={email}
        handleEditInfoClick={handleEditInfoClick}
        handleEditPasswordClick={handleEditPasswordClick}
        isDrawerView={isDrawerView}
      />
    )
  ) : null
}

export default track({
  page: AnalyticsCategory.UserAccount
})(ManageAccount)
