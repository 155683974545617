import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ReferCompleteBar from './ReferCompleteBar'

import './ReferStatics.scss'

interface ReferStaticsProps {
  referralStatistics: any
  requireAction: boolean
}
const ReferStatics: FC<ReferStaticsProps> = ({
  referralStatistics = null,
  requireAction = false
}) => {
  const { t } = useTranslation()
  const completedActionStats =
    referralStatistics != null ? referralStatistics.completedAction : 0
  const enteredStats = referralStatistics?.codeEntered ?? 0
  const calaCompleted = Math.round(
    (parseInt(completedActionStats) / parseInt(enteredStats)) * 100
  )
  const completedAction = calaCompleted.toString()

  if (enteredStats === 0) {
    return <></>
  }
  return (
    <div className='ReferStatics--wrapper'>
      <div className='ReferStatics_title'>
        <p>{t`ReferStatics`}</p>
      </div>
      {requireAction && <ReferCompleteBar completed={completedAction} />}
      {requireAction && (
        <div className='ReferStatics_completed'>
          <table className='ReferStatics_contents'>
            <tbody>
              <tr>
                <td className='ReferStatics_contents-button'>
                  <p className='ReferStaticsButton' />
                </td>
                <td className='ReferStatics_contents-text'>
                  <span>{t`ReferCompleted`}</span>
                  <span className='ReferStatics_contents-text_number'>
                    {completedActionStats}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className='ReferStatics_entered'>
        <table className='ReferStatics_contents'>
          <tbody>
            <tr>
              <td className='ReferStatics_contents-button'>
                <p className='ReferStaticsButton' />
              </td>
              <td className='ReferStatics_contents-text'>
                <span>{t`ReferEntered`}</span>
                <span className='ReferStatics_contents-text_number'>
                  {enteredStats}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default ReferStatics
