import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState
} from 'react'
import OfferListItemSkeletonUpdated from '../OfferList/OfferListItem/OfferListItemSkeletonUpdated'
import './RedeemUpdated.scss'
import { useSelector } from 'react-redux'
import { selectIsActionLoaded } from '../../config/selectors'
import { GET_OFFER_CATEGORIES, GET_OFFER_LIST } from '../actionTypes'
import { selectOfferList, selectOfferListByCategory } from '../selectors'
import OfferListPrompt from '../OfferList/OfferListPrompt/OfferListPrompt'
import { selectUser } from '../../user/selectors'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import FavoriteOfferList from '../OfferList/FavoriteOfferList/FavoriteOfferList'
import PhoneVerificationDrawer from '../OfferList/PhoneVerificationDrawer/PhoneVerificationDrawer'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import OfferList from '../OfferList/OfferList'
import OfferListCategoryUpdated from '../OfferList/OfferListCategory/OfferListCategoryUpdated'

const FIRST_INDEX = 0
const LIST_ITEM_SKELETON_NUM = 3
const maxColumnOffersXs = 2
const SEARCH_ALL = 'ALL'
const TERM_LIMIT = 20

const RedeemUpdated: FC = () => {
  let categoryUIIndex = 0
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const isDesktopView = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const offerList: Offer[] = useSelector(selectOfferList)
  const isOfferCategoryLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_OFFER_CATEGORIES)
  )
  const isOfferListLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_OFFER_LIST)
  )
  const { featureFlags }: UserState = useSelector(selectUser)
  const [isPhoneVerificationDrawerOpen, setIsPhoneVerificationDrawerOpen] =
    useState(false)
  const [isSearchActive, setSearchActive] = useState<boolean>(false)

  const offerCategoryList: OfferListByCategory[] = useSelector(
    selectOfferListByCategory
  )
  const [term, setTerm] = useState<string>('')
  const [filter, setFilter] = useState('')
  const [cleanTerm, setCleanTerm] = useState<boolean>(true)
  const handleClosePhoneVerification = (): void => {
    setIsPhoneVerificationDrawerOpen(false)
  }

  const handleTermChange = (event: ChangeEvent<HTMLInputElement>): any => {
    setTerm(event.target.value.slice(0, TERM_LIMIT))
  }
  const user: UserState = useSelector(selectUser)

  useEffect(() => {
    if (term === null || term === '') {
      setFilter(SEARCH_ALL)
      setSearchActive(false)
      setCleanTerm(true)
    } else {
      setFilter(term)
      setSearchActive(true)
      if (term.length > 1) {
        setCleanTerm(false)
      }
      if (term.length === 1 && cleanTerm) {
        trackEvent({
          action: 'Search Offer',
          category: 'Redemption'
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term])
  const submitHandler = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setTerm(term)
  }, [term])

  return isDesktopView ? (
    <div className='offer-list-cover m-top-48 offer-list-cover__desktop'>
      {isOfferListLoaded && isOfferCategoryLoaded ? (
        offerList.length > 0 && (
          <>
            <OfferListPrompt
              setIsPhoneVerificationDrawerOpen={
                setIsPhoneVerificationDrawerOpen
              }
            />
            {user.userStatus === 'VERIFIED' && (
              <div className={`offer-search ${isDesktopView ? 'mt-1' : ''}`}>
                <form onSubmit={submitHandler}>
                  <input
                    type='text'
                    className={`searchBar ${
                      featureFlags.UX_UPDATES ? 'custom-search' : ''
                    }`}
                    value={term}
                    placeholder={
                      featureFlags.UX_UPDATES
                        ? t`SearchForOfferUpdated`
                        : t`SearchForOffer`
                    }
                    onChange={handleTermChange}
                  />
                  <button
                    disabled
                    className={`searchBtn ${
                      featureFlags.UX_UPDATES ? 'custom-icon' : ''
                    }`}
                  >
                    <SearchIcon />
                  </button>
                  <button
                    className={`searchClearBtn ${
                      isSearchActive ? 'searchClearBtn--active' : ''
                    }`}
                    onClick={() => {
                      setTerm('')
                    }}
                  >
                    <ClearIcon />
                  </button>
                </form>
              </div>
            )}
            {featureFlags.UX_UPDATES && featureFlags.FAVORITE_OFFER && (
              <div className='offer-favorite-cover'>
                <FavoriteOfferList
                  offerCategoryList={offerCategoryList}
                  categoryIndex={categoryUIIndex++}
                  handleClickLockedOffer={() =>
                    setIsPhoneVerificationDrawerOpen(true)}
                  searchFilter={filter}
                  allowFilter={!(term === null || term === '')}
                />
              </div>
            )}
            <OfferListCategoryUpdated
              offerCategoryList={offerCategoryList}
              isFavoriteOffer={featureFlags.FAVORITE_OFFER}
              isRedeemTab={featureFlags.UX_UPDATES}
              term={term}
              setTerm={setTerm}
              submitHandler={submitHandler}
              setIsPhoneVerificationDrawerOpen={setIsPhoneVerificationDrawerOpen}
              isDesktopView={isDesktopView}
            />
            <PhoneVerificationDrawer
              isPhoneVerificationDrawerOpen={isPhoneVerificationDrawerOpen}
              handleClosePhoneVerificationDrawer={handleClosePhoneVerification}
              handleOpenPhoneVerificationDrawer={
                setIsPhoneVerificationDrawerOpen
              }
            />
          </>
        )
      ) : (
        <OfferListItemSkeletonUpdated
          maxColumn={maxColumnOffersXs}
          firstIndex={FIRST_INDEX}
          skeletonNum={LIST_ITEM_SKELETON_NUM}
        />
      )}
    </div>
  ) : (
    <OfferList />
  )
}

export default RedeemUpdated
