import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'

import SuccessIco from '../../../../ui/customIcons/SuccessIco'
import { IdList } from '../../../../core/utils/id-list'

import './SuccessfulMsg.scss'

interface SuccessfulDialogProps {
  handleOkBtn: () => void
  label: string
}

const SuccessfulMsg: FC<SuccessfulDialogProps> = ({ handleOkBtn, label = '' }) => {
  const { t } = useTranslation()

  return (
    <div className='provider-success-dialog'>

      <div className='provider-success-dialog__ico'>
        <SuccessIco />
      </div>

      <div className='provider-success-dialog__msg'>{t('ProviderSuccessDialogMsg', { label })}</div>

      <Button
        id={IdList.providerSuccessDialog}
        onClick={() => handleOkBtn()}
        className='btn provider-success-dialog__btn'
      >
        {t`Ok`}
      </Button>
    </div>
  )
}

export default SuccessfulMsg
