import { axiosInstance } from '../core/api/init'

interface SignInResponse {
  message: string
  success: boolean
}

interface SignUpResponse {
  message: string
  success: boolean
  isInviteCodeInvalid: boolean
  promotionInfo: CampaignPromotional
}

export const isLoginKey = 'isAuth'

export const tokenService = {
  getToken: (): string => (localStorage.getItem(isLoginKey) ?? 'true'),
  setToken: (): void => {
    localStorage.setItem(isLoginKey, 'true')
  },
  clearToken: (): void => {
    localStorage.removeItem(isLoginKey)
  }
}

export const authService = {
  signIn: async (data: SignInForm): Promise<SignInResponse> => {
    return await axiosInstance.post('/auth/login', data)
      .then((response) => response.data)
      .catch(async (err) => await Promise.reject(err.response.data))
  },
  signOut: async (): Promise<any> => {
    return await axiosInstance.get('/auth/logout')
      .then((response) => response.data)
  },
  isAuthenticated: (): boolean => {
    return Boolean(tokenService.getToken())
  },
  passwordReset: async (
    data: PasswordResetForm,
    properties: TenantPropertiesParams
  ): Promise<any> => {
    return await axiosInstance
      .post('/auth/forgot-password', { data, properties })
      .then((response) => response)
  },
  editAccountInfo: async (
    data: EditAccountInfoParams
  ): Promise<any> => {
    return await axiosInstance
      .put('/auth/manage-account/info', { data })
      .then((response) => response)
  },
  editAccountPassword: async (
    data: EditPasswordParams
  ): Promise<any> => {
    return await axiosInstance
      .put('/auth/manage-account/password', { data })
      .then((response) => response)
  },
  signUp: async (data: SignUpForm): Promise<SignUpResponse> => {
    return await axiosInstance.post('/auth/sign-up', data)
      .then((response) => response.data)
      .catch(async (err) => await Promise.reject(err.response?.data))
  },
  validateInviteCode: async (
    data: InviteCodeValidationForm
  ): Promise<SignUpResponse> => {
    return await axiosInstance
      .post('/auth/validate-invite-code', data)
      .then((response) => response.data)
      .catch(async (err) => await Promise.reject(new Error(err.response?.data)))
  }
}
