import { RoutePath } from '../../core/routes/route-path'

export const actionPageList = [
  RoutePath.Home,
  RoutePath.Redemption,
  RoutePath.Tutorial,
  RoutePath.Invite,
  RoutePath.ConnectAccount,
  RoutePath.Refer,
  RoutePath.PointHistory,
  RoutePath.ManageAccount,
  RoutePath.EarnUpdated,
  RoutePath.RedeemUpdated
]
