import React, { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { useTracking } from 'react-tracking'
import { userService } from '../../user/service'
import { selectUser } from '../../user/selectors'
import {
  showNewFeatureDialog,
  getNewFeatureConfig,
  setHaveSeenNewFeature
} from '../../user/actions'
import {
  selectNewFeatureConfig,
  selectIsSignupWelcomeEnabled,
  selectIsWelcomeMessage
} from '../../config/selectors'
import { selectCurrentTutorialState } from '../../tutorial/selectors'
import { removeScreenConstraint } from '../../tutorial/TutorialToolTip'
import './NewFeatureMessage.scss'
import {
  Dialog,
  DialogContent,
  Button,
  MobileStepper,
  DialogTitle
} from '@material-ui/core'
import SwipeableViews from 'react-swipeable-views'
import _ from 'lodash'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'

const NewFeatureMessage: FC = () => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const HEIGHT_WITHOUT_IMAGE = matchesMd ? '35vh' : '30vh'
  const HEIGHT_WITH_IMAGE = matchesMd ? '55vh' : '50vh'
  const dispatch: Dispatch<any> = useDispatch()
  const { trackEvent } = useTracking()
  const { t } = useTranslation()
  const user: UserState = useSelector(selectUser)
  const newFeatureConfig: NewFeatureConfig[] = useSelector(
    selectNewFeatureConfig
  )
  const signupWelcomeEnabled = useSelector(selectIsSignupWelcomeEnabled)
  const isWelcome = useSelector(selectIsWelcomeMessage)
  const [isNewFeatureOpen, setIsNewFeatureOpen] = useState(true)
  const [isNewFeatureAvailable, setisNewFeatureAvailable] = useState(false)
  const currentTutorialState = useSelector(selectCurrentTutorialState)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [dialogHeight, setDialogHeight] = useState<string>(HEIGHT_WITHOUT_IMAGE)

  const lastStepIndex = newFeatureConfig.length - 1

  const onClose = async (): Promise<void> => {
    dispatch(setHaveSeenNewFeature(true))
    await userService.updateNewFeatureID()
    removeScreenConstraint()
    trackEvent({
      action: 'Feature Announcement',
      category: 'Feature Announcement',
      payload: {
        origin: 'FeatureAnnouncement'
      }
    })
    setIsNewFeatureOpen(false)
  }

  const onCTAClick = async (): Promise<void> => {
    await onClose()
    const lastStep = newFeatureConfig[lastStepIndex]
    const buttonLink = lastStep?.NewFeatureButtonLink
    if (!_.isEmpty(buttonLink)) {
      window.open(buttonLink)
    }
  }

  useEffect(() => {
    dispatch(showNewFeatureDialog()).then((data: any) => {
      if (data.payload === true) {
        dispatch(getNewFeatureConfig())
      }
      setisNewFeatureAvailable(data.payload)
    })
  }, [dispatch])

  useEffect(() => {
    const hasImage = !_.isEmpty(newFeatureConfig[activeStep]?.ImageUrl)
    setDialogHeight(hasImage ? HEIGHT_WITH_IMAGE : HEIGHT_WITHOUT_IMAGE)
  }, [activeStep, newFeatureConfig, HEIGHT_WITHOUT_IMAGE, HEIGHT_WITH_IMAGE])

  const handleStepChange = (step: number): void => {
    setActiveStep(step)
  }

  return (
    <>
      {newFeatureConfig.length > 0 &&
        isNewFeatureAvailable &&
        !isWelcome &&
        !signupWelcomeEnabled &&
        user.HaveSeenTutorial &&
        !currentTutorialState.tutorialOn &&
        (
          <Dialog
            open={isNewFeatureOpen}
            onClose={onClose}
            fullWidth
            maxWidth='sm'
            className='new-feature-dialog'
          >
            <DialogTitle className='dialog-title'>
              <CancelRoundedIcon className='cancel-icon' onClick={onClose} />
            </DialogTitle>
            <DialogContent
              className='new-feature-content'
              style={{ height: dialogHeight }}
            >
              <SwipeableViews
                axis='x'
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                className='step-swipe-box'
              >
                {newFeatureConfig.map((step, index) => (
                  <div key={index} className='step-info'>
                    {!_.isEmpty(step.ImageUrl) && (
                      <img
                        src={step.ImageUrl ?? ''}
                        alt='step'
                        className='step-img'
                      />
                    )}
                    <div
                      className={`step-detail ${
                        _.isEmpty(step.ImageUrl) ? 'p-top' : ''
                      }`}
                    >
                      {!_.isEmpty(step.NewFeatureLabel) && (
                        <div className='step-label'>{step.NewFeatureLabel}</div>
                      )}
                      <div className='step-title'>{step.NewFeatureTitle}</div>
                      <div className='step-description'>
                        <ReactMarkdown linkTarget='_blank'>
                          {step.NewFeatureDescription}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                ))}
              </SwipeableViews>
              {newFeatureConfig.length > 1 && (
                <MobileStepper
                  variant='dots'
                  steps={newFeatureConfig.length}
                  position='static'
                  activeStep={activeStep}
                  nextButton={<></>}
                  backButton={<></>}
                  className='pagination-dots'
                />
              )}
              {activeStep === lastStepIndex && (
                <Button
                  onClick={onCTAClick}
                  className={`btn cta ${
                    newFeatureConfig.length > 1 ? 'm-top' : ''
                  }`}
                >
                  {newFeatureConfig[lastStepIndex]?.NewFeatureButtonLabel ??
                    t`Ok`}
                </Button>
              )}
            </DialogContent>
          </Dialog>
        )}
    </>
  )
}

export default NewFeatureMessage
