import React, { FC } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectIsOffline } from '../../../config/selectors'
import { toggleShake } from '../../../config/actions'

import './LockedByConnectErr.scss'

const LockedByConnectErr: FC = ({ children }) => {
  const dispatch = useDispatch()
  const offline: boolean = useSelector(selectIsOffline)

  const handleShake = (): any => {
    dispatch(toggleShake(true))
    setTimeout(() => {
      dispatch(toggleShake(false))
    }, 1000)
  }

  return (
    <div onClick={handleShake}>
      {offline
        ? <div className='content-locked'>{children}</div>
        : <>{children}</>}
    </div>
  )
}

export default LockedByConnectErr
