export const initialState: ProviderAccountState = {
  providerList: [],
  providerStatusList: [],
  providerRedirectionURL: null
}

export const defaultAction: ProviderListAction = {
  type: '',
  payload: []
}
