import React, { FC } from 'react'

const ConfirmationIco: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <path d='M0 0L18 0 18 18 0 18z' transform='translate(-13 -898) translate(13 898)' />
            <path fill='black' fillRule='nonzero' d='M16.5 7.5v-3c0-.833-.675-1.5-1.5-1.5H3c-.825 0-1.492.667-1.492 1.5v3C2.333 7.5 3 8.175 3 9s-.667 1.5-1.5 1.5v3c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-3c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5zM15 6.405c-.893.518-1.5 1.492-1.5 2.595 0 1.102.607 2.078 1.5 2.595V13.5H3v-1.905c.893-.518 1.5-1.493 1.5-2.595 0-1.11-.6-2.077-1.493-2.595L3 4.5h12v1.905zM8.25 11.25h1.5v1.5h-1.5v-1.5zm0-3h1.5v1.5h-1.5v-1.5zm0-3h1.5v1.5h-1.5v-1.5z' transform='translate(-13 -898) translate(13 898)' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ConfirmationIco
