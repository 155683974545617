import React, { FC, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography
} from '@material-ui/core'
import { RoutePath } from '../../core/routes/route-path'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDispatch, useSelector } from 'react-redux'
import { selectConfig } from '../../config/selectors'
import './PasswordReset.scss'
import { useFormik } from 'formik'
import { passwordReset, resetPasswordResetError } from '../actions'
import { selectAuth } from '../selectors'
import InvalidEmailDialog from '../SignIn/InvalidCredentialsDialog'
import PasswordResetEmailSentDialog from './PasswordResetEmailSentDialog'
import { useMediaQuery } from '@react-hook/media-query'
import { regexEmail } from '../constants'
import patternImage from '../../../assets/images/pattern.png'
import lockImage from '../../../assets/images/password-reset-security.png'
import tipsImage from '../../../assets/images/password-reset-tips.png'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import track from 'react-tracking'
import { parseCssTheme } from '../../core/utils/useTheme'

export const emailNotFoundErrMsg =
  'This email address is not associated with a Rewards account'

const PasswordReset: FC = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch = useDispatch()
  const history = useHistory()
  const authState = useSelector(selectAuth)
  const config: ConfigState = useSelector(selectConfig)
  const initialValues: PasswordResetForm = { email: '' }
  const [isError, setIsError] = useState<boolean>(false)
  const [
    openPasswordResetEmailSentDialog,
    setOpenPasswordResetEmailSentDialog
  ] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const matchesMdw = useMediaQuery('(min-width: 1024px)')
  const matchesMdh = useMediaQuery('(min-height: 680px)')

  const customTheme = parseCssTheme(config.CssTheme)

  const tenantProperties = {
    tenantID: config.TenantID,
    customText: {
      securityAssurance: t`SecurityAssurance`,
      securityAssuranceListItem1: t`SecurityAssuranceListItem1`,
      securityAssuranceListItem2: t`SecurityAssuranceListItem2`,
      passwordCreationTips: t`PasswordCreationTips`,
      passwordCreationTipsListItem1: t`PasswordCreationTipsListItem1`,
      passwordCreationTipsListItem2: t`PasswordCreationTipsListItem2`
    }
  }

  useEffect(() => {
    if (authState.errorMessage === emailNotFoundErrMsg) {
      setIsError(true)
    } else if (
      authState.errorMessage != null &&
      authState.errorMessage !== ''
    ) {
      setError(authState.errorMessage)
      dispatch(resetPasswordResetError())
    }
  }, [authState.errorMessage, dispatch])

  const errorDisplay = <FormHelperText error>{error}</FormHelperText>

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData) => {
      if (formData != null) {
        dispatch(passwordReset(formData, tenantProperties)).then(
          (res: Response) => {
            if (res != null && res.status === 200) {
              setOpenPasswordResetEmailSentDialog(true)
            }
          }
        )
      }
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {}
      if (values.email == null) {
        errors.email = t`MissingEmailError`
      } else if (!regexEmail.test(values.email)) {
        errors.email = t`InvalidEmailError`
      }
      return errors
    },
    validateOnChange: false
  })

  const isDisabled = form.values.email.length < 3

  const handleInvalidEmailDialogClose = (): void => {
    setIsError(false)
    dispatch(resetPasswordResetError())
  }

  const handleInvalidEmailDialogCTA = (): void => {
    setIsError(false)
    dispatch(resetPasswordResetError())
    history.push(RoutePath.SignUp)
  }

  const handleEmailSentDialogClose = (): void => {
    setOpenPasswordResetEmailSentDialog(false)
    history.push(RoutePath.SignIn)
  }

  const handleEmailSentDialogCTA = (): void => {
    history.push(RoutePath.SignIn)
  }

  return (
    <Box className='password-reset-container'>
      <Box className='password-reset-left'>
        <Box className='form-field-box'>
          {matchesMdh && matchesMdw && (
            <Box className='tenant-logo'>
              <img src={config.TenantLogoURL ?? ''} alt='tenant-logo' />
            </Box>
          )}
          <Link to={RoutePath.SignIn} className='back-to-sign-in no-decor'>
            <ArrowBackIcon fontSize='small' className='arrow-responsive' />
            <Typography>{t`GoBackToSignIn`}</Typography>
          </Link>
          <Typography className='password-reset-heading'>{t`ResetPassword`}</Typography>
          <Typography className='password-reset-text'>{t`ResetPasswordText`}</Typography>
          <form onSubmit={form.handleSubmit} className='password-reset-form'>
            <Box mb={4}>
              <TextField
                id='email'
                name='email'
                variant='filled'
                className='form-control'
                value={form.values.email}
                onChange={form.handleChange}
                label={t`EnterYourEmail`}
                InputLabelProps={{ className: 'lato label' }}
              />
              {form.errors.email != null && (
                <FormHelperText error>{form.errors.email}</FormHelperText>
              )}
            </Box>
            <div className='responsive-btn'>
              <Button
                id='verify-email-btn'
                type='submit'
                className={`btn lato ${isDisabled ? 'disabled' : ''}`}
                disabled={isDisabled}
                style={{
                  backgroundColor: customTheme.button,
                  color: customTheme.buttonText
                }}
              >
                {t`VerifyEmail`}
              </Button>
            </div>
            {error !== '' && errorDisplay}
          </form>
        </Box>
      </Box>
      {matchesMdh && matchesMdw && (
        <Box
          className='password-reset-right'
          style={{
            backgroundColor: customTheme.rightPanel,
            backgroundImage: `url(${patternImage as string})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            backgroundPositionY: '100%'
          }}
        >
          <Box className='steps-box'>
            <Box className='step'>
              <Box className='step-header'>
                <img src={lockImage} alt='Image1' />
                <Typography style={{ color: customTheme.headerText }}>
                  {t`SecurityAssurance`}
                </Typography>
              </Box>
              <Typography
                className='description'
                style={{ color: customTheme.headerText }}
              >
                <ul>
                  <li>{t`SecurityAssuranceListItem1`}</li>
                  <li>{t`SecurityAssuranceListItem2`}</li>
                </ul>
              </Typography>
            </Box>
            <Box className='step' style={{ marginBottom: 0 }}>
              <Box className='step-header'>
                <img src={tipsImage} alt='Image2' />
                <Typography style={{ color: customTheme.headerText }}>
                  {t`PasswordCreationTips`}
                </Typography>
              </Box>
              <Typography
                className='description'
                style={{ color: customTheme.headerText }}
              >
                <ul>
                  <li>{t`PasswordCreationTipsListItem1`}</li>
                  <li>{t`PasswordCreationTipsListItem2`}</li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {isError && (
        <InvalidEmailDialog
          open={isError}
          handleClose={handleInvalidEmailDialogClose}
          handleSignUpClick={handleInvalidEmailDialogCTA}
        />
      )}
      {openPasswordResetEmailSentDialog && (
        <PasswordResetEmailSentDialog
          open={openPasswordResetEmailSentDialog}
          handleClose={handleEmailSentDialogClose}
          handleClick={handleEmailSentDialogCTA}
        />
      )}
    </Box>
  )
}

export default track({
  page: AnalyticsCategory.ResetPassword
})(PasswordReset)
