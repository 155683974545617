import { axiosInstance } from '../core/api/init'
import { languageService } from '../language/service'

export const userService = {
  getUserInfo: async (): Promise<UserInfo> => {
    return await axiosInstance.get(`/rest/users/me/userinfo/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },
  getEarningList: async (): Promise<EarningList> => {
    return await axiosInstance.get(`rest/users/me/earnings/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },
  agreeTerms: async (): Promise<Consent> => {
    return await axiosInstance.post('/rest/users/me/consent')
      .then((response) => response.data)
  },
  getOfferStatus: async (): Promise<UserOfferStatus[]> => {
    return await axiosInstance.get('rest/users/me/offersStatus')
      .then((response) => response.data)
  },
  getTransactionList: async (customUrl?: string, type?: string): Promise<TransactionList> => {
    let url = `/rest/users/me/transactions/${languageService.getLanguage()}`

    if (customUrl !== undefined && customUrl !== '') {
      url = customUrl
    }

    return await axiosInstance.get(url, { params: { type } })
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  validatePhoneNumber: async (phoneNumber: string, isMigrationRequest: boolean): Promise<VerificationCode> => {
    return await axiosInstance.post('/rest/users/me/validation', { phoneNumber, isMigrationRequest })
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  validateCode: async (validationCode: string, isMigrationRequest: boolean): Promise<VerificationCode> => {
    return await axiosInstance.put('/rest/users/me/validation', { validationCode, isMigrationRequest })
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  ackEarning: async (EarningID: string): Promise<AckEarning> => {
    return await axiosInstance.post(`/rest/earnings/${EarningID}/ack`)
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  ackRedemption: async (CouponID: string): Promise<AckRedemption> => {
    return await axiosInstance.post(`/rest/redemptions/${CouponID}/ack`)
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  optBackIn: async (): Promise<OptedIn> => {
    return await axiosInstance.post('/rest/users/me/optBackIn')
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  setTutorialSeen: async (): Promise<UserInfo> => {
    return await axiosInstance.post('/rest/users/me/tutorialSeen')
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  getPointsFromReferee: async (): Promise<number> => {
    return await axiosInstance.post('/rest/users/me/referrerPoints')
      .then((response) => response.data)
  },
  updateNewFeatureID: async (): Promise<UserInfo> => {
    return await axiosInstance.post('/rest/users/me/updateNewFeatureID')
      .then((response) => response.data)
      .catch(async error => await Promise.reject(error.response))
  },
  ShowNewFeatureDialog: async (): Promise<boolean> => {
    return await axiosInstance.post('/rest/users/me/showNewFeatureDialog')
      .then((response) => response.data)
  },
  updateShowReactivationBonusDialog: async (): Promise<boolean> => {
    return await axiosInstance.post('/rest/users/me/showReactivationBonusDialog')
      .then((response) => response.data)
  },
  getUserReactivationStatus: async (): Promise<UserReactivationStatusResponse> => {
    return await axiosInstance.post('/rest/users/me/getUserReactivationStatus')
      .then((response) => response.data)
  },
  getNewFeatureConfig: async (): Promise<NewFeatureConfig[]> => {
    return await axiosInstance.post(`/rest/users/me/getNewFeatureConfig/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },
  updatePreferredLanguage: async (LanguageCode: string): Promise<string> => {
    return await axiosInstance.post(`/rest/users/me/updatePreferredLanguage/${LanguageCode}`)
      .then((response) => response.data)
      .catch(async (error) => await Promise.reject(error.response))
  },
  getFavoriteOfferCount: async (): Promise<number> => {
    return await axiosInstance.get('/rest/users/me/getFavoriteOfferCount')
      .then((response) => response.data)
  }
}
