export const GET_CAMPAIGN = 'GET_CAMPAIGN'
export const GET_CAMPAIGN_HIDDEN_LIST = 'GET_CAMPAIGN_HIDDEN_LIST'
export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST'
export const CLEAR_CAMPAIGN_LIST = 'CLEAR_CAMPAIGN_LIST'

export const GET_CAMPAIGN_FEATURED_LIST = 'GET_CAMPAIGN_FEATURED_LIST'
export const GET_CAMPAIGN_CATEGORIES = 'GET_CAMPAIGN_CATEGORIES'
export const GET_CAMPAIGN_STATUSES = 'GET_CAMPAIGN_STATUSES'
export const PARTICIPATE_PROMOTION = 'PARTICIPATE_PROMOTION'
export const GET_REFERRAL = 'GET_REFERRAL'
