import React, { createElement, FC } from 'react'
import * as Icons from '@material-ui/icons'

type IconType = typeof import('@material-ui/icons/index')

interface DynamicIconProps {
  iconName: string
  className: string
}

const DynamicIcon: FC<DynamicIconProps> = ({ iconName, className }) => {
  const upperFirst = (string: string): string => {
    return string.slice(0, 1).toUpperCase() + string.slice(1, string.length)
  }

  const fixIconNames = (string: string): string => {
    const name = string != null ? string.split('-').map(upperFirst).join('') : ''
    if (name === '3dRotation') {
      return 'ThreeDRotation'
    } else if (name === '4k') {
      return 'FourK'
    } else if (name === '360') {
      return 'ThreeSixty'
    }
    return name
  }

  const MuiIcon = Icons[fixIconNames(iconName) as keyof IconType]

  return MuiIcon !== undefined ? createElement(MuiIcon, { className: className }) : <></>
}

export default DynamicIcon
