export const initialState: OfferState = {
  offerList: [],
  offerHiddenList: [],
  offerCategories: []
}

export const defaultAction: OfferListAction = {
  type: '',
  payload: []
}
