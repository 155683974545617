export const CampaignTypes = {
  TRANSACTIONAL: 'TRANSACTIONAL',
  CONNECT: 'CONNECT',
  VELOCIA_SIGNUP: 'VELOCIA_SIGNUP',
  ACTION: 'ACTION',
  SCHEDULED: 'SCHEDULED',
  PROMOTION: 'PROMOTION',
  EXTERNAL_SURVEY: 'EXTERNAL_SURVEY',
  LOCATION: 'LOCATION',
  REFERRAL: 'REFERRAL',
  STREAK: 'STREAK',
  MULTI_TRIP: 'MULTI_TRIP',
  REDEMPTION: 'REDEMPTION',
  TIER: 'TIER'
}

export const TITLE_CHAR_LIMIT = 34
export const SUBHEADER_CHAR_LIMIT = 25

export const FEATURED_CAMPAIGN_TITLE_CHAR_LIMIT_DESKTOP = 60
export const FEATURED_CAMPAIGN_SUBHEADER1_CHAR_LIMIT_DESKTOP = 38
export const FEATURED_CAMPAIGN_SUBHEADER2_CHAR_LIMIT_DESKTOP = 88

export const CAMPAIGN_TITLE_CHAR_LIMIT_DESKTOP = 44
export const CAMPAIGN_SUBHEADER1_CHAR_LIMIT_DESKTOP = 28
export const CAMPAIGN_SUBHEADER2_CHAR_LIMIT_DESKTOP = 64

export const FEATURED_CAMPAIGNS_LIST_LIMIT = 3

export const MAX_BADGE_LIMIT = 5
export const BEHAVIOR = 'BEHAVIOR'

export const CampaignCtaActions = {
  OPEN: 'open',
  REDIRECT: 'redirect'
}
