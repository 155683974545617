import { axiosInstance } from '../core/api/init'
import { languageService } from '../language/service'

export const configService = {
  getConfigInfo: async (): Promise<ConfigInfo> => {
    return await axiosInstance
      .get('/rest/configuration')
      .then((response) => response.data)
  },
  getConfigInfoByTenant: async (TenantID: string): Promise<ConfigInfo> => {
    return await axiosInstance
      .post(`/rest/configuration/${languageService.getLanguage()}`, {
        TenantID
      })
      .then((response) => response.data)
  }
}
