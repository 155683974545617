import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

export const selectProviderList = (state: ApplicationState): ProviderAccount[] => sortBy(state.provider.providerList, 'UiPosition')

export const selectProviderStatusList = (state: ApplicationState): ProviderStatus[] => state.provider.providerStatusList

export const selectProviderRedirectURL = (state: ApplicationState): ProviderRedirectURL | null => state.provider.providerRedirectionURL

export const selectProviderStatus: any = (ProviderID: string) => {
  return createSelector(
    [selectProviderStatusList],
    (providerStatusList: ProviderStatus[]): ProviderStatus | undefined => {
      return providerStatusList.find((providerStatus) => providerStatus.ProviderID === ProviderID)
    }
  )
}
