import React, { FC, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, NavLink, useLocation } from 'react-router-dom'
import track from 'react-tracking'
import { useMediaQuery } from '@react-hook/media-query'
import { includes } from 'lodash'
import { Skeleton } from '@material-ui/lab'
import { AppBar, Toolbar, Box, MenuItem, TextField } from '@material-ui/core'
import './Header.scss'
import { selectConfig, selectIsActionLoaded } from '../../config/selectors'
import { selectAuth } from '../../auth/selectors'
import { signOut } from '../../auth/actions'
import { Navigation } from './Navigation/Navigation'
import { NavigationUpdated } from './Navigation/NavigationUpdated'
import NavigationTabs from './NavigationTabs/NavigationTabs'
import NavigationDrawer from './NavigationDrawer/NavigationDrawer'
import BalanceDesktop from '../../user/BalanceDesktop/BalanceDesktop'
import { RoutePath } from '../../core/routes/route-path'
import { IdList } from '../../core/utils/id-list'
import { actionPageList } from './actionPageList'
import { breakpoints } from '../../core/utils/css-selectors'
import TransactionHistoryList from '../../user/TransactionHistoryList/TransactionHistoryList'
import { GET_TRANSACTION_LIST, GET_USER_INFO } from '../../user/actionTypes'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import BalanceMobile from '../../user/BalanceMobile/BalanceMobile'
import { selectCurrentTutorialState } from '../../tutorial/selectors'
import { setCurrentStepTitle } from '../../tutorial/actions'

import { TUTORIAL_STEPS } from '../../tutorial/TutorialToolTip'
import { selectTransactionList, selectUser } from '../../user/selectors'
import MultiLanguage from '../../language/MultiLanguage/MultiLanguage'
import { useTranslation } from 'react-i18next'
import ManageAccountDrawer from '../../auth/ManageAccount/ManageAccountDrawer'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { selectCampaignReferral } from '../../campaign/selectors'
import MultiLanguageDropDown from '../../language/MultiLanguage/MultiLanguageDropDown'
import { TUTORIAL_STEPS_UPDATED } from '../../tutorial/TutorialToolTipUpdated'
import { selectProviderList } from '../../provider/selectors'
import { UserStatus } from '../../user/userStatus'

const SIGN_OUT = 'signOut'

const Header: FC = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch<UserAction> = useDispatch()
  const history = useHistory()
  const config: ConfigState = useSelector(selectConfig)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const location = useLocation()
  const auth: AuthState = useSelector(selectAuth)
  const transactionList: TransactionList = useSelector(selectTransactionList)
  const isTransactionLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_TRANSACTION_LIST)
  )
  const isUserLoaded: boolean = useSelector(selectIsActionLoaded(GET_USER_INFO))
  const user: UserState = useSelector(selectUser)

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [isAction, setIsAction] = useState<boolean>(true)
  const [redirectionProcessed, setRedirectionProcessed] =
    useState<boolean>(false)
  const [isOpenTransactionHistory, setIsOpenTransactionHistory] =
    useState<boolean>(false)
  const [isOpenLanguageSelection, setIsOpenLanguageSelection] =
    useState<boolean>(false)
  const [isOpenMyAccount, setIsOpenMyAccount] = useState<boolean>(false)
  const currentTutorialState = useSelector(selectCurrentTutorialState)
  const referralInfo = useSelector(selectCampaignReferral)
  const providerList: ProviderAccount[] = useSelector(selectProviderList)
  const [prevWidth, setPrevWidth] = useState<boolean>(matchesMd)

  const onSignOut = async (): Promise<any> => {
    dispatch(signOut())
    history.push(RoutePath.SignIn)
  }
  const tutorialOpenMenu = TUTORIAL_STEPS()
  const tutorialMenuUpdated = TUTORIAL_STEPS_UPDATED()
  const onMenuActionChange = async (event: any): Promise<void> => {
    const action = event.target.value
    action === SIGN_OUT
      ? await onSignOut()
      : history.push(RoutePath[action as keyof typeof RoutePath])
  }
  const getNewNavMenuItems = (): JSX.Element[] => {
    const items = []
    items.push(
      <MenuItem
        key='inviteKey'
        value='Invite'
        className={`menu-item__desktop ${getSelectedMenu('Invite')}`}
      >
        {t`InviteCode`}
      </MenuItem>
    )
    if (providerList.length > 0) {
      items.push(
        <MenuItem
          key='connectAccountKey'
          value='ConnectAccount'
          className={`menu-item__desktop ${getSelectedMenu('ConnectAccount')}`}
        >
          {t`ConnectAccount`}
        </MenuItem>
      )
    }
    if (
      config.IsReferralAvailable &&
      referralInfo != null &&
      referralInfo.ReferralConfiguration != null
    ) {
      items.push(
        <MenuItem
          key='referKey'
          value='Refer'
          className={`menu-item__desktop ${getSelectedMenu('Refer')}`}
        >
          {t`ReferFriend`}
        </MenuItem>
      )
    }
    return items
  }
  const getSelectedMenu = (menu: string): string => {
    const selectedMenu = Object.keys(RoutePath).find((key: string) => {
      return RoutePath[key as keyof typeof RoutePath] === location.pathname
    })
    return selectedMenu === menu ? 'selected-menu' : ''
  }

  if (isOpenMyAccount && matchesMd) {
    setIsOpenMyAccount(false)
    setPrevWidth(true)
    history.push(RoutePath.ManageAccount)
  }

  if (
    prevWidth &&
    !matchesMd &&
    !isOpenMyAccount &&
    location.pathname === RoutePath.ManageAccount
  ) {
    history.replace(RoutePath.Home)
    setIsOpenMyAccount(true)
    setPrevWidth(false)
  }

  useEffect(() => {
    setIsAction(includes(actionPageList, location.pathname))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (currentTutorialState.tutorialOn) {
      if (
        currentTutorialState.currentStepTitle ===
          tutorialOpenMenu.TUTORIAL_STEP_TITLES.MORE_TAB ||
        currentTutorialState.currentStepTitle ===
          tutorialMenuUpdated.TUTORIAL_STEP_TITLES.MORE_TAB
      ) {
        setMenuOpen(true)
      }
      if (
        currentTutorialState.currentStepTitle ===
          tutorialOpenMenu.TUTORIAL_STEP_TITLES.REDEEM_POINT ||
        currentTutorialState.currentStepTitle ===
          tutorialMenuUpdated.TUTORIAL_STEP_TITLES.REDEEM_TAB
      ) {
        setMenuOpen(false)
      }
    } else {
      if (
        currentTutorialState.currentStepTitle ===
          tutorialOpenMenu.TUTORIAL_STEP_TITLES.MORE_TAB ||
        currentTutorialState.currentStepTitle ===
          tutorialMenuUpdated.TUTORIAL_STEP_TITLES.MORE_TAB
      ) {
        setMenuOpen(false)
        dispatch(setCurrentStepTitle(''))
      }
    }
  }, [
    location,
    currentTutorialState,
    tutorialOpenMenu.TUTORIAL_STEP_TITLES,
    menuOpen,
    dispatch,
    tutorialMenuUpdated.TUTORIAL_STEP_TITLES
  ])

  useEffect(() => {
    if (location.pathname === RoutePath.PointHistory) {
      if (isTransactionLoaded && transactionList.data.length > 0) {
        if (!redirectionProcessed) {
          setIsOpenTransactionHistory(true)
          setRedirectionProcessed(true)
        }
      }
    }
  }, [location, transactionList, isTransactionLoaded, redirectionProcessed])

  return (
    <>
      {matchesMd &&
        (isUserLoaded ? (
          <AppBar
            position='static'
            className={`header ${
              user.featureFlags.UX_UPDATES ? 'header__new' : ''
            }`}
          >
            <Toolbar className='header-toolbar'>
              <div className='flex height-full'>
                <NavLink
                  id={IdList.navLogo}
                  exact
                  to={
                    user.userStatus === UserStatus.Suspended
                      ? RoutePath.Suspended
                      : user.userStatus === UserStatus.OptedOut
                        ? RoutePath.OptedOut
                        : RoutePath.Home
                  }
                  className='flex align-center'
                >
                  <div
                    style={{
                      backgroundImage: `url(${config.TenantHeaderLogoURL})`
                    }}
                    className='header-toolbar__logo'
                  />
                </NavLink>
                {user.featureFlags.UX_UPDATES
                  ? matchesMd && isAction && <NavigationUpdated />
                  : matchesMd && isAction && <Navigation />}
              </div>

              {isAction && (
                <div className='header-actions'>
                  <BalanceDesktop isFromUpdatedUI />
                  {user.featureFlags.UX_UPDATES && config.SupportLanguage && (
                    <MultiLanguageDropDown />
                  )}
                  {auth.isAuth && config.IsStandalonModeSupported && (
                    <Box className='manage-account-action'>
                      <Box className='user-name'>
                        <Box className='user-name'>
                          <p>
                            {`${
                              user.featureFlags.UX_UPDATES ? '' : t`Hi` + ','
                            }${
                              user.name != null ? user.name.split(' ')[0] : ''
                            }`}
                          </p>
                        </Box>
                      </Box>
                      <TextField
                        className='manage-account-select'
                        select
                        id='user-account-action'
                        value=''
                        onChange={onMenuActionChange}
                        SelectProps={{
                          IconComponent: () => (
                            <KeyboardArrowDownIcon style={{ color: 'white' }} />
                          ),
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'center'
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                              className: 'dropdown'
                            }
                          }
                        }}
                      >
                        <MenuItem
                          key='myAccountKey'
                          value='ManageAccount'
                          className={`menu-item__desktop ${getSelectedMenu(
                            'ManageAccount'
                          )}`}
                        >
                          {t`MyAccount`}
                        </MenuItem>
                        {user.featureFlags.UX_UPDATES && getNewNavMenuItems()}
                        <MenuItem
                          key='signOutKey'
                          value='signOut'
                          className='sign-out menu-item__desktop'
                        >
                          {t`SignOut`}
                        </MenuItem>
                      </TextField>
                    </Box>
                  )}
                </div>
              )}
            </Toolbar>
          </AppBar>
        ) : (
          <Skeleton
            variant='rect'
            classes={{ root: 'header-toolbar--loading__nav-tab' }}
          />
        ))}

      {!matchesMd &&
        isAction &&
        (isUserLoaded ? (
          <>
            <div className='header--mobile'>
              <BalanceMobile />
              <NavigationTabs onMore={() => setMenuOpen(true)} />
            </div>
            <NavigationDrawer
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              setHistoryOpen={setIsOpenTransactionHistory}
              setLanguageOpen={setIsOpenLanguageSelection}
              setMyAccountOpen={setIsOpenMyAccount}
            />
            <TransactionHistoryList
              isOpenTransactionHistory={isOpenTransactionHistory}
              setIsOpenTransactionHistory={setIsOpenTransactionHistory}
            />
            <MultiLanguage
              isOpenLanguageSelection={isOpenLanguageSelection}
              setIsOpenLanguageSelection={setIsOpenLanguageSelection}
            />
            <ManageAccountDrawer
              isOpenMyAccount={isOpenMyAccount}
              setIsOpenMyAccount={setIsOpenMyAccount}
            />
          </>
        ) : (
          <Skeleton
            variant='rect'
            classes={{ root: 'header-toolbar--loading__nav-tab' }}
          />
        ))}
    </>
  )
}

export default track({
  page: AnalyticsCategory.Menu
})(Header)
