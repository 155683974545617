import { axiosInstance } from '../core/api/init'
import { languageService } from '../language/service'

export const updateCardService = {
  getUpdateCardList: async (): Promise<UpdateCard[]> => {
    return await axiosInstance
      .get(`/rest/update-cards/${languageService.getLanguage()}`)
      .then((response) => response.data)
  }
}
