import { Dispatch } from 'redux'

import * as actionTypes from './actionTypes'
import { updateCardService } from './service'
import { setLoaded } from '../config/actions'

export const getUpdateCardList: any = () => async (dispatch: Dispatch<UpdateCardListAction | LoadedAction>): Promise<UpdateCardListAction> => {
  return await updateCardService.getUpdateCardList()
    .then((data) => dispatch(setUpdateCardList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_UPDATE_CARD_LIST)))
}

export const clearUpdateCardList: any = () => async (dispatch: Dispatch<UpdateCardListAction>): Promise<UpdateCardListAction> => {
  return await Promise.resolve(dispatch(setClearUpdateCardList()))
}

const setUpdateCardList = (payload: UpdateCard[]): UpdateCardListAction => ({
  type: actionTypes.GET_UPDATE_CARD_LIST,
  payload
})

const setClearUpdateCardList = (): UpdateCardListAction => ({
  type: actionTypes.CLEAR_UPDATE_CARD_LIST,
  payload: []
})
