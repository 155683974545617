import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ReferHeader from '../../ui/Refer/ReferHeader/ReferHeader'
import ReferForm from '../../ui/Refer/ReferForm/ReferForm'
import ReferStatics from '../../ui/Refer/ReferStatics/ReferStatics'
import { getReferralInfo } from '../actions'
import { selectCampaignReferral } from '../selectors'
import './ReferFriend.scss'
import ReferHowTo from '../../ui/Refer/ReferHowTo/ReferHowTo'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'

const { REACT_APP_TENANT_ID_FOR_CLIENT } = process.env

const ReferFriend: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const referralInfo = useSelector(selectCampaignReferral)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  let referralConfiguration: { [key: string]: any } | null = null
  let referralInstructions: any = []
  let referrerRecord = {}
  let referralStatistics = {}
  let requireAction = false
  useEffect((): void => {
    dispatch(getReferralInfo(REACT_APP_TENANT_ID_FOR_CLIENT))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (referralInfo != null) {
    try {
      referralConfiguration = referralInfo.ReferralConfiguration
      referralInstructions = referralInfo.ReferralInstructions
      referrerRecord = referralInfo.ReferrerRecord
      referralStatistics = referralInfo.ReferralStatistics
      requireAction =
        referralInfo.ReferralConfiguration?.IsActionRequire || false
    } catch (error) {
      toast.error(t`SomethingWrong`)
      Sentry.captureException(error)
    }
  }

  return (
    <div className='refer-friend'>
      <ReferHeader
        referralConfiguration={referralConfiguration}
        referralInstructions={referralInstructions}
      />
      <ReferForm
        btnTxt={t`CopyCode`}
        referralConfiguration={referralConfiguration}
        referrerRecord={referrerRecord}
      />
      <ReferStatics
        referralStatistics={referralStatistics}
        requireAction={requireAction}
      />
      {matchesMd && <ReferHowTo referralInstructions={referralInstructions} />}
    </div>
  )
}

export default ReferFriend
