import { authService } from './service'

export const initialState: AuthState = {
  isAuth: authService.isAuthenticated(),
  isHomePath: false
}

export const defaultAction: AuthAction = {
  type: '',
  payload: {
    isAuth: false
  }
}
