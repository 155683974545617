import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import './SoldOut.scss'

const SoldOut: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className='sold-out__bg' />
      <div className='sold-out__label'>
        <span>{t`soldOut`}</span>
      </div>
    </>
  )
}

export default SoldOut
