import { Dispatch } from 'redux'

import * as actionTypes from './actionTypes'
import { providerService } from './service'
import { ProviderStatusType } from './providerStatusType'
import { setLoaded } from '../config/actions'

export const getProviderList: any = () => async (dispatch: Dispatch<ProviderListAction | LoadedAction>): Promise<ProviderListAction> => {
  return await providerService.getProviderList()
    .then((data) => dispatch(setProviderList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_PROVIDER_LIST)))
}

export const clearProviderList: any = () => async (dispatch: Dispatch<ProviderListAction>): Promise<ProviderListAction> => {
  return dispatch(setClearOfferList())
}

export const getProviderStatusList: any = () => async (dispatch: Dispatch<ProviderStatusListAction | LoadedAction>): Promise<ProviderStatusListAction> => {
  return await providerService.getProviderStatusList()
    .then((data) => dispatch(setProviderStatusList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_PROVIDER_STATUS_LIST)))
}

export const clearProviderStatusList: any = () => async (dispatch: Dispatch<ProviderStatusListAction>): Promise<ProviderStatusListAction> => {
  return dispatch(setClearProviderStatusList())
}

export const getRedirectURLForOAUTH: any = (providerID: string) => async (dispatch: Dispatch<RedirectURLAction | LoadedAction>): Promise<RedirectURLAction> => {
  return await providerService.getRedirectURLForOAUTH(providerID)
    .then((res) => dispatch(setRedirectURLForOAUTH(res)))
    .finally(() => dispatch(setLoaded(actionTypes.SET_REDIRECTION_URL)))
}

export const setProviderStatus: any = (providerID: string, identifier: string) => async (dispatch: Dispatch<ProviderStatusAction | LoadedAction>): Promise<ProviderStatusAction> => {
  return await providerService.setProviderStatus(providerID, identifier)
    .then((data) => dispatch(setSetProviderStatus(data)))
    .finally(() => dispatch(setLoaded(actionTypes.SET_PROVIDER_STATUS)))
}

export const disconnectProviderStatus: any = (providerID: string) => async (dispatch: Dispatch<ProviderDisconnectAction | LoadedAction>): Promise<ProviderDisconnectAction> => {
  return await providerService.disconnectProviderStatus(providerID)
    .then(() => dispatch(setDisconnectProviderStatus(providerID)))
    .finally(() => dispatch(setLoaded(actionTypes.DISCONNECT_PROVIDER_STATUS)))
}

const setProviderList = (payload: ProviderAccount[]): ProviderListAction => ({
  type: actionTypes.GET_PROVIDER_LIST,
  payload
})

const setClearOfferList = (): ProviderListAction => ({
  type: actionTypes.CLEAR_PROVIDER_LIST,
  payload: []
})

const setProviderStatusList = (payload: ProviderStatus[]): ProviderStatusListAction => ({
  type: actionTypes.GET_PROVIDER_STATUS_LIST,
  payload
})
const setClearProviderStatusList = (): ProviderStatusListAction => ({
  type: actionTypes.CLEAR_PROVIDER_STATUS_LIST,
  payload: []
})

const setSetProviderStatus = (payload: ProviderStatus): ProviderStatusAction => ({
  type: actionTypes.SET_PROVIDER_STATUS,
  payload
})

const setDisconnectProviderStatus = (ProviderID: string): ProviderDisconnectAction => ({
  type: actionTypes.DISCONNECT_PROVIDER_STATUS,
  payload: {
    ProviderID,
    status: ProviderStatusType.Disconnected
  }
})

const setRedirectURLForOAUTH = (payload: ProviderRedirectURL): RedirectURLAction => ({
  type: actionTypes.SET_REDIRECTION_URL,
  payload
})
