export enum TransactionType {
  Earn = 'EARN',
  Spend = 'SPEND',
  BalanceDeduction = 'BALANCE_DEDUCTION',
  Refund = 'REFUND',
  SuspensionDeduction = 'SUSPENSION_DEDUCTION',
  ExpiryDeduction = 'EXPIRY_DEDUCTION'
}

export enum TransactionTypeOptions {
  ALL_TRANSACTIONS = 'ALL_TRANSACTIONS',
  EARN = 'EARN',
  SPEND = 'SPEND',
  BALANCE_DEDUCTION = 'BALANCE_DEDUCTION',
  REFUND = 'REFUND',
  SUSPENSION_DEDUCTION = 'SUSPENSION_DEDUCTION',
  EXPIRY_DEDUCTION = 'EXPIRY_DEDUCTION'
}
