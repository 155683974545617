import React, { FC } from 'react'
import { TextField } from '@material-ui/core'
interface CustomPhoneInputProps {
  customInputProps?: {
    errorMessage: string
    classForm: string
  }
}
const CustomPhoneInput: FC<CustomPhoneInputProps> = (inputProps) => {
  const props = { ...inputProps }
  const customProps = { ...props.customInputProps }

  delete props.customInputProps

  const getClassForm = (): string => (
    customProps.classForm ?? ''
  )
  const getErrorLabelClass = (): string => (
    customProps?.classForm != null ? `${customProps.classForm}__error-label` : ''
  )

  return (
    <TextField
      variant='outlined'
      className={customProps.errorMessage === ''
        ? `${getClassForm()} form-control`
        : `${getClassForm()} form-control-error`}
      error={customProps?.errorMessage !== ''}
      helperText={customProps.errorMessage ?? ''}
      FormHelperTextProps={{ className: getErrorLabelClass() }}
      {...props}
    />
  )
}

export default CustomPhoneInput
