import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { Button } from '@material-ui/core'
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined'
import { useTracking } from 'react-tracking'
import { IdList } from '../../../../core/utils/id-list'
import placeholderOffer from '../../../../../assets/images/offer/placeholder-offer.png'

import './RedemptionWithTicket.scss'
import { RedemptionType } from '../../../redemptionType'
import { AnalyticsCategory } from '../../../../core/analytics/analyticsCategory'

interface RedemptionDrawerWithTicketProps {
  handleCloseRedemption: () => void
  offerItem: Offer | undefined
  redemption: Redemption | undefined
  isFromUpdatedUI?: boolean
  isFromUpdatedDesktopUI?: boolean
}

const RedemptionWithTicket: FC<RedemptionDrawerWithTicketProps> = ({
  handleCloseRedemption,
  offerItem,
  redemption,
  isFromUpdatedUI = false,
  isFromUpdatedDesktopUI = false
}) => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const redemptionClassName = isFromUpdatedUI
    ? 'redemption-drawer-new-ticket'
    : 'redemption-drawer-ticket'
  const handleCheckOut = async (event: any = null): Promise<void> => {
    if (event != null) {
      event.preventDefault()
    }
    trackEvent({
      action: 'View Ticket Pressed',
      page: AnalyticsCategory.Redemption,
      payload: {
        offerID: redemption?.OfferID
      }
    })
    handleCloseRedemption()
    window.open(redemption?.couponUrl)
  }

  const handleOkay = async (event: any = null): Promise<void> => {
    if (event != null) {
      event.preventDefault()
    }
    handleCloseRedemption()
  }

  const getImg = (): string => {
    if (offerItem?.headerImageUrl != null) {
      return offerItem.headerImageUrl
    }
    return placeholderOffer
  }

  const ticketButton = (redemption: Redemption | undefined): ReactElement => {
    return (
      <div
        className={`${redemptionClassName}__btn ${
          isFromUpdatedDesktopUI ? 'btn-updated w-90' : ''
        }`}
      >
        {redemption !== undefined &&
          (redemption.couponUrl != null ? (
            <Button
              id={IdList.viewTicket}
              onClick={handleCheckOut}
              onTouchEnd={handleCheckOut}
              className='btn btn__small m-top-24'
            >
              {t`ViewTicket`}
            </Button>
          ) : (
            <Button
              id={IdList.ticketClose}
              onClick={handleOkay}
              onTouchEnd={handleOkay}
              className='btn btn__small m-top-24'
            >
              {redemption.CouponType === RedemptionType.creditPass
                ? t`Close`
                : t`Ok`}
            </Button>
          ))}
      </div>
    )
  }
  const getOfferImg = (): ReactElement => {
    return (
      <div
        style={{ backgroundImage: `url(${getImg()})` }}
        className={`redemption-drawer-new-ticket-img ${
          isFromUpdatedDesktopUI ? 'img-updated' : ''
        }`}
      />
    )
  }
  return (
    <div
      className={`${redemptionClassName} ${
        isFromUpdatedDesktopUI ? 'ticket-drawer-updated' : ''
      }`}
    >
      {(isFromUpdatedUI || isFromUpdatedDesktopUI) && getOfferImg()}

      <div
        className={`${redemptionClassName}-details ${
          isFromUpdatedDesktopUI ? 'details-updated padding-updated' : ''
        }`}
      >
        <div>
          <ConfirmationNumberOutlinedIcon
            className={`${redemptionClassName}__icon`}
          />
        </div>
        <div className={`${redemptionClassName}__title`}>
          {offerItem?.Title}
        </div>
        {offerItem != null && (
          <div className={`${redemptionClassName}__instruction`}>
            <ReactMarkdown linkTarget='_blank'>
              {offerItem.CouponInstructions}
            </ReactMarkdown>
          </div>
        )}
      </div>
      {ticketButton(redemption)}
    </div>
  )
}

export default RedemptionWithTicket
