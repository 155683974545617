import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { Button } from '@material-ui/core'
import { selectConfig, selectWelcomeScreenConfig } from '../../config/selectors'
import InviteHeader from '../../ui/Invite/InviteHeader/InviteHeader'
import { RoutePath } from '../../core/routes/route-path'
import * as Sentry from '@sentry/react'
import { selectUser } from '../selectors'
import { IdList } from '../../core/utils/id-list'
import { getOfferList } from '../../offer/actions'
import {
  getUserInfo,
  getUserOfferStatus,
  getEarningList,
  optBackIn
} from '../actions'
import {
  getCampaignList,
  getCampaignFeaturedList,
  getCampaignStatuses
} from '../../campaign/actions'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import LoadingDialog from '../../ui/LoadingDialog/LoadingDialog'

import './OptedOut.scss'

const OptedOut: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const config = useSelector(selectConfig)
  const user: UserState = useSelector(selectUser)
  const [isSpinner, setIsSpinner] = useState(false)
  const welcomeScreenConfig: WelcomeScreenConfig = useSelector(
    selectWelcomeScreenConfig
  )

  const handleOptBackIn = async (): Promise<void> => {
    trackEvent({
      page: 'User Account',
      action: 'Opt Back In',
      payload: {
        userID: user.UserID
      }
    })
    setIsSpinner(true)
    try {
      await dispatch(optBackIn())
      setIsSpinner(false)
      history.replace(RoutePath.Home)

      await Promise.all([
        dispatch(getUserInfo()),
        dispatch(getEarningList()),
        dispatch(getCampaignList()),
        dispatch(getCampaignFeaturedList()),
        dispatch(getCampaignStatuses()),
        dispatch(getOfferList()),
        dispatch(getUserOfferStatus())
      ])
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: {
          method: 'handleOptBackIn'
        }
      })
    }
  }

  return (
    <>
      <div className='opted-out'>
        <InviteHeader
          title={
            config.OptBackInTitle !== ''
              ? config.OptBackInTitle
              : welcomeScreenConfig.InviteCodeTitle
          }
          description={config.OptBackInSubTitle}
          descriptionMarkdown={
            config.OptBackInDescription !== ''
              ? config.OptBackInDescription
              : welcomeScreenConfig.InviteCodeDescription
          }
        />
        <Button id={IdList.optInBtn} className='btn' onClick={handleOptBackIn}>
          {t`OptBackIn`}
        </Button>
        <div className='terms__links'>
          <span>{t`ByTappingOptBackIn`}&nbsp;</span>
          <a href={config.TermsAndConditionsUrl} target='_blank' className='default-link' rel='noreferrer'>
            {t`Terms&Conditions`}&nbsp;
          </a>
          <span>{t`and`}&nbsp;</span>
          <a href={config.PrivacyPolicyUrl} target='_blank' className='default-link' rel='noreferrer'>
            {t`PrivacyPolicy`}
          </a>
        </div>
      </div>
      <BaseDrawer
        isOpen={isSpinner}
        onClose={() => setIsSpinner(false)}
        isCentered
        centerCondition='both'
      >
        <LoadingDialog isSpinner={isSpinner} />
      </BaseDrawer>
    </>
  )
}

export default OptedOut
