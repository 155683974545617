export const GET_USER_INFO = 'GET_USER_INFO'
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO'

export const GET_EARNING_LIST = 'GET_EARNING_LIST'
export const CLEAR_EARNING_LIST = 'CLEAR_EARNING_LIST'

export const AGREE_TERMS = 'AGREE_TERMS'

export const GET_USER_OFFER_STATUS = 'GET_USER_OFFER_STATUS'
export const CLEAR_USER_OFFER_STATUS = 'CLEAR_USER_OFFER_STATUS'

export const GET_TRANSACTION_LIST = 'GET_TRANSACTION_LIST'
export const ADD_TRANSACTION_LIST = 'ADD_TRANSACTION_LIST'
export const CLEAR_TRANSACTION_LIST = 'CLEAR_TRANSACTION_LIST'

export const ACKNOWLEDGE_EARNING = 'ACKNOWLEDGE_EARNING'

export const CLAIM_EARNING = 'CLAIM_EARNING'

export const OPT_BACK_IN = 'OPT_BACK_IN'

export const HAVE_SEEN_TUTORIAL = 'HAVE_SEEN_TUTORIAL'

export const TRIGGER_CLAIM_PROGRESS = 'TRIGGER_CLAIM_PROGRESS'

export const DISABLE_CLAIM_PROGRESS = 'DISABLE_CLAIM_PROGRESS'

export const DISABLE_CLAIM_ANIMATION = 'DISABLE_CLAIM_ANIMATION'

export const CLAIM_ALL_EARNING = 'CLAIM_ALL_EARNING'

export const GET_POINTS_FROM_REFEREE = 'GET_POINTS_FROM_REFEREE'

export const HAVE_SEEN_NEW_FEATURE = 'HAVE_SEEN_NEW_FEATURE'

export const GET_HAVE_SEEN_NEW_FEATURE = 'GET_HAVE_SEEN_NEW_FEATURE'

export const GET_NEW_FEATURE_CONFIG = 'GET_NEW_FEATURE_CONFIG'

export const SET_IS_REFRESH_ANIMATION_COUNTER = 'SET_IS_REFRESH_ANIMATION_COUNTER'

export const GET_FAVORITE_OFFER_COUNT = 'GET_FAVORITE_OFFER_COUNT'

export const SET_HAS_JUST_LOGGED_IN = 'SET_HAS_JUST_LOGGED_IN'

export const SHOW_REACTIVATION_BONUS_DIALOG = 'SHOW_REACTIVATION_BONUS_DIALOG'

export const SET_IS_DATA_LOADED = 'SET_IS_DATA_LOADED'

export const SET_USER_REACTIVATION_STATUS = 'SET_USER_REACTIVATION_STATUS'
