import { FC } from 'react'

interface ReferCompleteBarProps {
  completed: string
}

const ReferCompleteBar: FC<ReferCompleteBarProps> = ({ completed }) => {
  const progressStyle = {
    width: `${completed}%`
  }

  return (
    <div className='ReferCompleteBar-outter'>
      <div className='ReferCompleteBar-inner' style={progressStyle} />
    </div>
  )
}

export default ReferCompleteBar
