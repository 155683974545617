import { Box, Button, FormHelperText, Typography } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import './ManageAccount.scss'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { PASSWORD, RESET_PASSWORD_FIELDS, TEXT } from '../constants'
import FormTextField from '../SignUp/FormTextField'
import { useSelector } from 'react-redux'
import { selectAuth } from '../selectors'
import { showPasswordToggleIcon, validatePassword } from '../utils'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import { BACK_BTN_MARGIN_TOP } from '../../core/utils/constants'

interface AccountPasswordEditProps {
  handleCancelClick: () => void
  handleUpdatePasswordSubmission: (data: EditPasswordParams) => void
  isDrawerView: boolean
}

const AccountPasswordEdit = ({
  handleCancelClick,
  handleUpdatePasswordSubmission,
  isDrawerView
}: AccountPasswordEditProps): ReactElement => {
  const { t } = useTranslation()
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    useState<boolean>(false)
  const initialValues: EditPasswordParams = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }
  const authState = useSelector(selectAuth)
  const i18t = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (formData: EditPasswordParams) => {
      handleUpdatePasswordSubmission(formData)
    },
    validate: (values) => {
      let errors: { [key: string]: string } = {}
      const validationFields = [
        {
          value: values.currentPassword,
          key: RESET_PASSWORD_FIELDS.CURRENT_PASSWORD
        },
        { value: values.newPassword, key: RESET_PASSWORD_FIELDS.NEW_PASSWORD },
        {
          value: values.confirmNewPassword,
          key: RESET_PASSWORD_FIELDS.CONFIRM_NEW_PASSWORD
        }
      ]
      validationFields.forEach((field) => {
        errors = validatePassword(field.value, errors, field.key, i18t)
      })
      if (
        errors.confirmNewPassword != null &&
        errors.confirmNewPassword !== i18t.t`MissingPasswordError`
      ) {
        errors.confirmNewPassword = t`PasswordsNotMatch`
      }
      if (
        errors.newPassword == null &&
        errors.confirmNewPassword == null &&
        values.newPassword !== values.confirmNewPassword
      ) {
        errors.confirmNewPassword = t`PasswordsNotMatch`
        errors.newPassword = t`PasswordsNotMatch`
      }
      return errors
    },
    validateOnChange: false
  })

  const errorDisplay = (
    <FormHelperText error>{authState.editAccountPasswordErr}</FormHelperText>
  )

  return (
    <Box
      className={`account-details-box ${!isDrawerView ? 'maxw-30' : ''}`}
      style={{ marginTop: matchesMd ? BACK_BTN_MARGIN_TOP : '' }}
    >
      {!isDrawerView && (
        <Typography className='title'>{t`EditYourPassword`}</Typography>
      )}
      <form onSubmit={form.handleSubmit}>
        <Box className='accounts-details-field'>
          <FormTextField
            id='currentPassword'
            name='currentPassword'
            value={form.values.currentPassword}
            label={t`CurrentPassword`}
            errorField={form.errors.currentPassword}
            onChange={form.handleChange}
            type={showCurrentPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showCurrentPassword,
                setShowCurrentPassword
              )
            }}
          />
          <FormTextField
            id='newPassword'
            name='newPassword'
            value={form.values.newPassword}
            label={t`NewPassword`}
            errorField={form.errors.newPassword}
            onChange={form.handleChange}
            type={showNewPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showNewPassword,
                setShowNewPassword
              )
            }}
          />
          <FormTextField
            id='confirmNewPassword'
            name='confirmNewPassword'
            value={form.values.confirmNewPassword}
            label={t`ConfirmNewPassword`}
            errorField={form.errors.confirmNewPassword}
            onChange={form.handleChange}
            type={showConfirmNewPassword ? TEXT : PASSWORD}
            InputProps={{
              endAdornment: showPasswordToggleIcon(
                showConfirmNewPassword,
                setShowConfirmNewPassword
              )
            }}
          />
          <Box
            className={`${
              !isDrawerView ? 'actions-box gap-0' : 'actions-box-drawer-view'
            }`}
          >
            <Box className='btn-box'>
              <Button className='btn lato' type='submit'>
                {t`UpdatePassword`}
              </Button>
            </Box>
            <Button
              className={`secondary-btn ${isDrawerView ? 'align-center' : ''}`}
              onClick={handleCancelClick}
            >
              {t`Cancel`}
            </Button>
          </Box>
        </Box>
        {authState.editAccountPasswordErr != null &&
          authState.editAccountPasswordErr !== '' &&
          errorDisplay}
      </form>
    </Box>
  )
}

export default AccountPasswordEdit
