import * as moment from 'moment'

export const convertSecondsToDays = (seconds: number): number => {
  return Math.floor(moment.duration(seconds, 'seconds').asDays())
}

export const diffFromNow = (end: string): string => {
  const duration = moment.duration(moment.utc(end).diff(moment.utc()))

  const days: string = duration.days() > 0 ? `${duration.days()}d` : ''
  const hours: string = duration.hours() > 0 ? `${duration.hours()}h` : ''
  const minutes: string = duration.minutes() > 0 ? `${duration.minutes()}m` : ''

  return `${days} ${hours} ${minutes}`
}

export const isLatter = (start: string, end: string): boolean => (
  moment.utc(start).unix() < moment.utc(end).unix()
)
