import React, { FC } from 'react'
import { Button, Icon } from '@material-ui/core'

import './CustomArrow.scss'

interface CustomArrowProps {
  onClick?: () => void
  side: string
  icon: FC
}

const CustomArrow: FC<CustomArrowProps> = ({ onClick, side, icon }) => {
  return (
    <Button
      className={`carousel-arrow carousel-arrow__${side}`}
      onClick={() => onClick?.()}
    >
      <Icon component={icon} />
    </Button>
  )
}
export default CustomArrow
