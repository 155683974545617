import React, { FC } from 'react'
import './OfferListItemSkeletonUpdated.scss'
import { Skeleton } from '@material-ui/lab'

interface OfferListSkeletonUpdatedProps {
  maxColumn: number
  firstIndex: number
  skeletonNum: number
}
const OfferListSkeletonUpdated: FC<OfferListSkeletonUpdatedProps> = ({
  maxColumn,
  firstIndex,
  skeletonNum
}) => {
  return (
    <div>
      {Array.from({ length: maxColumn }).map((_, i) => (
        <div style={{ marginBottom: '1em' }} key={`skeletonCategory-${i}`}>
          <Skeleton
            variant='rect'
            classes={{
              root: 'offer-list--loading__category-title skeleton-txt'
            }}
            className='category-skeleton-updated'
            style={{ marginTop: i !== firstIndex ? '2em' : 0 }}
          />
          {i === firstIndex &&
            Array.from({ length: skeletonNum }).map((_, i) => (
              <div key={`skeletonItem-${i}`}>
                <div className='container'>
                  <Skeleton className='left' />
                  <div className='right'>
                    <Skeleton className='top' />
                    <Skeleton className='bottom' />
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  )
}

export default OfferListSkeletonUpdated
