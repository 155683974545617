import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import { useSelector } from 'react-redux'

import { RoutePath } from '../../../core/routes/route-path'
import { IdList } from '../../../core/utils/id-list'
import { selectProviderList } from '../../../provider/selectors'

import './Navigation.scss'
import { selectConfig } from '../../../config/selectors'
import { selectCampaignReferral } from '../../../campaign/selectors'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

export const Navigation: FC = () => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const providerList: ProviderAccount[] = useSelector(selectProviderList)
  const config: ConfigState = useSelector(selectConfig)
  const referralInfo = useSelector(selectCampaignReferral)

  const handleHomeLink = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Home'
    })
  }

  const handleConnectLink = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Connect Account'
    })
  }

  const handleReferralLink = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Refer a Friend'
    })
  }
  return (
    <div className='navigation'>
      <NavLink id={IdList.navHome} exact to={RoutePath.Home} className='navigation-link' onClick={handleHomeLink}>
        <span className='navigation-link__text'>{t`Home`}</span>
      </NavLink>
      {providerList.length > 0 &&
        <NavLink id={IdList.navConnect} to={RoutePath.ConnectAccount} className='navigation-link' onClick={handleConnectLink}>{t`ConnectAccount`}</NavLink>}
      <NavLink id={IdList.navInviteCode} to={RoutePath.Invite} className='navigation-link'>{t`InviteCode`}</NavLink>
      {config.IsReferralAvailable &&
      referralInfo != null &&
      referralInfo.ReferralConfiguration != null && (
        <NavLink
          id={IdList.navReferFriend}
          to={RoutePath.Refer}
          className='navigation-link'
          onClick={handleReferralLink}
        >
          {t`ReferFriend`}
        </NavLink>
      )}
    </div>
  )
}
