export const copyToDashboard = (text: string): void => {
  if (navigator.clipboard != null) {
    if (navigator.clipboard.writeText != null) {
      navigator.clipboard.writeText(text).catch((err) => err)
    }
  } else {
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.style.top = '0'
    textarea.style.left = '0'
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.focus()
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }
}
