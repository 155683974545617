import * as actionTypes from './actionTypes'
import { defaultAction, initialState } from './initialState'

const reducer = (state: OfferState = initialState, action: OfferListAction = defaultAction): OfferState => {
  switch (action.type) {
    case actionTypes.GET_OFFER_LIST:
      return {
        ...state,
        offerList: [...action.payload]
      }
    case actionTypes.CLEAR_OFFER_LIST:
      return {
        ...state,
        offerList: [...action.payload]
      }
    case actionTypes.GET_OFFER_HIDDEN_LIST:
      return {
        ...state,
        offerHiddenList: [...action.payload]
      }
    case actionTypes.GET_OFFER_CATEGORIES:
      return {
        ...state,
        offerCategories: action.payload
      }
  }
  return state
}

export default reducer
