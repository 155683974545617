import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'

import { Stars, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { useMediaQuery } from '@react-hook/media-query'

import SectionTitle from '../../SectionTitle/SectionTitle'
import { selectCampaignFeaturedList, selectCampaignList } from '../selectors'
import CampaignCard from '../Card/Campaign'
import CampaignList from './CampaignList/CampaignList'
import { breakpoints } from '../../core/utils/css-selectors'
import CustomArrow from '../../ui/btn/CustomArrow/CustomArrow'

import './Campaigns.scss'

const Campaigns: FC = () => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const firstFeaturedCampaign = useSelector(selectCampaignFeaturedList)[0]

  const campaignList = useSelector(selectCampaignList).filter(
    (c) => c.CampaignType
  )

  const featuredCampaign = useMemo(
    () =>
      campaignList.find(
        (c) => firstFeaturedCampaign?.campaignId === c.CampaignID
      ),
    [firstFeaturedCampaign, campaignList]
  )

  return (
    <div className='campaigns-container'>
      <div>
        {featuredCampaign != null && (
          <>
            <div className='campaigns-title'>
              <SectionTitle title={t`Featured`} Icon={Stars} />
            </div>
            <div>
              <CampaignCard
                key={featuredCampaign.CampaignID}
                campaignItem={featuredCampaign}
                containerClassName='featured-campaign'
                imgClassName='featured-campaign-img'
              />
            </div>
          </>
        )}
      </div>

      {isDesktop ? (
        <div>
          {campaignList.length > 0 && (
            <>
              <div className='campaigns-title'>
                <SectionTitle title={t`WaysToEarnPoints`} />
              </div>
              <Slider
                className='slider variable-width'
                dots={false}
                arrows
                infinite={false}
                slidesToShow={3}
                slidesToScroll={1}
                nextArrow={
                  <CustomArrow
                    side='next'
                    icon={KeyboardArrowRight}
                  />
                }
                prevArrow={
                  <CustomArrow
                    side='prev'
                    icon={KeyboardArrowLeft}
                  />
                }
              >
                {campaignList.map((campaign, index) => (
                  <CampaignCard
                    key={campaign.CampaignID}
                    campaignItem={campaign}
                    indexing={index}
                    containerClassName='campaign'
                    imgClassName='campaign-img'
                  />
                ))}
              </Slider>
            </>
          )}
        </div>
      ) : (
        <CampaignList />
      )}
    </div>
  )
}

export default Campaigns
