import React, { ReactElement, useState } from 'react'
import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import ManageAccount from './ManageAccount'
import { useTranslation } from 'react-i18next'

interface ManageAccountDrawerProps {
  isOpenMyAccount: boolean
  setIsOpenMyAccount: (value: boolean) => void
}

const ManageAccountDrawer = ({
  isOpenMyAccount,
  setIsOpenMyAccount
}: ManageAccountDrawerProps): ReactElement => {
  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false)
  const { t } = useTranslation()

  const changeDrawerTitle = (value: boolean): void => {
    setIsPasswordEdit(value)
  }

  return (
    <BaseDrawer
      isOpen={isOpenMyAccount}
      onClose={() => setIsOpenMyAccount(false)}
      title={isPasswordEdit ? t`EditYourPassword` : t`MyAccount`}
      fullWidth
      maxWidth='md'
    >
      <ManageAccount
        isDrawerView={isOpenMyAccount}
        changeDrawerTitle={(value) => changeDrawerTitle(value)}
      />
    </BaseDrawer>
  )
}

export default ManageAccountDrawer
