import React, { FC, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UserStatus } from '../../user/userStatus'
import { RoutePath } from './route-path'
import { selectAuth } from '../../auth/selectors'
import NewUserRoute from './NewUserRoute'
import { selectConfig, selectIsActionLoaded } from '../../config/selectors'
import { GET_CONFIG_INFO } from '../../config/actionTypes'
import { selectUser } from '../../user/selectors'
import history from '../../core/history'

const ProtectedRoute: FC<any> = ({ component, isAuth, ...rest }: any) => {
  const config: ConfigState = useSelector(selectConfig)
  const auth: AuthState = useSelector(selectAuth)
  const isConfigLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_CONFIG_INFO)
  )

  const user: UserState = useSelector(selectUser)

  useEffect(() => {
    if (user.UserID === undefined) {
      return
    }

    if (user.userStatus === UserStatus.Suspended) {
      history.replace(RoutePath.Suspended)
    }

    if (user.userStatus === UserStatus.OptedOut) {
      history.replace(RoutePath.OptedOut)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.UserID, user.userStatus])

  const routeComponent: FC = (props: any) =>
    isConfigLoaded ? (
      auth.isAuth ? (
        <NewUserRoute component={component} {...rest} />
      ) : config.IsStandalonModeSupported ? (
        <Redirect
          to={{
            pathname: RoutePath.SignIn,
            state: { from: props.location }
          }}
        />
      ) : (
        <></>
      )
    ) : (
      <></>
    )

  return <Route {...rest} render={routeComponent} />
}

export default ProtectedRoute
