import { sortBy } from 'lodash'

import * as actionTypes from './actionTypes'
import { initialState, defaultAction } from './initialState'

const reducer = (
  state: CampaignState = initialState,
  action: CampaignAction = defaultAction
): CampaignState => {
  switch (action.type) {
    case actionTypes.GET_CAMPAIGN: {
      const copyCampaignList = [...state.campaignList]
      const campaignIndex = copyCampaignList.findIndex(
        campaign =>
          campaign.CampaignID.toString() ===
          action.payload.CampaignID.toString()
      )

      if (campaignIndex !== -1) {
        copyCampaignList[campaignIndex] = { ...action.payload }
      }

      return {
        ...state,
        campaignList: [...copyCampaignList]
      }
    }
    case actionTypes.GET_CAMPAIGN_HIDDEN_LIST: {
      const copyCampaignListHidden = [...state.campaignListHidden]

      action.payload.forEach((newCampaign: Campaign) => {
        const findCampaignIndex = copyCampaignListHidden.findIndex(
          oldCampaign => oldCampaign.CampaignID === newCampaign.CampaignID
        )

        if (findCampaignIndex === -1) {
          copyCampaignListHidden.push({ ...newCampaign })
        } else {
          copyCampaignListHidden[findCampaignIndex] = { ...newCampaign }
        }
      })

      return {
        ...state,
        campaignListHidden: [...copyCampaignListHidden]
      }
    }
    case actionTypes.GET_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload.map((campaign: Campaign) => ({
          ...campaign,
          badges: sortBy(campaign.badges, 'UiPosition')
        }))
      }
    case actionTypes.CLEAR_CAMPAIGN_LIST:
      return {
        ...state,
        campaignList: action.payload
      }
    case actionTypes.GET_CAMPAIGN_FEATURED_LIST:
      return {
        ...state,
        campaignFeaturedList: action.payload
      }
    case actionTypes.GET_CAMPAIGN_CATEGORIES:
      return {
        ...state,
        campaignCategories: action.payload
      }
    case actionTypes.GET_CAMPAIGN_STATUSES:
      return {
        ...state,
        campaignStatuses: action.payload
      }
    case actionTypes.PARTICIPATE_PROMOTION:
      return {
        ...state,
        campaignPromotion: action.payload
      }
    case actionTypes.GET_REFERRAL:
      return {
        ...state,
        campaignReferralInfo: action.payload
      }
  }
  return state
}

export default reducer
