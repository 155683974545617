import { FC } from 'react'
import './TierProgress.scss'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import coinIcon from '../../../assets/images/coin-color.png'

const PROGRESS_COLORS = {
  DEFAULT: '#d7dfe5',
  IN_PROGRESS: '#FFA300',
  LIMIT_REACHED: '#09ba70'
}

interface TierProgressProps {
  tierProperties: Array<{ [key: string]: number }>
  tierProgress: { [key: number]: number }
  isDetailView?: boolean
  tierType?: string
  isLastTierCompleted: boolean
}

const TierProgress: FC<TierProgressProps> = ({
  tierProperties,
  tierProgress,
  isDetailView = false,
  tierType = '',
  isLastTierCompleted
}) => {
  const { t } = useTranslation()

  return (
    <div className='progress-bar-wrapper'>
      <div className='progress-bar-container'>
        {tierProperties.map((tier, index) => {
          const { Tier, RewardPoints, MaxBehaviors } = tier
          const progress = _.isFinite(tierProgress[Tier]) ? tierProgress[Tier] : 0
          let tierColor = PROGRESS_COLORS.DEFAULT
          if (isLastTierCompleted) {
            tierColor = PROGRESS_COLORS.LIMIT_REACHED
          } else if (progress > 0) {
            tierColor = PROGRESS_COLORS.IN_PROGRESS
          }
          return (
            <div
              key={`${Tier}-${index}`}
              className='progress-bar-segment-wrapper'
            >
              {isDetailView && (
                <div className='tier-text mb-05 align-end'>
                  {MaxBehaviors}{' '}
                  {tierType === 'BEHAVIOR' ? t('trips') : t('surveys')}
                </div>
              )}
              <div className='progress-bar-segment'>
                <div
                  className='progress-bar-segment-progress'
                  style={{
                    width: `${progress}%`,
                    backgroundColor: tierColor
                  }}
                />
              </div>
              <div className='tier-info'>
                <img
                  src={coinIcon}
                  className='coin-icon m-right-3'
                  alt='Coin Icon'
                />
                <div className='tier-text'>{RewardPoints}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TierProgress
