import { createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'

import rootReducer from './reducers'

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

const store: Store<ApplicationState> = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk),
    sentryReduxEnhancer
  )
)

export default store
