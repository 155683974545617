import { Dispatch } from 'redux'

import * as actionTypes from './actionTypes'

export const processNext = (payload: any): TutorialStepAction => ({
  type: actionTypes.PROCESS_NEXT,
  payload
})

export const setCurrentStepTitle = (payload: any): TutorialStepTitleAction => ({
  type: actionTypes.SET_CURRENT_STEP_TITLE,
  payload
})

export const setDrawerOpenForTutorial = (payload: any): TutorialDrawerOpen => ({
  type: actionTypes.SET_DRAWER_OPEN_FOR_TUTORIAL,
  payload
})

export const setMoreTabOpenForTutorial = (payload: any): TutorialDrawerOpen => ({
  type: actionTypes.SET_DRAWER_OPEN_FOR_TUTORIAL,
  payload
})

export const setTutorialOn = (payload: any): TutorialOnAction => ({
  type: actionTypes.SET_TUTORIAL_ON,
  payload
})

export const processNextStep: any = (step: string) => async (dispatch: Dispatch<TutorialStepAction>): Promise<TutorialStepAction> => {
  return dispatch(processNext(step))
}
