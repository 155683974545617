import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RoutePath } from './route-path'
import { selectUser } from '../../user/selectors'
import { UserStatus } from '../../user/userStatus'
import { selectConfig } from '../../config/selectors'

const NewUserRoute: FC<any> = ({ component, isAuth, ...rest }: any) => {
  const user: UserState = useSelector(selectUser)
  const config: ConfigState = useSelector(selectConfig)

  const routeComponent: FC = (props: any) => {
    return user.userStatus !== UserStatus.New || config.IsStandalonModeSupported
      ? React.createElement(component, props)
      : (
        <Redirect to={{
          pathname: RoutePath.Terms,
          state: { from: props.location }
        }}
        />
      )
  }
  return <Route {...rest} render={routeComponent} />
}

export default NewUserRoute
