import { FC } from 'react'
import { useHistory } from 'react-router'
import { useTracking } from 'react-tracking'

import { Button } from '@material-ui/core'
import { useMediaQuery } from '@react-hook/media-query'
import MDEditor from '@uiw/react-md-editor'

import BaseDrawer from '../../ui/BaseDrawer/BaseDrawer'
import { breakpoints } from '../../core/utils/css-selectors'
import placeholderImg from '../../../assets/images/campaign/placeholder-campaign.png'
import { isExternal, isValidUrl } from '../../utils'
import './UpdateCardDrawer.scss'
import _ from 'lodash'

interface UpdateCardDrawerProps {
  updateCard: UpdateCard
  setSelectedUpdateCard: (updateCard: null | UpdateCard) => void
}

const UpdateCardDrawer: FC<UpdateCardDrawerProps> = ({
  updateCard,
  setSelectedUpdateCard
}) => {
  const isDesktop = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const history = useHistory()
  const { trackEvent } = useTracking()

  const handleButtonClick = (event: any = null): void => {
    setSelectedUpdateCard(null)
    if (event != null) {
      event.preventDefault()
    }

    trackEvent({
      action: 'Update Detail CTA',
      category: 'Updates',
      payload: {
        updateCardID: updateCard.UpdateCardID
      }
    })

    if (isValidUrl(updateCard.CtaLink)) {
      const external = isExternal(updateCard.CtaLink)
      if (external) {
        window.open(updateCard.CtaLink)
      } else {
        history.push(updateCard.CtaLink)
      }
    }
  }

  const getImg = (): string => {
    if (updateCard?.ImageUrl != null) {
      return updateCard.ImageUrl
    }
    return placeholderImg
  }

  return (
    <BaseDrawer
      isOpen={Boolean(updateCard)}
      onClose={() => setSelectedUpdateCard(null)}
      customMargin={isDesktop ? '20px' : ''}
      isFromUpdatedUI={!isDesktop}
      isFromUpdatedDesktopUI={isDesktop}
    >
      <div
        className={`update-card-drawer-updated ${isDesktop ? 'maxh-75' : ''}`}
      >
        {!_.isEmpty(updateCard?.ImageUrl) && (
          <div
            className={`update-card-drawer-img ${
              isDesktop ? 'updated-img' : ''
            }`}
            style={{ backgroundImage: `url(${getImg()})` }}
          />
        )}

        <div
          className={`update-card-drawer-details ${isDesktop ? 'p-2' : ''} ${
            _.isEmpty(updateCard?.ImageUrl)
              ? !isDesktop
                ? 'flex-2 pt-0'
                : 'pt-0'
              : ''
          }`}
        >
          {updateCard.SubHeader1 != null && (
            <div className='subheader-1'>{updateCard.SubHeader1}</div>
          )}

          <div>
            <span className='title'>{updateCard.Title}</span>
          </div>

          <div className='description'>
            <MDEditor.Markdown
              source={updateCard.Description}
              style={{ whiteSpace: 'pre-wrap' }}
            />
          </div>
        </div>

        {Boolean(updateCard.CtaLink) && (
          <div className={`cta ${isDesktop ? 'cta-updated' : ''}`}>
            <Button
              onClick={handleButtonClick}
              onTouchEnd={handleButtonClick}
              className='btn'
            >
              {updateCard.CtaLabel}
            </Button>
          </div>
        )}
      </div>
    </BaseDrawer>
  )
}

export default UpdateCardDrawer
