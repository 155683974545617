import variables from '../../../assets/style/utils/variables.module.scss'

interface CssBreakpoints {
  minWidthXs: string
  minWidthMd: string
}

export const breakpoints: CssBreakpoints = {
  minWidthXs: `min-width: ${variables['breakpoint-xs'] as string}`,
  minWidthMd: `min-width: ${variables['breakpoint-md'] as string}`
}
