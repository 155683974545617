import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectWelcomeScreenConfig } from '../../config/selectors'
import InviteHeader from '../../ui/Invite/InviteHeader/InviteHeader'
import InviteForm from '../../ui/Invite/InviteForm/InviteForm'
import { RoutePath } from '../../core/routes/route-path'
import { changeTab } from '../../config/actions'
import { TabsName } from '../../core/tabsName'

import './InviteCode.scss'

const InviteCode: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const welcomeScreenConfig: WelcomeScreenConfig = useSelector(
    selectWelcomeScreenConfig
  )

  const handleInviteCode = async (): Promise<void> => {
    history.push(RoutePath.Home)
    return dispatch(changeTab(TabsName.Earn))
  }
  return (
    <div className='invite-code'>
      <InviteHeader title={welcomeScreenConfig.InviteCodeTitle} description={welcomeScreenConfig.InviteCodeDescription} />
      <InviteForm
        btnTxt={t`ApplyCode`}
        handleSubmit={handleInviteCode}
        label={t`EnterInviteCode`}
      />
    </div>
  )
}

export default InviteCode
