import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDrawer from '../../BaseDrawer/BaseDrawer'
import ReactMarkdown from 'react-markdown'

import './ReferHowTo.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'

interface ReferHowToProps {
  referralInstructions: any
  isOpenReferHowTo?: boolean
  setIsOpenReferHowTo?: (isOpenReferHowTo: boolean) => void
}

const ReferHowTo: FC<ReferHowToProps> = ({
  referralInstructions = null,
  isOpenReferHowTo = false,
  setIsOpenReferHowTo = () => {}
}) => {
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const closeDialog = (): void => {
    setIsOpenReferHowTo(false)
  }
  const [referralSteps, setReferralSteps] = useState([
    {
      Step: '1',
      Description: t`ReferralSteps1`
    },
    {
      Step: '2',
      Description: t`ReferralSteps2`
    },
    {
      Step: '3',
      Description: t`ReferralSteps3`
    }
  ])
  useEffect(() => {
    if (referralInstructions != null && referralInstructions.length > 0) {
      setReferralSteps(referralInstructions)
    }
  }, [referralInstructions])

  const renderReferralInstruction = (): any => {
    return !matchesMd ? (
      referralSteps.map((inst, key) => {
        return (
          <tr key={key}>
            <td>
              <p className='HowToStep'>{inst.Step}</p>
            </td>
            <td>
              <ReactMarkdown linkTarget='_blank' className='HowToDescription'>
                {inst.Description}
              </ReactMarkdown>
            </td>
          </tr>
        )
      })
    ) : (
      <ol>
        {referralSteps.map((inst, key) => {
          return (
            <li key={key}>
              <ReactMarkdown linkTarget='_blank' className='HowToDescription'>
                {inst.Description}
              </ReactMarkdown>
            </li>
          )
        })}
      </ol>
    )
  }

  const content = (
    <>
      <div className='ReferHowTo-title'>
        <p>{t`ReferHowTo`}</p>
      </div>
      <div className='ReferHowTo-list'>
        <table className='ReferHowTo-list_wrapper'>
          <tbody>{renderReferralInstruction()}</tbody>
        </table>
      </div>
    </>
  )

  return !matchesMd ? (
    <BaseDrawer isOpen={isOpenReferHowTo} onClose={closeDialog}>
      {content}
    </BaseDrawer>
  ) : (
    <div className='referral-instructions-desktop'>{content}</div>
  )
}
export default ReferHowTo
