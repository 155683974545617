import _ from 'lodash'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import StarOutlineIcon from '@material-ui/icons/StarOutline'

import CustomArrow from '../../../../ui/btn/CustomArrow/CustomArrow'
import { selectUser } from '../../../../user/selectors'
import CampaignCard from '../../../Card/Campaign'

import './CampaignListCategory.scss'

interface CampaignListCategoryProps {
  campaignCategory: CampaignListByCategory
  isFeatured?: boolean
  categoryIndex: any
  featuredCampaignsDetailsList: Campaign[]
}

const CampaignListCategory: FC<CampaignListCategoryProps> = ({
  campaignCategory,
  isFeatured,
  categoryIndex,
  featuredCampaignsDetailsList
}) => {
  const { featureFlags }: UserState = useSelector(selectUser)
  const featuredCampaignsIds = featuredCampaignsDetailsList.map(
    (c) => c.CampaignID
  )

  return (
    <div className='campaign-list-category'>
      <div className='campaign-list-category__title'>
        {isFeatured === true ? (
          <StarOutlineIcon className='campaign-list-category__ico m-right-8' />
        ) : (
          !_.isEmpty(campaignCategory.iconImageUrl) && (
            <img
              src={campaignCategory.iconImageUrl}
              className='campaign-list-category__ico m-right-8'
              alt='Campaign List Category Icon'
            />
          )
        )}
        <span>{campaignCategory.CategoryName}</span>
      </div>
      <div>
        <Slider
          className='slider variable-width'
          dots={false}
          draggable
          infinite={false}
          slidesToShow={2}
          slidesToScroll={1}
          nextArrow={
            <CustomArrow
              side='next'
              icon={KeyboardArrowRightIcon}
            />
          }
          prevArrow={
            <CustomArrow
              side='prev'
              icon={KeyboardArrowLeftIcon}
            />
          }
        >
          {campaignCategory.campaignList
            .filter((c) => !featuredCampaignsIds.includes(c.CampaignID))
            .map((campaignItem, index) => {
              if (featureFlags.UX_UPDATES) {
                const isInFeaturedList = featuredCampaignsDetailsList?.some(
                  (featuredCampaign) =>
                    featuredCampaign.CampaignID === campaignItem.CampaignID
                )
                return (
                  !isInFeaturedList && (
                    <CampaignCard
                      key={index}
                      campaignItem={campaignItem}
                      indexing={index}
                      categoryIndex={categoryIndex}
                      containerClassName='category-campaign'
                      imgClassName='category-campaign-img'
                    />
                  )
                )
              } else {
                return (
                  <CampaignCard
                    key={index}
                    campaignItem={campaignItem}
                    indexing={index}
                    categoryIndex={categoryIndex}
                    containerClassName='category-campaign'
                    imgClassName='category-campaign-img'
                  />
                )
              }
            })}
        </Slider>
      </div>
    </div>
  )
}

export default CampaignListCategory
