import React, { FC } from 'react'

import './Locked.scss'

const Locked: FC = () => {
  return (
    <>
      <div className='locked-offers' />
    </>
  )
}

export default Locked
