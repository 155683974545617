import { axiosInstance } from '../core/api/init'
import { languageService } from '../language/service'

export const offerService = {
  getOfferList: async (): Promise<Offer[]> => {
    return await axiosInstance.get(`/rest/offers/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },

  redeemOffer: async (OfferID: string): Promise<Redemption> => {
    return await axiosInstance.post(`/rest/offers/${OfferID}/redemption`)
      .then((response) => response.data)
  },

  getOffer: async (OfferID: string): Promise<Offer[]> => {
    return await axiosInstance.get(`/rest/offers/${OfferID}/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },

  getOfferCategories: async (): Promise<OfferCategory[]> => {
    return await axiosInstance.get(`/rest/offerCategories/${languageService.getLanguage()}`)
      .then((response) => response.data)
  },

  setFavoriteOffer: async (
    OfferID: string,
    isFavorite: boolean
  ): Promise<void> => {
    return await axiosInstance
      .post(`/rest/offers/setFavoriteOffer/${OfferID}/${isFavorite.toString()}`)
      .then((response) => response.data)
  }
}
