import React, { FC } from 'react'

const ExclamationMarkIco: FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#E9503C' fillRule='nonzero'>
          <g>
            <path d='M26 13c1.43 0 2.6 1.17 2.6 2.6V26c0 1.43-1.17 2.6-2.6 2.6-1.43 0-2.6-1.17-2.6-2.6V15.6c0-1.43 1.17-2.6 2.6-2.6zm-.026-13C11.622 0 0 11.648 0 26s11.622 26 25.974 26C40.352 52 52 40.352 52 26S40.352 0 25.974 0zM26 46.8C14.508 46.8 5.2 37.492 5.2 26S14.508 5.2 26 5.2 46.8 14.508 46.8 26 37.492 46.8 26 46.8zm2.6-7.8h-5.2v-5.2h5.2V39z' transform='translate(-695 -425) translate(695 425)' />
          </g>
        </g>
      </g>
    </svg>

  )
}

export default ExclamationMarkIco
