import { Dispatch } from 'redux'
import * as actionTypes from './actionTypes'
import { userService } from './service'
import { clearLoaded, setLoaded } from '../config/actions'
export const getUserInfo: any = () => async (dispatch: Dispatch<UserInfoAction | AuthAction | LoadedAction>): Promise<UserInfoAction | UserAction> => {
  return await userService.getUserInfo()
    .then((data) => dispatch(setUserInfo(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_USER_INFO)))
}
export const clearUserInfo: any = () => async (dispatch: Dispatch<UserInfoAction>): Promise<UserInfoAction> => {
  return dispatch(setClearUserInfo())
}
export const getEarningList: any = () => async (dispatch: Dispatch<EarningListAction | LoadedAction>): Promise<EarningListAction> => {
  return await userService.getEarningList()
    .then((data) => dispatch(setEarningList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_EARNING_LIST)))
}
export const clearEarningList: any = () => async (dispatch: Dispatch<EarningListAction>): Promise<EarningListAction> => {
  return dispatch(setClearEarningList())
}
export const agreeTerms: any = () => async (dispatch: Dispatch<ConsentAction | LoadedAction>): Promise<ConsentAction> => {
  return await userService.agreeTerms()
    .then((data) => dispatch(setAgreeTerms(data)))
    .then(() => dispatch(getEarningList()))
    .finally(() => dispatch(setLoaded(actionTypes.AGREE_TERMS)))
}
export const getUserOfferStatus: any = () => async (dispatch: Dispatch<UserOfferStatusAction | LoadedAction>): Promise<UserOfferStatusAction> => {
  return await userService.getOfferStatus()
    .then((data) => dispatch(setUserOfferStatus(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_USER_OFFER_STATUS)))
}
export const clearUserOfferStatus: any = () => async (dispatch: Dispatch<UserOfferStatusAction>): Promise<UserOfferStatusAction> => {
  return dispatch(setClearUserOfferStatus())
}
export const getTransactionList: any = (type?: string, customUrl?: string) => async (dispatch: Dispatch<TransactionListAction | LoadedAction>): Promise<TransactionListAction> => {
  return await userService.getTransactionList(customUrl, type)
    .then((data) => {
      if (customUrl !== undefined) {
        return dispatch(setAddTransactionList(data))
      } else {
        return dispatch(setTransactionList(data))
      }
    })
    .finally(() => dispatch(setLoaded(actionTypes.GET_TRANSACTION_LIST)))
}
export const clearTransactionList: any = () => async (dispatch: Dispatch<TransactionListAction | LoadedAction>): Promise<TransactionListAction> => {
  dispatch(clearLoaded(actionTypes.GET_TRANSACTION_LIST))
  return dispatch(setClearTransactionList())
}
export const ackEarning: any = (EarningID: string) => async (dispatch: Dispatch<AckEarningAction | LoadedAction>): Promise<AckEarningAction> => {
  return await userService.ackEarning(EarningID)
    .then((data) => dispatch(setEarningAck(data)))
    .finally(() => dispatch(setLoaded(actionTypes.ACKNOWLEDGE_EARNING)))
}
export const optBackIn: any = () => async (dispatch: Dispatch<UserAction | LoadedAction>): Promise<LoadedAction> => {
  return await userService.optBackIn()
    .then(() => dispatch(getUserInfo()))
    .finally(() => dispatch(setLoaded(actionTypes.OPT_BACK_IN)))
}
export const getPointsFromReferee: any = () => async (dispatch: Dispatch<PointsFromRefereeAction | LoadedAction>): Promise<PointsFromRefereeAction> => {
  return await userService.getPointsFromReferee()
    .then((data) => dispatch(setPointsFromReferee(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_POINTS_FROM_REFEREE)))
}
export const showNewFeatureDialog: any = () => async (dispatch: Dispatch<HaveSeenNewFeatureAction | LoadedAction>): Promise<HaveSeenNewFeatureAction> => {
  return await userService.ShowNewFeatureDialog()
    .then((data) => dispatch(setHaveSeenNewFeature(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_HAVE_SEEN_NEW_FEATURE)))
}
export const updateShowReactivationBonusDialog: any =
  () =>
    async (
      dispatch: Dispatch<ShowReactivationBonusDialogAction>
    ): Promise<boolean | ShowReactivationBonusDialogAction> => {
      return await userService.updateShowReactivationBonusDialog()
        .then(() => dispatch(seenReactivationBonusDialog()))
    }

export const getUserReactivationStatus: any =
  () =>
    async (
      dispatch: Dispatch<SetUserReactivationStatusAction>
    ): Promise<SetUserReactivationStatusAction> => {
      return await userService
        .getUserReactivationStatus()
        .then((data) => dispatch(setUserReactivationStatus(data)))
    }

export const getNewFeatureConfig: any = () => async (dispatch: Dispatch<GetNewFeatureConfigAction | LoadedAction>): Promise<GetNewFeatureConfigAction> => {
  return await userService.getNewFeatureConfig()
    .then((data) => dispatch(setNewFeatureConfig(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_NEW_FEATURE_CONFIG)))
}
export const updatePreferredLanguage: any = (LanguageCode: string) => async (): Promise<string> => {
  return await userService.updatePreferredLanguage(LanguageCode)
}
export const getFavoriteOfferCount: any = () => async (dispatch: Dispatch<GetFavoriteOfferCountAction | LoadedAction>): Promise<GetFavoriteOfferCountAction> => {
  return await userService.getFavoriteOfferCount()
    .then((data) => dispatch(setFavoriteOfferCount(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_FAVORITE_OFFER_COUNT)))
}
const setUserInfo = (payload: UserInfo): UserInfoAction => ({
  type: actionTypes.GET_USER_INFO,
  payload
})
const setClearUserInfo = (): UserInfoAction => ({
  type: actionTypes.CLEAR_USER_INFO,
  payload: {
    UserID: undefined,
    Balance: undefined,
    earningsCount: 0,
    redeemsCount: 0,
    userStatus: undefined,
    usedInviteCodes: []
  }
})
const setEarningList = (payload: EarningList): EarningListAction => ({
  type: actionTypes.GET_EARNING_LIST,
  payload
})
const setClearEarningList = (): EarningListAction => ({
  type: actionTypes.CLEAR_EARNING_LIST,
  payload: {
    data: [],
    links: {
      next: '',
      previous: '',
      self: ''
    }
  }
})
const setAgreeTerms = (payload: Consent): ConsentAction => ({
  type: actionTypes.AGREE_TERMS,
  payload
})
const setUserOfferStatus = (payload: UserOfferStatus[]): UserOfferStatusAction => ({
  type: actionTypes.GET_USER_OFFER_STATUS,
  payload
})
const setClearUserOfferStatus = (): UserOfferStatusAction => ({
  type: actionTypes.CLEAR_USER_OFFER_STATUS,
  payload: []
})
const setTransactionList = (payload: TransactionList): TransactionListAction => ({
  type: actionTypes.GET_TRANSACTION_LIST,
  payload
})
const setAddTransactionList = (payload: TransactionList): TransactionListAction => ({
  type: actionTypes.ADD_TRANSACTION_LIST,
  payload
})
const setClearTransactionList = (): TransactionListAction => ({
  type: actionTypes.CLEAR_TRANSACTION_LIST,
  payload: {
    data: [],
    links: {
      next: '',
      previous: '',
      self: ''
    }
  }
})
const setEarningAck = (payload: AckEarning): AckEarningAction => ({
  type: actionTypes.ACKNOWLEDGE_EARNING,
  payload
})
export const setHaveSeenTutorial = (payload: boolean): HaveSeenTutorialAction => ({
  type: actionTypes.HAVE_SEEN_TUTORIAL,
  payload
})
const setPointsFromReferee = (payload: number): PointsFromRefereeAction => ({
  type: actionTypes.GET_POINTS_FROM_REFEREE,
  payload
})
export const setHaveSeenNewFeature = (payload: boolean): HaveSeenNewFeatureAction => ({
  type: actionTypes.HAVE_SEEN_NEW_FEATURE,
  payload
})
export const setNewFeatureConfig = (payload: NewFeatureConfig[]): GetNewFeatureConfigAction => ({
  type: actionTypes.GET_NEW_FEATURE_CONFIG,
  payload
})
export const setIsRefreshAnimationCounter = (payload: boolean): SetIsRefreshCounter => ({
  type: actionTypes.SET_IS_REFRESH_ANIMATION_COUNTER,
  payload
})
export const setFavoriteOfferCount = (payload: number): GetFavoriteOfferCountAction => ({
  type: actionTypes.GET_FAVORITE_OFFER_COUNT,
  payload
})
export const setHasJustLoggedIn = (payload: boolean): SetHasJustLoggedInAction => ({
  type: actionTypes.SET_HAS_JUST_LOGGED_IN,
  payload
})
export const seenReactivationBonusDialog = (): ShowReactivationBonusDialogAction => ({
  type: actionTypes.SHOW_REACTIVATION_BONUS_DIALOG
})
export const setIsDataLoaded = (payload: boolean): SetIsDataLoadedAction => ({
  type: actionTypes.SET_IS_DATA_LOADED,
  payload
})
export const setUserReactivationStatus = (
  payload: UserReactivationStatusResponse
): SetUserReactivationStatusAction => ({
  type: actionTypes.SET_USER_REACTIVATION_STATUS,
  payload
})
