export const GET_CONFIG_INFO = 'GET_CONFIG_INFO'

export const SET_APP_START_UTC = 'SET_APP_START_UTC'

export const CHANGE_TAB = 'CHANGE_TAB'

export const SET_LOADED = 'SET_LOADED'

export const CLEAR_LOADED = 'CLEAR_LOADED'

export const SET_WELCOME = 'SET_WELCOME'

export const SET_SIGNUP_WELCOME = 'SET_SIGNUP_WELCOME'

export const SET_OFFLINE = 'SET_OFFLINE'

export const SET_SHAKE = 'SET_SHAKE'

export const SET_APP_ERROR = 'SET_APP_ERROR'

export const SET_NEW_FEATURE = 'SET_NEW_FEATURE'
