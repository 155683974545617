import * as actionTypes from './actionTypes'
import {
  initialState,
  defaultAction,
  initialFeatureFlags
} from './initialState'
import { orderBy } from 'lodash'
import moment from 'moment'

import { TransactionType } from './transactionType'

const handleUserFeatureFlags = (
  userFeatureFlags: FeatureFlags
): FeatureFlags => {
  const featureFlags: FeatureFlags = { ...initialFeatureFlags }
  Object.keys(featureFlags).forEach((flag) => {
    if (userFeatureFlags[flag as keyof FeatureFlags]) {
      featureFlags[flag as keyof FeatureFlags] =
        userFeatureFlags[flag as keyof FeatureFlags]
    }
  })
  return featureFlags
}

const reducer = (
  state: UserState = initialState,
  action: UserAction = defaultAction
): UserState => {
  switch (action.type) {
    case actionTypes.GET_USER_INFO: {
      return {
        ...state,
        ...action.payload,
        featureFlags: handleUserFeatureFlags(action.payload.featureFlags ?? {})
      }
    }
    case actionTypes.CLEAR_USER_INFO:
      return {
        ...state,
        ...action.payload
      }
    case actionTypes.HAVE_SEEN_TUTORIAL:
      return {
        ...state,
        HaveSeenTutorial: action.payload
      }
    case actionTypes.SHOW_REACTIVATION_BONUS_DIALOG:
      return {
        ...state,
        showReactivationBonusDialog: false
      }
    case actionTypes.GET_EARNING_LIST:
      return {
        ...state,
        earningList: {
          data: orderBy(
            action.payload.data,
            (a: Earning) => moment.utc(a.EarnedAtUTC),
            'desc'
          ),
          links: { ...action.payload.links }
        },
        earningFetched: true
      }
    case actionTypes.CLEAR_EARNING_LIST:
      return {
        ...state,
        earningList: {
          data: [...action.payload.data],
          links: { ...action.payload.links }
        }
      }
    case actionTypes.GET_USER_OFFER_STATUS:
      return {
        ...state,
        offerStatus: [...action.payload]
      }
    case actionTypes.CLEAR_USER_OFFER_STATUS:
      return {
        ...state,
        offerStatus: [...action.payload]
      }
    case actionTypes.GET_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: action.payload
      }
    case actionTypes.CLEAR_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: action.payload
      }
    case actionTypes.ADD_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: {
          data: state.transactionList.data.concat(orderBy(action.payload.data, (a: Transaction) => moment.utc(a.transactionDateUTC), 'desc')),
          links: { ...action.payload.links }
        }
      }

    case actionTypes.CLAIM_EARNING: {
      const copyEarning = { ...state.earningList }
      const copyTransaction = { ...state.transactionList }
      const earningIndex = copyEarning.data.findIndex(earning => earning.EarningID.toString() === action.payload.EarningID.toString())
      const transactionIndex = copyTransaction.data.findIndex(transaction => (
        transaction.transactionType === TransactionType.Earn && transaction.earning.EarningID.toString() === action.payload.EarningID.toString()
      ))

      if (earningIndex !== -1) {
        copyEarning.data[earningIndex] = { ...action.payload }
      }
      if (transactionIndex !== -1) {
        copyTransaction.data[transactionIndex].earning = { ...action.payload }
      }

      return {
        ...state,
        earningList: { ...copyEarning },
        transactionList: { ...copyTransaction }
      }
    }
    case actionTypes.CLAIM_ALL_EARNING: {
      const copyEarning = { ...state.earningList }
      const copyTransaction = { ...state.transactionList }
      let earningArray: string[] = []
      if (action.payload?.data != null) {
        earningArray = Object.values(action.payload.data)
      }
      earningArray.forEach((value: any) => {
        const earningIndex = copyEarning.data.findIndex(
          earning => earning.EarningID.toString() === value.EarningID.toString()
        )
        const transactionIndex = copyTransaction.data.findIndex(
          transaction =>
            transaction.transactionType === TransactionType.Earn &&
            transaction.earning.EarningID.toString() ===
              value.EarningID.toString()
        )
        if (earningIndex !== -1) {
          copyEarning.data[earningIndex] = { ...value }
        }
        if (transactionIndex !== -1) {
          copyTransaction.data[transactionIndex].earning = {
            ...value
          }
        }
      })
      return {
        ...state,
        earningList: { ...copyEarning },
        transactionList: { ...copyTransaction }
      }
    }
    case actionTypes.GET_POINTS_FROM_REFEREE: {
      return {
        ...state,
        pointsFromReferee: action.payload.value
      }
    }
    case actionTypes.HAVE_SEEN_NEW_FEATURE:
      return {
        ...state,
        HaveSeenNewFeature: action.payload
      }
    case actionTypes.GET_NEW_FEATURE_CONFIG:
      return {
        ...state,
        NewFeatureConfig: [...action.payload]
      }
    case actionTypes.SET_IS_REFRESH_ANIMATION_COUNTER:
      return {
        ...state,
        isRefreshCounter: action.payload
      }
    case actionTypes.GET_FAVORITE_OFFER_COUNT:
      return {
        ...state,
        favoriteOfferCount: action.payload
      }
    case actionTypes.SET_HAS_JUST_LOGGED_IN:
      return {
        ...state,
        hasJustLoggedIn: action.payload
      }
    case actionTypes.SET_IS_DATA_LOADED:
      return {
        ...state,
        isDataLoaded: action.payload
      }
    case actionTypes.SET_USER_REACTIVATION_STATUS:
      return {
        ...state,
        showReactivationBonusDialog: action.payload.ShowReactivationBonusDialog,
        isUserInactive: action.payload.isUserInactive,
        Balance: action.payload.balance
      }
  }
  return state
}

export default reducer
