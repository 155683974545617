export enum AnalyticsCategory {
  Home = 'Home',
  Menu = 'Menu',
  SignIn = 'SignIn',
  Error = 'Error',
  Policy = 'Policy',
  SignUp = 'SignUp',
  UserAccount = 'UserAccount',
  ResetPassword = 'ResetPassword',
  Campaigns = 'Campaigns',
  Redemption = 'Redemption',
  Redemptions = 'Redemptions',
  PhoneVerification = 'Phone Verification',
  PTH = 'PTH',
  Referral = 'Referral'
}
