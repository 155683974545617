import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import React, { ReactElement, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from '../../config/selectors'
import './ReactivationBonusDialog.scss'
import { useTracking } from 'react-tracking'

interface ReactivationBonusDialogProps {
  open: boolean
  handleClose: () => void
}

const ReactivationBonusDialog = ({
  open,
  handleClose
}: ReactivationBonusDialogProps): ReactElement => {
  const config: ConfigState = useSelector(selectConfig)
  const { trackEvent } = useTracking()

  useEffect(() => {
    trackEvent({
      page: 'Reactivation',
      action: 'View Reactivation Dialog'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      className='reactivation-bonus-dialog'
    >
      <DialogTitle className='title'>{config.ReactivationTitle}</DialogTitle>
      <DialogContent className='content'>
        {config.ReactivationImageURL != null && (
          <div className='dialog-img'>
            <img src={config.ReactivationImageURL} alt='reactivation' />
          </div>
        )}
        <p>{config.ReactivationDescription}</p>
      </DialogContent>
      <DialogActions className='actions'>
        <Button id='cta-btn' className='btn dialog-btn' onClick={handleClose}>
          {config.ReactivationButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReactivationBonusDialog
