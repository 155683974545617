import React, { FC } from 'react'
import TimerIcon from '@material-ui/icons/Timer'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import BadgeIcon from '../../ui/customIcons/BadgeIcon/BadgeIcon'
import { CampaignStatusList } from '../campaignStatus'

interface CampaignIcoProp {
  campaignStatus: CampaignStatus
  handleBadge: () => void
  shadow?: boolean
  campaignLocked: boolean
  className?: string
}

const CampaignIco: FC<CampaignIcoProp> = ({ campaignStatus, handleBadge, campaignLocked = false, shadow = false, className = '' }) => {
  if (campaignStatus.status === CampaignStatusList.Locked || campaignLocked) {
    return <BadgeIcon icon={LockOutlinedIcon} clickBtn={handleBadge} shadow={shadow} className={className} />
  } else if (campaignStatus.status === CampaignStatusList.TimeLimited) {
    return <BadgeIcon icon={TimerIcon} clickBtn={handleBadge} shadow={shadow} className={className} />
  }

  return (
    <>
    </>
  )
}

export default CampaignIco
