export enum RoutePath {
  Home = '/',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Terms = '/terms',
  Invite = '/reward_code_screen',
  Refer = '/referral_page',
  Redemption = '/redemption',
  OptedOut = '/opted-out',
  Suspended = '/suspended',
  ConnectAccount = '/app_connection',
  PointHistory = '/point_history',
  Tutorial = '/tutorial',
  ManageAccount = '/manage-account',
  PasswordReset = '/password-reset',
  ErrorPage = '*',
  EarnUpdated = '/earn',
  RedeemUpdated = '/redeem',
  AddToHomescreenInstructions = '/add-to-homescreen-instructions'
}
