import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import lang_EN from './en/en.json'
import lang_ES from './es/es.json'
import lang_RU from './ru/ru.json'
import lang_VI from './vi/vi.json'
import lang_ZH_CN from './cn/zh_cn.json'
import lang_KO from './ko/ko.json'
import lang_AR from './ar/ar.json'
import lang_BN from './bn/bn.json'

export const DEFAULT_LANGUAGE_CODE = 'en'
export const DEFAULT_LANGUAGE_NAME = 'English'

const resources = {
  en: {
    translation: lang_EN
  },
  es: {
    translation: lang_ES
  },
  ru: {
    translation: lang_RU
  },
  vi: {
    translation: lang_VI
  },
  ko: {
    translation: lang_KO
  },
  'zh-CN': {
    translation: lang_ZH_CN
  },
  ar: {
    translation: lang_AR
  },
  bn: {
    translation: lang_BN
  }
}
const init = async (): Promise<any> => {
  const lang = localStorage.getItem('i18nextLng') ?? DEFAULT_LANGUAGE_CODE
  return await i18n.use(initReactI18next).init({
    resources,
    lng: lang,
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    interpolation: {
      escapeValue: false
    }
  })
}

export default init
