import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Skeleton } from '@material-ui/lab'
import { Dispatch } from 'redux'
import ProviderList from '../ProviderList/ProviderList'
import { selectIsActionLoaded } from '../../config/selectors'
import { GET_PROVIDER_LIST } from '../actionTypes'
import { useHistory } from 'react-router-dom'
import './ConnectAccount.scss'
import { selectProviderList } from '../selectors'
import { changeTab } from '../../config/actions'
import { TabsName } from '../../core/tabsName'
import { RoutePath } from '../../core/routes/route-path'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import { BACK_BTN_MARGIN_TOP } from '../../core/utils/constants'

const ConnectAccount: FC = () => {
  const { t } = useTranslation()
  const isProviderLoaded: boolean = useSelector(selectIsActionLoaded(GET_PROVIDER_LIST))
  const providerList: ProviderAccount[] = useSelector(selectProviderList)
  const dispatch: Dispatch<UserAction> = useDispatch()
  const history = useHistory()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  useEffect(() => {
    if (isProviderLoaded && providerList.length <= 0) {
      history.push(RoutePath.Home)
      dispatch(changeTab(TabsName.Earn))
    }
  }, [providerList, isProviderLoaded, dispatch, history])

  return (
    <div
      className='connect-account'
      style={{ marginTop: matchesMd ? BACK_BTN_MARGIN_TOP : '' }}
    >
      {isProviderLoaded
        ? <div className='connect-account__title'>{t`ConnectAccounts`}</div>
        : <Skeleton variant='rect' classes={{ root: 'connect-account--loading-title' }} />}

      <div className='connect-account__provider-list'>
        <ProviderList isProviderLoaded={isProviderLoaded} />
      </div>
    </div>
  )
}

export default ConnectAccount
