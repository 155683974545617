import { combineReducers, Reducer } from 'redux'

import authReducer from '../../auth/reducers'
import userReducer from '../../user/reducers'
import configReducer from '../../config/reducers'
import CampaignReducer from '../../campaign/reducers'
import UpdateCardReducer from '../../updateCards/reducers'
import OfferReducer from '../../offer/reducers'
import ProviderReducer from '../../provider/reducers'
import TutorialReducer from '../../tutorial/reducers'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'tutorial', 'user', 'config']
}

const uiPersistConfig = {
  key: 'config',
  storage: storage,
  blacklist: ['ui']
}

export default persistReducer(
  persistConfig,
  combineReducers<ApplicationState>({
    auth: authReducer,
    user: userReducer,
    config: persistReducer<ConfigState, ConfigAction>(
      uiPersistConfig,
      configReducer
    ) as unknown as Reducer<ConfigState, any>,
    campaign: CampaignReducer,
    updateCard: UpdateCardReducer,
    offer: OfferReducer,
    provider: ProviderReducer,
    tutorial: TutorialReducer
  })
)
