import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Skeleton } from '@material-ui/lab'
import { useMediaQuery } from '@react-hook/media-query'

import { selectCampaignListByCategory } from '../../selectors'
import CampaignListCategory from './CampaignListCategory/CampaignListCategory'
import { selectIsActionLoaded } from '../../../config/selectors'
import { GET_CAMPAIGN_CATEGORIES, GET_CAMPAIGN_LIST } from '../../actionTypes'
import { breakpoints } from '../../../core/utils/css-selectors'
import { selectUser } from '../../../user/selectors'

import './CampaignList.scss'

interface CampaignListProps {
  featuredCampaignsDetailsList?: Campaign[]
}

const CampaignList = ({
  featuredCampaignsDetailsList = []
}: CampaignListProps): ReactElement => {
  const isDesktop = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const campaignCategoryList: CampaignListByCategory[] = useSelector(
    selectCampaignListByCategory
  )
  const isCampaignLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_CAMPAIGN_LIST)
  )
  const isCampaignCategoryLoaded: boolean = useSelector(
    selectIsActionLoaded(GET_CAMPAIGN_CATEGORIES)
  )
  const { featureFlags }: UserState = useSelector(selectUser)
  const maxRowCampaignsMd = 1
  const maxRowCampaignsXs = 3
  const maxColumnCampaignsMd = 3
  const maxColumnCampaignsXs = 2
  const [amountMax, setAmountMax] = useState<number>(maxRowCampaignsXs)
  const [hiddenCategoryIDs, setHiddenCategoryIDs] = useState<number[]>([])
  let categoryUIIndex = 0

  useEffect(() => {
    isDesktop ? setAmountMax(maxRowCampaignsMd) : setAmountMax(maxRowCampaignsXs)
  }, [isDesktop])

  useEffect(() => {
    if (featureFlags.UX_UPDATES) {
      const categoriesToHide: number[] = []
      campaignCategoryList?.forEach((category: CampaignListByCategory) => {
        if (category.campaignList.length > 0) {
          const nonFeaturedCampaigns = category.campaignList.filter(
            (campaignItem) =>
              !featuredCampaignsDetailsList.some(
                (featuredCampaignItem) =>
                  campaignItem.CampaignID === featuredCampaignItem.CampaignID
              )
          )
          if (nonFeaturedCampaigns.length < 1) {
            categoriesToHide.push(category.CampaignCategoryID)
          }
        }
      })
      setHiddenCategoryIDs([...categoriesToHide])
    }
  }, [
    campaignCategoryList,
    featureFlags.UX_UPDATES,
    featuredCampaignsDetailsList,
    isDesktop
  ])

  return (
    <div className='campaign-list-cover'>
      {isCampaignLoaded && isCampaignCategoryLoaded
        ? (campaignCategoryList.map((campaignCategory: CampaignListByCategory, i) => (
          campaignCategory.campaignList.length > 0 &&
          !hiddenCategoryIDs.includes(campaignCategory.CampaignCategoryID) &&
            <CampaignListCategory
              key={i}
              campaignCategory={campaignCategory}
              categoryIndex={categoryUIIndex++}
              featuredCampaignsDetailsList={featuredCampaignsDetailsList ?? []}
            />
        )))
        : (
          <div className='campaign-list--loading'>
            {new Array(amountMax).fill(0).map((_, item) => (
              <div className='campaign-list--loading__wrapper' key={item}>
                <Skeleton variant='rect' classes={{ root: 'campaign-list--loading__category-title skeleton-txt' }} />
                <div className='campaign-list--loading__description'>
                  {new Array(isDesktop ? maxColumnCampaignsMd : maxColumnCampaignsXs).fill(0).map((_, i) => (
                    <div className='campaign-list--loading__description__item' key={i}>
                      <Skeleton variant='rect' classes={{ root: 'campaign-list--loading__description__item__img' }} />
                      <Skeleton variant='rect' classes={{ root: 'campaign-list--loading__description__item__title skeleton-txt--default' }} />
                      <Skeleton variant='rect' classes={{ root: 'campaign-list--loading__description__item__subtitle skeleton-txt--default' }} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
    </div>
  )
}

export default CampaignList
