import React, { FC } from 'react'
import Lottie from 'react-lottie'

import { defaultOptions } from './defaultOptions'

interface SpinnerProps {
  height?: number
  width?: number
  isStopped?: boolean
  isPaused?: boolean
}

const Spinner: FC<SpinnerProps> = ({
  height = 100,
  width = 100,
  isStopped = false,
  isPaused = false
}) => {
  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
      isStopped={isStopped}
      isPaused={isPaused}
    />
  )
}

export default Spinner
