import React, { FC } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { RoutePath } from './route-path'
import { selectAuth } from '../../auth/selectors'
import { selectIsActionLoaded } from '../../config/selectors'
import { GET_CONFIG_INFO } from '../../config/actionTypes'

const TermsRoute: FC<any> = ({ component, isAuth, ...rest }: any) => {
  const auth: AuthState = useSelector(selectAuth)
  const isConfigLoaded: boolean = useSelector(selectIsActionLoaded(GET_CONFIG_INFO))

  const routeComponent: FC = (props: any) => (
    isConfigLoaded
      ? auth.isAuth
        ? React.createElement(component, props)
        : (
          <Redirect to={{
            pathname: RoutePath.SignIn,
            state: { from: props.location }
          }}
          />
        )
      : <></>
  )

  return <Route {...rest} render={routeComponent} />
}

export default TermsRoute
