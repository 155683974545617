import { TransactionType } from './transactionType'

export const DEDUCTION_TYPES: string[] = [
  TransactionType.BalanceDeduction,
  TransactionType.SuspensionDeduction,
  TransactionType.ExpiryDeduction
]

export const BALANCE_ANIMATION_COUNT_START = 0
export const BALANCE_ANIMATION_LIMIT_CHECK = 10000
export const BALANCE_ANIMATION_MIN_DURATION = 3
export const BALANCE_ANIMATION_MAX_DURATION = 4
