import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import store from '../redux/store'
import initInterceptor from './interceptorFunc'

const Interceptor: FC = ({ children }) => {
  const history = useHistory()

  useEffect(() => {
    initInterceptor(store, history)
  }, [history])

  return <>{children}</>
}

export default Interceptor
