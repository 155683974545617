import React, { ReactElement } from 'react'
import { Button, Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import './RedemptionErrorDialog.scss'
import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'

interface RedemptionErrorDialogProps {
  open: boolean
  handleClose: () => void
  isFromUpdatedUI?: boolean
  offerImage?: string
  isFromUpdatedDesktopUI?: boolean
}

const RedemptionErrorDialog = ({
  open,
  handleClose,
  isFromUpdatedUI = false,
  offerImage = '',
  isFromUpdatedDesktopUI = false
}: RedemptionErrorDialogProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      {isFromUpdatedUI || isFromUpdatedDesktopUI ? (
        <BaseDrawer
          isOpen={open}
          onClose={handleClose}
          dialogClassName='offer-detail-new-drawer'
          noPadding
          isFromUpdatedUI={isFromUpdatedUI}
          isFromUpdatedDesktopUI={isFromUpdatedDesktopUI}
          isFromRedemptionError={isFromUpdatedDesktopUI}
        >
          <div
            className={`redemption-error-new ${
              isFromUpdatedDesktopUI ? 'redemption-error-updated' : ''
            }`}
          >
            <div
              style={{ backgroundImage: `url(${offerImage})` }}
              className={`redemption-error-new-img ${
                isFromUpdatedDesktopUI ? 'img-updated' : ''
              }`}
            />

            <div
              className={`redemption-error-new-details ${
                isFromUpdatedDesktopUI ? 'details-updated' : ''
              }`}
            >
              <ErrorOutlineOutlinedIcon
                className='info-icon'
                color='error'
                fontSize='large'
              />
              <div className='title'>{t`RedemptionFailed`}</div>
            </div>
            <div
              className={`redemption-error-new__btn ${
                isFromUpdatedDesktopUI ? 'btn-updated' : ''
              }`}
            >
              <Button
                id='redemption-error-dialog-button'
                className='btn dialog-btn'
                onClick={handleClose}
              >
                {t`Ok`}
              </Button>
            </div>
          </div>
        </BaseDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          className='redemption-error-dialog'
          maxWidth='xs'
        >
          <ErrorOutlineOutlinedIcon
            className='info-icon'
            color='error'
            fontSize='large'
          />
          <DialogTitle className='title'>{t`RedemptionFailed`}</DialogTitle>
          <DialogActions>
            <Button
              id='redemption-error-dialog-button'
              className='btn dialog-btn'
              onClick={handleClose}
            >
              {t`Ok`}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default RedemptionErrorDialog
