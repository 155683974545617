import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignProviderWithStatusCheck } from '../../../campaign/selectors'
import { selectProviderStatus } from '../../selectors'
import ProviderDialog from '../ProviderDialog/ProviderDialog'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../../core/utils/css-selectors'
import { providerLabelTemplate } from '../../../core/utils/providerLabelTemplate'
import ResultDialog from '../ResultDialog/ResultDialog'

interface OpenProviderDialogProps {
  isConnectDialogOpen: boolean
  setIsConnectDialogOpen: (isConnectDialogOpen: boolean) => void
  campaignItem: Campaign
}

const OpenProviderDialog: FC<OpenProviderDialogProps> = ({
  isConnectDialogOpen,
  setIsConnectDialogOpen,
  campaignItem
}) => {
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const [isSpinner, setIsSpinner] = useState<boolean>(true)
  const providerCampaign: ProviderAccount | undefined = useSelector(
    selectCampaignProviderWithStatusCheck(campaignItem.providers)
  )
  const providerCampaignStatus: ProviderStatus | undefined = useSelector(
    selectProviderStatus(providerCampaign?.ProviderID)
  )
  const [isResultOpen, setIsResultOpen] = useState<boolean>(false)

  const showSpinnerCampaign = (): void => {
    setIsConnectDialogOpen(false)
    setIsSpinner(true)
    setIsResultOpen(true)
  }

  const showMessageCampaign = (
    providerCampaignStatus?: ProviderStatus
  ): void => {
    if (providerCampaignStatus != null) {
      setIsSpinner(false)
    } else {
      setIsResultOpen(false)
      setIsSpinner(true)
      setIsConnectDialogOpen(true)
    }
  }

  const handleResultClose = (flag: boolean): void => {
    setIsSpinner(true)
    setIsResultOpen(flag)
  }

  return (
    <>
      {providerCampaign !== undefined && (
        <>
          <ProviderDialog
            isConnectDialogOpen={isConnectDialogOpen}
            setIsConnectDialogOpen={setIsConnectDialogOpen}
            provider={providerCampaign}
            handleSubmitStart={showSpinnerCampaign}
            handleSubmit={showMessageCampaign}
            identifier={
              providerCampaignStatus !== undefined
                ? providerCampaignStatus.identifier
                : ''
            }
            isFromProviderItem={matchesMd}
          />

          <ResultDialog
            isOpen={isResultOpen}
            setIsOpen={handleResultClose}
            isSpinner={isSpinner}
            providerStatus={providerCampaignStatus}
            label={
              providerCampaign.translations?.fullName != null
                ? providerLabelTemplate(
                  providerCampaign.Label,
                  providerCampaign.translations.fullName
                )
                : ''
            }
          />
        </>
      )}
    </>
  )
}

export default OpenProviderDialog
