import React, { FC } from 'react'
import track from 'react-tracking'

import { analytics } from './analytics'

const TrackAnalytics: FC = ({ children }) => {
  return <>{children}</>
}

export default track({}, {
  dispatch (data: Trackables): any {
    analytics.track(data.action, data).then(
      () => {},
      () => {}
    )
  }
})(TrackAnalytics)
