export const initialState: TutorialState = {
  currentStep: 1,
  currentStepTitle: '',
  campaignDrawerOpenForTutorial: false,
  tutorialOn: false,
  moreTabDrawerOpenForTutorial: false
}

export const defaultAction: TutorialStepAction = {
  type: '',
  payload: 1
}
