import * as actionTypes from './actionTypes'
import { initialState, defaultAction } from './initialState'

const reducer = (
  state: UpdateCardState = initialState,
  action: UpdateCardAction = defaultAction
): UpdateCardState => {
  switch (action.type) {
    case actionTypes.GET_UPDATE_CARD_LIST:
      return {
        ...state,
        updateCardList: action.payload.map((updateCard: UpdateCard) => ({
          ...updateCard
        }))
      }
    case actionTypes.CLEAR_UPDATE_CARD_LIST:
      return {
        ...state,
        updateCardList: action.payload
      }
  }
  return state
}

export default reducer
