export const ReferralActionType = {
  PHONENUMBER: 'PhoneNumber',
  OFFER: 'Offer',
  CAMPAIGN: 'Campaign'
}

export const REFERRER = 'Referrer'

export const REFERRAL = 'REFERRAL'
export const PROMOTIONAL = 'PROMOTIONAL'

export const EVENT_TIME_FRAME_ERROR =
  'Referral Error Timeframe Limit'
export const EVENT_LIFE_TIME_ERROR =
  'Referral Error Lifetime Limit'
export const REFERRAL_LIMIT_PARTIAL_ERROR_MESSAGE = 'This code has reached'
export const EVENT_TIME_FRAME_ERROR_CODE = 31002
export const EVENT_LIFE_TIME_ERROR_CODE = 31001
export const INVITE_FORM_DRAWERS_CUSTOM_MARGIN = '60px'
