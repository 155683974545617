import React, { FC } from 'react'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { Tab, Tabs } from '@material-ui/core'
import { useTracking } from 'react-tracking'

import { IdList } from '../../../core/utils/id-list'
import { TabsName } from '../../../core/tabsName'
import { changeTab } from '../../../config/actions'
import { selectActiveTab } from '../../../config/selectors'
import { RoutePath } from '../../../core/routes/route-path'

import './NavigationTabs.scss'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

interface NavigationTabsProps {
  onMore: () => void
}

const NavigationTabs: FC<NavigationTabsProps> = ({ onMore }) => {
  const dispatch: Dispatch<ConfigTabAction> = useDispatch()
  const { trackEvent } = useTracking()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const activeTab = useSelector(selectActiveTab)

  const handleClickMore = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu More'
    })
    onMore()
  }

  const handleChange = (event: React.ChangeEvent<{}>, newTab: TabsName): void => {
    if (newTab !== TabsName.More) {
      dispatch(changeTab(newTab))
    }
  }

  const handleTab = (): void => {
    if (location.pathname !== RoutePath.Home) {
      history.push(RoutePath.Home)
    }
  }

  const handleRedeemTab = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Redeem'
    })
    handleTab()
  }

  const handleEarnTab = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Earn'
    })
    handleTab()
  }

  return (
    <>
      <Tabs
        className='navigation-tabs'
        value={activeTab !== '' && activeTab}
        variant='fullWidth'
        onChange={handleChange}
      >
        <Tab
          id={IdList.tabEarn}
          className='navigation-tabs__tab'
          label={t`Earn`}
          value={TabsName.Earn}
          disableRipple
          onClick={handleEarnTab}
        />
        <Tab
          id={IdList.tabRedeem}
          className='navigation-tabs__tab'
          label={t`Redeem`}
          value={TabsName.Redeem}
          disableRipple
          onClick={handleRedeemTab}
        />
        <Tab
          id={IdList.tabMore}
          className='navigation-tabs__tab'
          label={t`More`}
          value={TabsName.More}
          disableRipple
          onClick={handleClickMore}
        />
      </Tabs>
    </>
  )
}

export default NavigationTabs
