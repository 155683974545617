import { Dispatch } from 'redux'

import * as actionTypes from './actionTypes'
import { offerService } from './service'
import { setLoaded } from '../config/actions'

export const getOfferList: any = () => async (dispatch: Dispatch<OfferListAction | LoadedAction>): Promise<OfferListAction> => {
  return await offerService.getOfferList()
    .then((data) => dispatch(setOfferList(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_OFFER_LIST)))
}

export const getOffersHiddenList: any = (missingOfferIDList: string[]) => async (dispatch: Dispatch<OfferListAction | LoadedAction>): Promise<OfferListAction> => {
  return await Promise.all(missingOfferIDList.map(async offerID => (await offerService.getOffer(offerID))))
    .then((data: any) => dispatch(setOfferHiddenList(data)))
}

export const clearOfferList: any = () => async (dispatch: Dispatch<OfferListAction>): Promise<OfferListAction> => {
  return dispatch(setClearOfferList())
}

export const redeemOffer: any = (OfferID: string) => async (dispatch: Dispatch<OfferListAction>): Promise<Redemption> => {
  return await offerService.redeemOffer(OfferID)
}

export const getOfferCategories: any = () => async (dispatch: Dispatch<OfferCategoriesListAction | LoadedAction>): Promise<OfferCategoriesListAction> => {
  return await offerService.getOfferCategories()
    .then((data) => dispatch(setOfferCategories(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_OFFER_CATEGORIES)))
}

export const setFavoriteOffer: any = (OfferID: string, isFavorite: boolean) => async (): Promise<void> => {
  return await offerService.setFavoriteOffer(OfferID, isFavorite)
}

const setOfferList = (payload: Offer[]): OfferListAction => ({
  type: actionTypes.GET_OFFER_LIST,
  payload
})

const setClearOfferList = (): OfferListAction => ({
  type: actionTypes.CLEAR_OFFER_LIST,
  payload: []
})

const setOfferHiddenList = (payload: Offer[]): OfferListAction => ({
  type: actionTypes.GET_OFFER_HIDDEN_LIST,
  payload
})

const setOfferCategories = (payload: OfferCategory[]): OfferCategoriesListAction => ({
  type: actionTypes.GET_OFFER_CATEGORIES,
  payload
})
