export const isEmail = (identifierField: string): boolean => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(identifierField)) {
    return true
  }
  return false
}

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const areaPattern = new RegExp(/^(\d)(?!\1+$)\d{2}$/)
  if (phoneNumber.length >= 3) {
    const areaCode = phoneNumber.substr(0, 3)
    return (areaPattern).test(areaCode)
  }

  return true
}
