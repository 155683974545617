export const initialFeatureFlags: FeatureFlags = {
  FAVORITE_OFFER: false,
  UX_UPDATES: false
}

export const initialState: UserState = {
  Balance: undefined,
  earningsCount: 0,
  redeemsCount: 0,
  userStatus: undefined,
  UserID: undefined,
  earningList: {
    data: [],
    links: {
      next: '',
      previous: '',
      self: ''
    }
  },
  earningFetched: false,
  offerStatus: [],
  transactionList: {
    data: [],
    links: {
      next: '',
      previous: '',
      self: ''
    }
  },
  usedInviteCodes: [],
  pointsFromReferee: 0,
  HaveSeenNewFeature: false,
  NewFeatureConfig: [],
  name: '',
  email: '',
  isRefreshCounter: false,
  featureFlags: initialFeatureFlags,
  favoriteOfferCount: 0,
  hasJustLoggedIn: false,
  showReactivationBonusDialog: false,
  isDataLoaded: false,
  isUserInactive: false
}

export const defaultAction: UserAction = {
  type: '',
  payload: {
    UserID: undefined,
    Balance: undefined,
    userStatus: undefined
  }
}
