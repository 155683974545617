import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Stars, EmojiEvents } from '@material-ui/icons'

import SectionTitle from '../../SectionTitle/SectionTitle'
import { selectUser } from '../../user/selectors'
import { selectCampaignFeaturedList, selectCampaignList } from '../selectors'
import CampaignCard from '../Card/Campaign'
import {
  CampaignTypes,
  FEATURED_CAMPAIGNS_LIST_LIMIT
} from '../CampaignConstants'

import './UpdatedCampaigns.scss'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import _ from 'lodash'

const ALLOWED_CAMPAIGN_TYPES = [
  CampaignTypes.ACTION,
  CampaignTypes.EXTERNAL_SURVEY,
  CampaignTypes.TRANSACTIONAL,
  CampaignTypes.LOCATION,
  CampaignTypes.MULTI_TRIP,
  CampaignTypes.STREAK,
  CampaignTypes.REDEMPTION,
  CampaignTypes.TIER,
  CampaignTypes.CONNECT
]

const UpdatedCampaigns: FC = () => {
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)
  const { featureFlags }: UserState = useSelector(selectUser)
  const featuredCampaignIds = useSelector(selectCampaignFeaturedList).map(
    (c) => c.campaignId
  )
  const campaignList = useSelector(selectCampaignList)

  const [featuredCampaigns, excludedFeaturedCampaignIds] = useMemo(() => {
    const filteredCampaigns = campaignList.filter((c) =>
      featuredCampaignIds.includes(c.CampaignID)
    )
    const featured = filteredCampaigns.slice(0, FEATURED_CAMPAIGNS_LIST_LIMIT)
    const excluded = filteredCampaigns
      .slice(FEATURED_CAMPAIGNS_LIST_LIMIT)
      .map((c) => c.CampaignID)
    return [featured, excluded]
  }, [featuredCampaignIds, campaignList])

  const unfeaturedCampaigns = useMemo(() => {
    return campaignList.filter(
      (c) =>
        (!featuredCampaignIds.includes(c.CampaignID) ||
          excludedFeaturedCampaignIds.includes(c.CampaignID)) &&
        ALLOWED_CAMPAIGN_TYPES.includes(c.CampaignType as string)
    )
  }, [featuredCampaignIds, excludedFeaturedCampaignIds, campaignList])

  const hasFeaturedCampaigns =
    featureFlags.UX_UPDATES && featuredCampaigns.length > 0

  return (
    <div className='updated-campaigns-container'>
      <div>
        {hasFeaturedCampaigns && (
          <>
            <div className='updated-campaigns-title'>
              <SectionTitle title={t`FeaturedCampaigns`} Icon={Stars} />
            </div>
            <div className='updated-campaigns updated-featured-campaigns'>
              {featuredCampaigns.map((campaign, index) => (
                <CampaignCard
                  key={campaign.CampaignID}
                  campaignItem={campaign}
                  indexing={index}
                  containerClassName={`updated-featured-campaign ${
                    !matchesMd &&
                    campaign.CampaignType === CampaignTypes.TIER &&
                    !_.isEmpty(campaign.SubHeader) &&
                    !_.isEmpty(campaign.SubTitle)
                      ? 'h-200'
                      : ''
                  }`}
                  imgClassName='updated-featured-campaign-img'
                  campaignDetailsClassName='updated-campaign-details'
                />
              ))}
            </div>
          </>
        )}
      </div>

      <div>
        {featureFlags.UX_UPDATES && unfeaturedCampaigns.length > 0 && (
          <>
            <div className='updated-campaigns-title'>
              <SectionTitle title={t`Campaigns`} Icon={EmojiEvents} />
            </div>
            <div className='updated-campaigns updated-unfeatured-campaigns'>
              {unfeaturedCampaigns.map((campaign, index) => (
                <CampaignCard
                  key={campaign.CampaignID}
                  campaignItem={campaign}
                  indexing={index}
                  containerClassName='updated-campaign'
                  imgClassName='updated-campaign-img'
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UpdatedCampaigns
