export const PROCESS_NEXT = 'PROCESS_NEXT'

export const SET_CURRENT_STEP_TITLE = 'SET_CURRENT_STEP_TITLE'

export const GET_CURRENT_STEP = 'GET_CURRENT_STEP'

export const SET_DRAWER_OPEN_FOR_TUTORIAL = 'SET_DRAWER_OPEN_FOR_TUTORIAL'

export const SET_TUTORIAL_ON = 'SET_TUTORIAL_ON'

export const SET_MORE_TAB_OPEN_FOR_TUTORIAL = 'SET_MORE_TAB_OPEN_FOR_TUTORIAL'
