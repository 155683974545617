import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUser } from '../../selectors'
import { selectConfig } from '../../../config/selectors'
import CountUp from 'react-countup'
import './BalanceInfo.scss'
import {
  BALANCE_ANIMATION_COUNT_START,
  BALANCE_ANIMATION_LIMIT_CHECK,
  BALANCE_ANIMATION_MAX_DURATION,
  BALANCE_ANIMATION_MIN_DURATION
} from '../../userConstants'
interface BalanceInfoProp {
  isFromUpdatedUI?: boolean
}
const BalanceInfo: FC<BalanceInfoProp> = ({
  isFromUpdatedUI = false
}) => {
  const { t } = useTranslation()
  const user: UserState = useSelector(selectUser)
  const config: ConfigState = useSelector(selectConfig)

  const [userPoints, setUserPoints] = useState<number>(0)
  const [resetCount, setResetCount] = useState(0)

  useEffect(() => {
    let points = 0
    if (user.Balance !== null && user.Balance !== undefined && user.Balance !== '') {
      points = parseInt(user.Balance)
    }
    setUserPoints(points)
  }, [user.Balance])

  useEffect(() => {
    setResetCount((prevResetCount) => prevResetCount + 1)
  }, [user.isRefreshCounter])

  const getPointsField = (): string => {
    if (isFromUpdatedUI) {
      if (config.WordsForPoints.short != null) {
        return t([config.WordsForPoints.short])
      }
    } else {
      if (config.WordsForPoints.long != null) {
        return t([config.WordsForPoints.long])
      }
    }
    return isFromUpdatedUI ? t`pts` : t`points`
  }
  return (
    <div className='balance__info'>
      {!isFromUpdatedUI && <span className='balance__info__txt'>{t`Balance`}:</span>}
      <div className='flex align-center'>
        <span className='balance__info__points'>
          <CountUp
            key={resetCount}
            start={BALANCE_ANIMATION_COUNT_START}
            end={userPoints}
            duration={
              userPoints < BALANCE_ANIMATION_LIMIT_CHECK
                ? BALANCE_ANIMATION_MIN_DURATION
                : BALANCE_ANIMATION_MAX_DURATION
            }
          />
        </span>
        <span className='balance__info__txt'>
          {getPointsField()}
        </span>
      </div>
    </div>
  )
}

export default BalanceInfo
