import * as actionTypes from './actionTypes'
import { initialState, defaultAction } from './initialState'

const reducer = (state: TutorialState = initialState, action: TutorialStepAction = defaultAction): TutorialState => {
  switch (action.type) {
    case actionTypes.PROCESS_NEXT:
      return {
        ...state,
        currentStep: action.payload
      }
    case actionTypes.SET_CURRENT_STEP_TITLE:
      return {
        ...state,
        currentStepTitle: action.payload
      }
    case actionTypes.SET_DRAWER_OPEN_FOR_TUTORIAL:
      return {
        ...state,
        campaignDrawerOpenForTutorial: action.payload
      }
    case actionTypes.SET_MORE_TAB_OPEN_FOR_TUTORIAL:
      return {
        ...state,
        moreTabDrawerOpenForTutorial: action.payload
      }
    case actionTypes.SET_TUTORIAL_ON:
      return {
        ...state,
        tutorialOn: action.payload
      }
  }
  return state
}

export default reducer
