import React, { ReactElement } from 'react'
import {
  Box,
  FilledInputProps,
  FormHelperText,
  InputProps,
  OutlinedInputProps,
  TextField
} from '@material-ui/core'

interface FormTextFieldProps {
  id: string
  name: string
  value: string
  label: string
  onChange:
  | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  errorField?: string
  InputProps?:
  | Partial<InputProps>
  | Partial<FilledInputProps>
  | Partial<OutlinedInputProps>
  type?: string
}

const FormTextField = ({
  id,
  name,
  value,
  label,
  errorField,
  InputProps,
  type,
  onChange
}: FormTextFieldProps): ReactElement => {
  return (
    <Box>
      <TextField
        type={type}
        id={id}
        name={name}
        variant='filled'
        className='form-control field-responsive'
        value={value}
        onChange={onChange}
        label={label}
        InputLabelProps={{ className: 'lato label' }}
        InputProps={InputProps}
      />
      {errorField != null && (
        <FormHelperText error>{errorField}</FormHelperText>
      )}
    </Box>
  )
}

export default FormTextField
