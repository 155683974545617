import { Switch, SwitchProps, styled } from '@material-ui/core'
import React, { ReactElement } from 'react'

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(() => {
  return {
    marginLeft: '1em',
    width: 51,
    height: 31,
    padding: 0,
    marginTop: '.5em',
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(22px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#D7DFE5',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#fff',
        opacity: 0.5
      }
    },
    '& .MuiSwitch-thumb': {
      marginTop: '.08em',
      boxSizing: 'border-box',
      width: 24,
      height: 24
    },
    '& .MuiSwitch-track': {
      borderRadius: (33) / 2,
      backgroundColor: '#D7DFE5',
      opacity: 1
    }
  }
})

const CustomSwitch = ({
  defaultChecked,
  checked,
  onClick,
  color,
  size,
  disabled,
  inputProps
}: SwitchProps): ReactElement => {
  return (
    <StyledSwitch
      disabled={disabled}
      size={size}
      color={color}
      onClick={onClick}
      defaultChecked={defaultChecked}
      checked={checked}
      inputProps={inputProps}
    />
  )
}

export default CustomSwitch
