export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
export const RESET_SIGN_IN_ERROR = 'RESET_SIGN_IN_ERROR'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const RESET_PASSWORD_RESET_ERROR = 'RESET_PASSWORD_RESET_ERROR'
export const EDIT_ACCOUNT_INFO_ERROR = 'EDIT_ACCOUNT_INFO_ERROR'
export const RESET_EDIT_ACCOUNT_INFO_ERROR = 'RESET_EDIT_ACCOUNT_INFO_ERROR'
export const EDIT_ACCOUNT_PASSWORD_ERROR = 'EDIT_ACCOUNT_PASSWORD_ERROR'
export const RESET_EDIT_ACCOUNT_PASSWORD_ERROR = 'RESET_EDIT_ACCOUNT_PASSWORD_ERROR'
export const SET_HOME_PATH = 'SET_HOME_PATH'
