import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import React, { ReactElement } from 'react'
import './AddToHomescreenPrompt.scss'
import homescreenImage from '../../../assets/images/homescreen/homescreen-image.png'
import { useTranslation } from 'react-i18next'

interface AddToHomescreenPromptProps {
  open: boolean
  handleClose: () => void
  handleAddBtnClick: () => void
}

const AddToHomescreenPrompt = ({
  open,
  handleClose,
  handleAddBtnClick
}: AddToHomescreenPromptProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='xs'
      className='prompt-dialog'
    >
      <DialogTitle className='title'>{t`AddToHomescreenTitle`}</DialogTitle>

      <DialogContent className='content'>
        <div className='prompt-img'>
          <img src={homescreenImage} alt='homescreen' />
        </div>
        <p>{t`AddToHomescreenContent`}</p>
      </DialogContent>

      <DialogActions className='actions'>
        <Button
          id='not-now-btn'
          className='btn dialog-btn not-now'
          onClick={handleClose}
        >
          {t`NotNow`}
        </Button>
        <Button
          id='add-btn'
          className='btn dialog-btn add'
          onClick={handleAddBtnClick}
        >
          {t`Add`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddToHomescreenPrompt
