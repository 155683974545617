import { createSelector } from 'reselect'
import { selectCampaignList, selectCampaignListHidden } from '../campaign/selectors'
import { selectOfferList, selectOfferHiddenList } from '../offer/selectors'
import { selectConfig } from '../config/selectors'
import { defaultCampaign } from '../campaign/initialState'
import { DEDUCTION_TYPES } from './userConstants'

export const selectUser = (state: ApplicationState): UserState => state.user

export const selectEarningList = (state: ApplicationState): EarningList => state.user.earningList

export const selectPointsFromReferee = (state: ApplicationState): number => state.user.pointsFromReferee

export const selectIsUserEarningFetched = (state: ApplicationState): boolean => state.user.earningFetched

export const selectFavoriteOfferCount = (state: ApplicationState): number => state.user.favoriteOfferCount

export const selectEarningCampaignList = createSelector(
  [selectEarningList, selectCampaignList, selectCampaignListHidden, selectConfig],
  (earningList: EarningList, campaignList: Campaign[], campaignListHidden: Campaign[], config: ConfigState): LastEarning[] => {
    return earningList.data.reduce((lastEarnings: LastEarning[], earning: Earning) => {
      const campaign = [...campaignList, ...campaignListHidden].find((campaign) => campaign.CampaignID === earning.CampaignID)
      if (campaign != null) {
        lastEarnings.push({
          ...earning,
          campaign
        })
      } else {
        if (earning.CampaignID === null && !DEDUCTION_TYPES.includes(earning.type as string)) {
          const campaignForSignUp = defaultCampaign
          campaignForSignUp.Title = config.SignUpRewardEarningTitle
          lastEarnings.push({
            ...earning,
            campaign: campaignForSignUp
          })
        } else {
          lastEarnings.push({
            ...earning,
            campaign: null
          })
        }
      }
      return lastEarnings
    }, [])
  }
)

export const selectTransactionList = (state: ApplicationState): TransactionList => ({
  ...state.user.transactionList,
  data: state.user.transactionList.data
})

export const selectOfferStatusList = (state: ApplicationState): UserOfferStatus[] => state.user.offerStatus
export const selectOfferStatus: any = (offerID: string) => createSelector(
  [selectOfferStatusList],
  (offerStatusList: UserOfferStatus[]): UserOfferStatus | undefined => {
    return offerStatusList.find((offerStatus) => offerStatus.OfferID === offerID)
  }
)

export const selectOfferByTransaction: any = (transaction: Transaction) => createSelector(
  [selectOfferList, selectOfferHiddenList],
  (offerList: Offer[], offerHiddenList: Offer[]): Offer | undefined => {
    if (transaction.redemption !== undefined) {
      return [...offerList, ...offerHiddenList].find((offer) => offer.OfferID === transaction.redemption.OfferID)
    }
    return undefined
  }
)
