import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '../../core/routes/route-path'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import homescreenImage from '../../../assets/images/homescreen/homescreen-image.png'
import './AddToHomescreenInstructions.scss'
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined'
import { useTranslation } from 'react-i18next'

const AddToHomescreenInstructions = (): ReactElement => {
  const history = useHistory()
  const { t } = useTranslation()
  const [isDeviceIOS, setIsDeviceIOS] = useState<boolean>(false)
  const [isBrowserSafari, setIsBrowserSafari] = useState<boolean>(false)
  const handleBackBtnClick = (): void => history.push(RoutePath.Home)

  useEffect(() => {
    const userAgent = navigator.userAgent
    setIsDeviceIOS(/iPhone|iPad|iPod/i.test(userAgent))
    setIsBrowserSafari(
      /Safari/i.test(userAgent) &&
        !/Chrome/i.test(userAgent) &&
        !/CriOS/i.test(userAgent)
    )
  }, [])

  const customIcon = (
    <div className='icon'>
      <div className='arrow' />
    </div>
  )

  return (
    <div className='instructions-box'>
      <div className='back-box' onClick={handleBackBtnClick}>
        <ArrowBackIosIcon className='back-arrow' />
        <div className='back-btn'>{t`Back`}</div>
      </div>

      <div className='title'>
        <p>{t`AddToHomescreenTitle`}</p>
      </div>

      <div className='instructions-img'>
        <img src={homescreenImage} alt='homescreen' />
      </div>

      <div className='text-box'>
        {isDeviceIOS ? (
          isBrowserSafari ? (
            <div className='ios-safari'>
              <p>
                {t`InstructionIOSSafari`} <span>{customIcon}</span>
                {t`Then`}
                <b>{t`AddToHomescreen`}</b>
              </p>
            </div>
          ) : (
            <div className='ios-other'>
              <p>
                {t`InstructionIOSOther`}
                <span className='ml-05'>{customIcon}</span>{' '}
                <KeyboardArrowRightOutlinedIcon />
                <b>{t`AddToHomescreen`}</b>
              </p>
            </div>
          )
        ) : (
          <div className='os-other'>
            <p>
              {t`InstructionOSOther`}
              <b>{t`InstallApp`}</b>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddToHomescreenInstructions
