import Analytics from 'analytics'
import amplitudePlugin from '@analytics/amplitude'
import * as Sentry from '@sentry/react'
import { axiosInstance } from '../api/init'

const { REACT_APP_VERSION = '', REACT_APP_AMPLITUDE_KEY = '' } = process.env

export const analytics = Analytics({
  app: 'velocia-webapp',
  version: REACT_APP_VERSION,
  plugins: [
    {
      name: 'velocia-plugin',
      async track (analytics: AnalyticsTrack): Promise<any> {
        const { payload, payload: { properties } } = analytics

        return await axiosInstance.post<AnalyticsEvent>('/rest/events', {
          EventUID: payload.userId,
          Category: properties.page ?? properties.category,
          Action: properties.action,
          ReportedAtUTC: new Date().toISOString(),
          Properties: properties.payload === undefined ? {} : properties.payload
        })
      }
    },
    amplitudePlugin({
      apiKey: REACT_APP_AMPLITUDE_KEY.trim(),
      options: {
        onError: (error: Error) => {
          Sentry.captureException(error, {
            tags: {
              method: 'amplitudePlugin'
            }
          })
        }
      }
    })
  ]
})
