import * as actionTypes from './actionTypes'
import { initialState, defaultAction } from './initialState'

const reducer = (
  state: ConfigState = initialState,
  action: ConfigAction = defaultAction
): ConfigState => {
  switch (action.type) {
    case actionTypes.GET_CONFIG_INFO: {
      return {
        ...state,
        ...action.payload,
        WordsForPoints: { ...action.payload.WordsForPoints }
      }
    }
    case actionTypes.SET_APP_START_UTC:
      return {
        ...state,
        startAppUTC: action.payload
      }
    case actionTypes.CHANGE_TAB:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeTab: action.payload
        }
      }
    case actionTypes.SET_LOADED: {
      const copySetLoadUi = [...state.ui.loadedList]
      const hasSetLoadType = state.ui.loadedList.includes(action.payload)

      if (!hasSetLoadType) {
        copySetLoadUi.push(action.payload)
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          loadedList: [...copySetLoadUi]
        }
      }
    }
    case actionTypes.CLEAR_LOADED:
      return {
        ...state,
        ui: {
          ...state.ui,
          loadedList: state.ui.loadedList.filter((i) => i !== action.payload)
        }
      }

    case actionTypes.SET_WELCOME:
      return {
        ...state,
        ui: {
          ...state.ui,
          isWelcome: action.payload
        }
      }

    case actionTypes.SET_SIGNUP_WELCOME:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSignupWelcome: action.payload
        }
      }

    case actionTypes.SET_OFFLINE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isOffline: action.payload
        }
      }

    case actionTypes.SET_SHAKE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isShake: action.payload
        }
      }

    case actionTypes.SET_APP_ERROR:
      return {
        ...state,
        AppErrorType: action.payload
      }
  }
  return state
}

export default reducer
