import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Error from '../../ui/Error/Error'
import { selectConfig, selectIsActionLoaded } from '../../config/selectors'
import { GET_CONFIG_INFO } from '../../config/actionTypes'

const StandaloneRoute: FC<any> = ({ component, ...rest }: any) => {
  const config: ConfigState = useSelector(selectConfig)
  const isConfigLoaded: boolean = useSelector(selectIsActionLoaded(GET_CONFIG_INFO))

  const routeComponent: FC = (props: any) => (
    isConfigLoaded
      ? (config.IsStandalonModeSupported)
        ? React.createElement(component, props)
        : (
          <Error />
        )
      : <></>
  )
  return <Route {...rest} render={routeComponent} />
}

export default StandaloneRoute
