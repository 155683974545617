import { Dispatch } from 'redux'
import moment from 'moment'

import * as actionTypes from './actionTypes'
import { configService } from './service'

export const getConfigInfo: any = () => async (dispatch: Dispatch<ConfigInfoAction | LoadedAction>): Promise<ConfigInfoAction> => {
  return await configService.getConfigInfo()
    .then((data: ConfigInfo) => dispatch(setConfigInfo(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CONFIG_INFO)))
}

export const getConfigInfoByTenant: any = (TenantID: string) => async (dispatch: Dispatch<ConfigInfoAction | LoadedAction>): Promise<ConfigInfoAction> => {
  return await configService.getConfigInfoByTenant(TenantID)
    .then((data: ConfigInfo) => dispatch(setConfigInfo(data)))
    .finally(() => dispatch(setLoaded(actionTypes.GET_CONFIG_INFO)))
}

export const setAppStartUTC: any = () => async (dispatch: Dispatch<AppStartUTCAction>): Promise<AppStartUTCAction> => (
  dispatch(setSetAppStartUTC(moment.utc().toISOString()))
)

export const changeTab: any = (newTab: string) => async (dispatch: Dispatch<ConfigTabAction>): Promise<ConfigTabAction> => {
  return dispatch(setChangeTab(newTab))
}

export const setLoaded = (payload: string): LoadedAction => ({
  type: actionTypes.SET_LOADED,
  payload
})

export const clearLoaded = (payload: string): LoadedAction => ({
  type: actionTypes.CLEAR_LOADED,
  payload
})

export const toggleWelcome: any = (flag: boolean) => async (dispatch: Dispatch<ConfigWelcomeAction>): Promise<ConfigWelcomeAction> => {
  return dispatch(setToggleWelcomeFlag(flag))
}

export const toggleSignupWelcome: any = (flag: boolean) => async (dispatch: Dispatch<ConfigSignupWelcomeAction>): Promise<ConfigSignupWelcomeAction> => {
  return dispatch(setToggleSignupWelcomeFlag(flag))
}

export const toggleOffline: any = (flag: boolean) => async (dispatch: Dispatch<ConfigOfflineAction>): Promise<ConfigOfflineAction> => {
  return dispatch(setToggleOfflineFlag(flag))
}

export const toggleShake: any = (flag: boolean) => async (dispatch: Dispatch<ConfigShakeAction>): Promise<ConfigShakeAction> => {
  return dispatch(setToggleShakeFlag(flag))
}

const setSetAppStartUTC = (payload: string): AppStartUTCAction => ({
  type: actionTypes.SET_APP_START_UTC,
  payload
})

const setChangeTab = (payload: string): ConfigTabAction => ({
  type: actionTypes.CHANGE_TAB,
  payload
})

const setConfigInfo = (payload: ConfigInfo): ConfigInfoAction => ({
  type: actionTypes.GET_CONFIG_INFO,
  payload
})

const setToggleWelcomeFlag = (payload: boolean): ConfigWelcomeAction => ({
  type: actionTypes.SET_WELCOME,
  payload
})

const setToggleSignupWelcomeFlag = (payload: boolean): ConfigSignupWelcomeAction => ({
  type: actionTypes.SET_SIGNUP_WELCOME,
  payload
})
const setToggleOfflineFlag = (payload: boolean): ConfigOfflineAction => ({
  type: actionTypes.SET_OFFLINE,
  payload
})

const setToggleShakeFlag = (payload: boolean): ConfigShakeAction => ({
  type: actionTypes.SET_SHAKE,
  payload
})

export const setAppError = (payload: string): AppErrorAction => ({
  type: actionTypes.SET_APP_ERROR,
  payload
})
