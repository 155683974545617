import {
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_LANGUAGE_NAME
} from '../../locales/i18n'
export const selectedLanguage = 'i18nextLng'
export const selectedLanguageTitle = 'LngTitle'
export const languageService = {
  getLanguage: (): string =>
    localStorage.getItem(selectedLanguage) ?? DEFAULT_LANGUAGE_CODE,
  getLanguageTitle: (): string =>
    localStorage.getItem(selectedLanguageTitle) ?? DEFAULT_LANGUAGE_NAME,
  setLanguage: (language: string, title: string): void => {
    localStorage.setItem(selectedLanguage, language)
    localStorage.setItem(selectedLanguageTitle, title)
  }
}
