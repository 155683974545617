import { Box, Button, TextField, Typography } from '@material-ui/core'
import React, { ReactElement } from 'react'
import './ManageAccount.scss'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@react-hook/media-query'
import { breakpoints } from '../../core/utils/css-selectors'
import { BACK_BTN_MARGIN_TOP } from '../../core/utils/constants'

interface AccountDetailsProps {
  name: string
  email: string
  handleEditInfoClick: () => void
  handleEditPasswordClick: () => void
  isDrawerView: boolean
}

const AccountDetails = ({
  name,
  email,
  handleEditInfoClick,
  handleEditPasswordClick,
  isDrawerView
}: AccountDetailsProps): ReactElement => {
  const { t } = useTranslation()
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  return (
    <Box
      className={`account-details-box ${!isDrawerView ? 'maxw-30' : ''}`}
      style={{ marginTop: matchesMd ? BACK_BTN_MARGIN_TOP : '' }}
    >
      {!isDrawerView && (
        <Typography className='title'>{t`MyAccount`}</Typography>
      )}
      <Box className='accounts-details-field'>
        <TextField
          disabled
          id='name'
          label={t`Name`}
          value={name}
          variant='filled'
        />
        <TextField
          disabled
          id='email'
          label={t`EmailSignIn`}
          value={email}
          variant='filled'
        />
        <Box
          className={`${
            !isDrawerView
              ? 'actions-box space-between'
              : 'actions-box-drawer-view'
          }`}
        >
          {!isDrawerView && (
            <Box className='btn-box'>
              <Button
                className='btn'
                onClick={handleEditInfoClick}
              >{t`EditInformation`}
              </Button>
            </Box>
          )}
          <Button
            className='secondary-btn'
            onClick={handleEditPasswordClick}
          >{t`ChangePassword`}
          </Button>
          {isDrawerView && (
            <Box className='btn-box'>
              <Button
                className='btn'
                onClick={handleEditInfoClick}
              >{t`EditInformation`}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default AccountDetails
