import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { Skeleton } from '@material-ui/lab'
import { IconButton } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'

import { IdList } from '../../core/utils/id-list'
import TransactionHistoryList from '../TransactionHistoryList/TransactionHistoryList'
import BalanceInfo from './BalanceInfo/BalanceInfo'
import { selectIsActionLoaded } from '../../config/selectors'
import { GET_USER_INFO } from '../actionTypes'
import { selectUser } from '../selectors'

import './BalanceDesktop.scss'
import { breakpoints } from '../../core/utils/css-selectors'
import { useMediaQuery } from '@react-hook/media-query'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'

interface BalanceDesktopProp {
  isFromUpdatedUI?: boolean
}
const BalanceDesktop: FC<BalanceDesktopProp> = ({
  isFromUpdatedUI = false
}) => {
  const { trackEvent } = useTracking()
  const isUserLoaded: boolean = useSelector(selectIsActionLoaded(GET_USER_INFO))
  const user: UserInfo = useSelector(selectUser)
  const [isOpenTransactionHistory, setIsOpenTransactionHistory] =
    useState<boolean>(false)
  const matchesMd = useMediaQuery(`(${breakpoints.minWidthMd})`)

  const handleOpenTransactionHistory = (): void => {
    trackEvent({
      page: AnalyticsCategory.PTH,
      action: 'PTH View',
      payload: {
        origin: 'point balance'
      }
    })
    setIsOpenTransactionHistory(true)
  }

  return (
    <>
      {isFromUpdatedUI ? (
        <div
          className='balance-desktop balance-desktop__new'
          onClick={handleOpenTransactionHistory}
        >
          {(user.earningsCount > 0 || user.redeemsCount > 0) && (
            <>
              <IconButton
                id={IdList.balanceHistory}
                color='inherit'
                aria-label='history'
                className='balance-desktop-history pl-0'
              >
                <HistoryIcon className='header-toolbar__ico' />
              </IconButton>
              <TransactionHistoryList
                isOpenTransactionHistory={isOpenTransactionHistory}
                setIsOpenTransactionHistory={setIsOpenTransactionHistory}
                isFromDesktop={matchesMd}
              />
            </>
          )}
          {isUserLoaded ? (
            <BalanceInfo isFromUpdatedUI />
          ) : (
            <Skeleton
              variant='rect'
              classes={{
                root: `balance-desktop--loading__info ${
                  user.earningsCount > 0 || user.redeemsCount > 0
                    ? 'm-right-20'
                    : ''
                }`
              }}
            />
          )}
        </div>
      ) : (
        <div className='balance-desktop'>
          {isUserLoaded ? (
            <BalanceInfo />
          ) : (
            <Skeleton
              variant='rect'
              classes={{
                root: `balance-desktop--loading__info ${
                  user.earningsCount > 0 || user.redeemsCount > 0
                    ? 'm-right-20'
                    : ''
                }`
              }}
            />
          )}

          {(user.earningsCount > 0 || user.redeemsCount > 0) && (
            <>
              <IconButton
                onClick={handleOpenTransactionHistory}
                id={IdList.balanceHistory}
                color='inherit'
                aria-label='history'
                className='balance-desktop-history pl-0'
              >
                <HistoryIcon className='header-toolbar__ico' />
              </IconButton>
              <TransactionHistoryList
                isOpenTransactionHistory={isOpenTransactionHistory}
                setIsOpenTransactionHistory={setIsOpenTransactionHistory}
                isFromDesktop={matchesMd}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

export default BalanceDesktop
